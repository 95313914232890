import { Avatar, Box, Button, ButtonBase, Card, CardContent, CardMedia, Chip, CircularProgress, IconButton, Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { AddCartIcon, CheckIcon, CloseIcon } from '../../../assets/icons';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { IOrder, ISetting } from '../../../interfaces';
import { ShopApi } from '../../../apis';

interface ICheckOutStep1Props {
  paymentMethodSelected?: ISetting;
  onPaymentMethodSelected: (paymentMethod: ISetting) => void;
  rootLoading: boolean;
  onNext: () => void;
  onCancel: () => void;
}

interface ICheckOutStep2Props {
  clientSecret: string;
  rootLoading: boolean;
  onStripeMessageChange: (message: string) => void;
  onStripeIntentStatusChange: (status: string) => void;
  onNext: () => void;
  onCancel: () => void;
}

interface ICheckOutProcessModalProps {
  open: boolean;
  order: IOrder;
  onClose: (isSuccess?: boolean) => void;
}

export const CheckOutStep1: FC<ICheckOutStep1Props> = ({ rootLoading, paymentMethodSelected, onPaymentMethodSelected, onNext, onCancel }) => {
  const [paymentMethods, setPaymentMethods] = useState<ISetting[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      ShopApi.getPaymentMethod()
        .then((res) => {
          setPaymentMethods(res.paymentMethods);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, []);

  const handleNext = async () => {
    if (!paymentMethodSelected || !paymentMethodSelected._id) return;
    onNext();
  };

  return (
    <>
      <Box sx={{ p: 3 }}>
        {paymentMethods.map(paymentMethod => (<ButtonBase
          onClick={() => onPaymentMethodSelected(paymentMethod)}
          sx={{ width: '50%' }} key={paymentMethod._id}
        >
          <Card sx={{ display: 'flex', ...(paymentMethodSelected && paymentMethodSelected._id === paymentMethod._id ? { borderColor: 'primary', border: 1 } : {}) }}>
            <CardContent sx={{ width: '100%' }}>
              <Typography component="div" sx={{ textAlign: 'left' }}>
                {paymentMethod.displayName}
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              sx={{ width: '40%', objectFit: 'contain' }}
              image={paymentMethod.value?.logoUrl}
            />
          </Card>
        </ButtonBase>))}
      </Box>
      <S.Actions direction="row" spacing={8}>
        <Button
          color="inherit"
          disabled={loading || rootLoading}
          onClick={onCancel}
          sx={{ mr: 1 }}
        >
          Close
        </Button>
        <Button disabled={loading || rootLoading || !paymentMethodSelected} onClick={handleNext}>
          Next
        </Button>
      </S.Actions>
    </>
  );
};

export const CheckOutStep2: FC<ICheckOutStep2Props> = ({ clientSecret, rootLoading, onStripeMessageChange, onStripeIntentStatusChange, onNext, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleNext = async () => {
    if (!message) return;
    onNext();
  };

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // console.log(paymentIntent?.status);
      // switch (paymentIntent?.status) {
      //   case 'succeeded':
      //     setMessage('Payment succeeded!');
      //     onStripeMessageChange(message);
      //     onNext();
      //     break;
      //   case 'processing':
      //     setMessage('Your payment is processing.');
      //     break;
      //   case 'requires_payment_method':
      //     setMessage('Your payment was not successful, please try again.');
      //     break;
      //   default:
      //     setMessage('Something went wrong.');
      //     break;
      // }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || loading) {
      return;
    }

    setLoading(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setMessage(error.message || '');
    } else {
      // setMessage('An unexpected error occurred.');
      console.log(paymentIntent?.status);
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          onStripeMessageChange('Payment succeeded!');
          onStripeIntentStatusChange(paymentIntent!.status);
          onNext();
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    }

    setLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={{
        layout: 'tabs'
      }} />
      <S.Actions direction="row" spacing={8}>
        <Button
          color="inherit"
          disabled={loading || rootLoading}
          onClick={onCancel}
          sx={{ mr: 1 }}
        >
          Close
        </Button>
        <Button disabled={loading || rootLoading} type="submit">
          Next
        </Button>
      </S.Actions>
    </form>
  );
};

export const CheckOutProcess: FC<ICheckOutProcessModalProps> = ({ open, order, onClose }) => {
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<ISetting>();
  const [stripeInstance, setStripeInstance] = useState<any>();
  const [stripeClientSecret, setStripeClientSecret] = useState<string>();
  const [stripeMessage, setStripeMessage] = useState<string>();
  const [stripeIntentStatus, setStripeIntentStatus] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [checkOutStep, setCheckOutStep] = useState<number>(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (paymentMethodSelected?.value?.publicKey) {
      setStripeInstance(loadStripe(paymentMethodSelected.value.publicKey));
    } else {
      setStripeInstance(null);
    }
  }, [paymentMethodSelected]);

  const handleNext = async () => {
    if (checkOutStep === 0) {
      if (!paymentMethodSelected || !paymentMethodSelected._id) return;
      setLoading(true);
      try {
        const orderResponse = await ShopApi.newOrder({
          ...order,
          paymentMethod: paymentMethodSelected!.key,
        });
        if (orderResponse.order && orderResponse.order.paymentClientSecret) {
          setStripeClientSecret(orderResponse.order.paymentClientSecret);
          setCheckOutStep((prevCheckOutStep) => prevCheckOutStep + 1);
        }
      } catch (error) {
        console.log('error', error);
      }
      setLoading(false);
    } else if (checkOutStep === 1) {
      setCheckOutStep((prevCheckOutStep) => prevCheckOutStep + 1);
    } else {
      onClose(stripeIntentStatus === 'succeeded');
    }
  };

  const handleClose = () => {
    onClose();
  };

  console.log(stripeMessage);

  return (
    <S.Modal fullWidth open={open} onClose={() => onClose()}>
      <S.Header>
        <Typography color="primary.dark" variant="title-m">
          {t('shop.check_out')}
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </S.Header>
      <S.Content>
        <Box sx={{ width: '100%', marginBottom: '2em' }}>
          <Stepper activeStep={checkOutStep} alternativeLabel>
            <Step key={1}>
              <StepLabel>Payment method</StepLabel>
              <StepContent></StepContent>
            </Step>
            <Step key={2}>
              <StepLabel>Payment information</StepLabel>
              <StepContent></StepContent>
            </Step>
            <Step key={3}>
              <StepLabel>Completed</StepLabel>
              <StepContent></StepContent>
            </Step>
          </Stepper>
        </Box>
        {checkOutStep === 0 && (<CheckOutStep1 rootLoading={loading} paymentMethodSelected={paymentMethodSelected} onPaymentMethodSelected={setPaymentMethodSelected} onNext={handleNext} onCancel={handleClose} />)}
        {checkOutStep === 1 && (<Box sx={{ p: 3 }}>
          {paymentMethodSelected?.value?.publicKey && stripeClientSecret && (<Elements options={{
            clientSecret: stripeClientSecret,
            appearance: {
              theme: 'stripe',
            },
          }} stripe={stripeInstance}>
            <CheckOutStep2 clientSecret={stripeClientSecret} rootLoading={loading} onStripeMessageChange={setStripeMessage} onStripeIntentStatusChange={setStripeIntentStatus} onNext={handleNext} onCancel={handleClose} />
          </Elements>)}
        </Box>)}
        {checkOutStep === 2 && (<>
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography>{stripeMessage}</Typography>
          </Box>
          <S.Actions direction="row" spacing={8}>
            <Button disabled={loading} onClick={handleNext}>
              Finish
            </Button>
          </S.Actions>
        </>)}
      </S.Content>
    </S.Modal>
  );
};
