import { Switch, SwitchProps, TextField } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import * as S from './styles';

interface IToggleInputProps extends SwitchProps {
  label?: string;
  trueLabel: string;
  falseLabel: string;
}

export const ToggleInput: FC<IToggleInputProps> = ({ checked, label, trueLabel, falseLabel, ...props }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    ref.current?.lastElementChild?.firstElementChild?.setAttribute('disabled', 'true');
  }, []);

  return (
    <S.ToggleInput checked={checked}>
      <TextField ref={ref} label={label} value={checked ? trueLabel : falseLabel} variant="outlined" fullWidth />
      <Switch {...props} checked={checked} />
    </S.ToggleInput>
  );
};
