import { Badge, Box, IconButton, InputAdornment, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { AddCartIcon, PersonFilledIcon, SearchIcon } from '../../../assets/icons';
import { ACCESS_TOKEN_KEY, LANGUAGE_KEY, ROUTES } from '../../../constants';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import BrandImage from '../../../assets/iamges/brand.png';
import { getLanguage, setLanguage } from '../../../i18n';
import LogoImage from '../../../assets/iamges/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from '../../../store/selectors';
import { Language, Role } from '../../../interfaces';
import { setAccount } from '../../../store/actions';
import { useTranslation } from 'react-i18next';
import { Dehaze } from '@mui/icons-material';
import { ProfileModal } from '../../Modal';
import { storage } from '../../../utils';
import * as S from './styles';

export const Header: FC = () => {
  const [personEl, setPersonEl] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [lang, setLang] = useState<string>();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [visibleProfileModal, setVisibleProfileModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const account = useSelector(getAccount);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isShop = useMemo(() => {
    return pathname === ROUTES.MAIN.SHOP.CART || pathname === ROUTES.MAIN.SHOP.CHECK_OUT;
  }, [pathname]);

  const isForUser = useMemo(() => {
    return !account || account?.role === Role.User;
  }, [account]);

  const isUser = useMemo(() => {
    return account && account.role === Role.User;
  }, [account]);

  const isAdmin = useMemo(() => {
    return account && account.role === Role.Admin;
  }, [account]);

  const navLinks = [
    {
      label: t('header.galleries'),
      path: ROUTES.MAIN.GALLERY.LIST
    },
    {
      label: t('header.about_us'),
      path: ROUTES.ABOUT_US
    },
    {
      label: t('header.prints'),
      path: ROUTES.MAIN.PRINT.LIST
    }
  ];

  const handleGoToCart = () => {
    if (!isShop) {
      navigate(ROUTES.MAIN.SHOP.CART);
    }
  };

  const handleClickProfile = (event: MouseEvent<HTMLButtonElement>) => {
    setPersonEl(event.currentTarget);
  };

  const handleClickLang = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setPersonEl(null);
  };

  const handleCloseLangMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    storage.removeItem(ACCESS_TOKEN_KEY);
    dispatch(setAccount(null));
    setPersonEl(null);
  };

  const handleOpenProfileModal = () => {
    setVisibleProfileModal(true);
    setPersonEl(null);
  };

  const handleCloseProfileModal = () => {
    setVisibleProfileModal(false);
  };

  const updateLanguage = (lang) => () => {
    setLanguage(lang);
    setLang(lang);
    localStorage.setItem(LANGUAGE_KEY, lang);
    setAnchorEl(null);
  };

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    setLang(getLanguage());
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setOpenMenu(false);
    }
  }, [isMobile]);

  return (
    <S.Header isAdmin={isAdmin}>
      <S.Container>
        <S.Logo to={ROUTES.AUTH.SIGN_IN}>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <img src={BrandImage} alt="brand" />
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <img src={LogoImage} alt="logo" />
          </Box>
        </S.Logo>
        {isForUser && (
          <S.Menu isOpen={openMenu} direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 20, md: 45 }}>
            {navLinks.map(({ label, path }, index) => (
              <S.MenuItem key={index} to={path} as={NavLink} onClick={handleCloseMenu}>
                {label}
              </S.MenuItem>
            ))}
          </S.Menu>
        )}
        <S.Toolbar direction="row" spacing={{ xs: 4, md: 16 }}>
          {false && (
            <S.SearchInput
              size="small"
              placeholder={t('header.search')}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          )}
          {isUser && (
            <S.Cart isShop={isShop} onClick={handleGoToCart}>
              <IconButton>
                <Badge color="error" variant="dot" invisible={!(account.cart.length > 0)}>
                  <AddCartIcon />
                </Badge>
              </IconButton>
            </S.Cart>
          )}
          {isForUser && <IconButton onClick={handleClickLang}>{t(`header.${lang}`)}</IconButton>}
          {anchorEl && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseLangMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem onClick={updateLanguage(Language.En)}>{t('header.en')}</MenuItem>
              <MenuItem onClick={updateLanguage(Language.Ch)}>{t('header.ch')}</MenuItem>
            </Menu>
          )}
          {isUser && (
            <IconButton onClick={handleClickProfile}>
              <PersonFilledIcon />
            </IconButton>
          )}
          {personEl && account && (
            <Menu
              anchorEl={personEl}
              open={Boolean(personEl)}
              onClose={handleCloseUserMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem onClick={handleOpenProfileModal}>{t('header.profile')}</MenuItem>
              <MenuItem onClick={handleLogout}>{t('header.logout')}</MenuItem>
            </Menu>
          )}
          {isForUser && (
            <IconButton onClick={handleToggleMenu} sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
              <Dehaze fontSize="medium" />
            </IconButton>
          )}
        </S.Toolbar>
      </S.Container>
      {visibleProfileModal && <ProfileModal open={visibleProfileModal} onClose={handleCloseProfileModal} />}
    </S.Header>
  );
};
