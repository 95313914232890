import { FC } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

export const AboutUsPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <S.Toolbar>
        <Button variant="text" startIcon={<ArrowLeftIcon />} onClick={handleBack}>
          {t('print.back')}
        </Button>
      </S.Toolbar>
      <Typography align="justify" variant="body-m" px={16}>
        {t('about_us.paragraph1')}.
        <br />
        <br />
        {t('about_us.paragraph2')}.
        <br />
        <br />
        {t('about_us.paragraph3')}.
        <br />
        <br />
        {t('about_us.paragraph4')}.{t('about_us.paragraph5')}.
      </Typography>
    </Container>
  );
};
