import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const Information = styled(Box)`
  width: 100%;

  .MuiDivider-root {
    margin-bottom: ${(props) => props.theme.spacing(28)};

    &:not(:first-of-type) {
      margin-top: ${(props) => props.theme.spacing(49)};
    }

    &::before {
      width: 12px;
      border-color: ${(props) => props.theme.palette.primary.dark};
    }

    &::after {
      width: calc(100% - 12px);
      border-color: ${(props) => props.theme.palette.primary.dark};
    }

    span {
      ${(props) => props.theme.typography['label-s']};
      color: ${(props) => props.theme.palette.primary.dark};
    }
  }

  .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    .MuiDivider-root {
      margin-bottom: ${(props) => props.theme.spacing(16)};

      &:not(:first-of-type) {
        margin-top: ${(props) => props.theme.spacing(28)};
      }
    }
  }
`;

export const Summary = styled(Box)`
  width: 100%;
  max-width: 536px;
  min-width: 536px;
  margin-top: ${(props) => props.theme.spacing(13)};
  margin-left: ${(props) => props.theme.spacing(292)};
  border: 1px solid ${(props) => props.theme.palette.primary.dark};
  border-radius: 4px;
  padding: ${(props) => props.theme.spacing(36, 16, 20)};
  position: relative;

  .MuiListItem-root {
    padding: ${(props) => props.theme.spacing(8, 0)};

    &.item-disabled {
      .MuiListItemAvatar-root {
        opacity: 0.33;
      }

      .MuiListItemText-root {
        opacity: 0.33;
      }
    }

    .MuiListItemAvatar-root {
      margin-right: ${(props) => props.theme.spacing(16)};

      img {
        width: 56px;
        height: 56px;
      }
    }

    .MuiListItemText-primary {
      ${(props) => props.theme.typography['body-m']};
    }

    .MuiListItemText-secondary {
      margin-top: -4px;
      ${(props) => props.theme.typography['body-s']};
    }
  }

  ${(props) => props.theme.breakpoints.down('xl')} {
    max-width: 420px;
    min-width: 420px;
    margin-left: ${(props) => props.theme.spacing(120)};
  }

  ${(props) => props.theme.breakpoints.down('lg')} {
    margin-top: ${(props) => props.theme.spacing(60)};
    margin-left: auto;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: ${(props) => props.theme.spacing(40)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const Label = styled(Typography)`
  position: absolute;
  padding: ${(props) => props.theme.spacing(1, 15, 0, 4)};
  background: ${(props) => props.theme.palette.common.white};
  top: -13px;
  left: 12px;
  display: block;
  width: fit-content;
`;
