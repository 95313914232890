import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { IColumn, Table, ToggleButton, ToggleInput } from '../../../components';
import { CloseIcon, WarningIcon } from '../../../assets/icons';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPaper } from '../../../interfaces';
import { PaperApi } from '../../../apis';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as S from './styles';
import * as Yup from 'yup';

const initialValues = {
  name: '',
  price: '',
  discount: '',
  isAvailable: false,
  width: {
    value: 1,
    unit: 'm'
  },
  height: {
    value: 1,
    unit: 'm'
  }
};

export const PrintPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [papers, setPapers] = useState<IPaper[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedPaper, setSelectedPaper] = useState<IPaper>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isNew, setIsNew] = useState<boolean>(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    price: Yup.number().required(t('validation.required'))
  });

  const { values, errors, touched, setValues, handleChange, handleBlur, handleSubmit } = useFormik<IPaper>({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      if (isNew) {
        PaperApi.create(values)
          .then((res) => {
            fetchData();
            setIsNew(false);
            enqueueSnackbar(res.msg, { variant: 'success' });
          })
          .catch((err) => {
            setLoading(false);
            enqueueSnackbar(err.msg, { variant: 'error' });
          });
      } else {
        PaperApi.update(selectedPaper?._id as string, values)
          .then((res) => {
            fetchData();
            enqueueSnackbar(res.msg, { variant: 'success' });
          })
          .catch((err) => {
            setLoading(false);
            enqueueSnackbar(err.msg, { variant: 'error' });
          });
      }
    }
  });

  const fetchData = () => {
    setLoading(true);
    PaperApi.readAll()
      .then((res) => {
        setPapers(res.papers);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectPaper = (id) => {
    setIsNew(false);
    setSelectedPaper(papers.find(({ _id }) => id === _id));
  };

  const handleToggle = (id, isAvailable) => {
    PaperApi.update(id, { isAvailable })
      .then((res) => {
        fetchData();
        if (selectedPaper) {
          setSelectedPaper({
            ...selectedPaper,
            isAvailable
          });
        }
        enqueueSnackbar(res.msg, { variant: 'success' });
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err.msg, { variant: 'error' });
      });
  };

  const handleCreate = () => {
    setIsNew(true);
    setSelectedPaper(undefined);
  };

  const handleCheckPaper = (isAllChecked, row) => {
    if (row) {
      if (selectedRows.includes(row.id)) {
        setSelectedRows(selectedRows.filter((id) => id !== row.id));
      } else {
        setSelectedRows([...selectedRows, row.id]);
      }
    } else {
      if (isAllChecked) {
        setSelectedRows(papers.map(({ _id }) => _id as string));
      } else {
        setSelectedRows([]);
      }
    }
  };

  const handleDeleteAll = () => {
    setLoading(true);
    PaperApi.removeAll(selectedRows)
      .then((res) => {
        fetchData();
        if (selectedPaper) {
          if (selectedRows.includes(selectedPaper?._id as string)) {
            setSelectedPaper(undefined);
          }
        }
        setSelectedRows([]);
        enqueueSnackbar(res.msg, { variant: 'success' });
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err.msg, { variant: 'error' });
      });
  };

  const handleSave = () => {
    handleSubmit();
  };

  const handleClose = () => {
    setSelectedPaper(undefined);
  };

  const handleDiscard = () => {
    if (isNew) {
      setValues(initialValues);
    }

    if (selectedPaper) {
      setValues(selectedPaper);
    }
  };

  const columns: IColumn[] = [
    {
      field: 'check'
    },
    {
      render: (row) => row.name
    },
    {
      render: (row) => `${row.width.value}${row.width.unit} × ${row.width.value}${row.width.unit}`
    },
    {
      render: (row) => `£${row.price}`
    },
    {
      align: 'right',
      render: ({ id, isAvailable }) => (
        <ToggleButton
          value={isAvailable}
          data={[
            { label: t('print.available'), value: true, color: '#02C58D' },
            { label: t('print.unavailable'), value: false, color: '#928F9A' }
          ]}
          onChange={(value) => handleToggle(id, value)}
        >
          {isAvailable ? t('print.available') : t('print.unavailable')}
        </ToggleButton>
      )
    }
  ];

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isNew) {
      setValues(initialValues);
    }
  }, [isNew]);

  useEffect(() => {
    if (!isNew && selectedPaper) {
      setValues(selectedPaper);
    }
  }, [selectedPaper, isNew]);

  return (
    <Grid container>
      <Grid item xs={12} lg={7} sx={{ display: { xs: selectedPaper ? 'none' : 'flex', lg: 'flex' } }}>
        <Table
          data={papers}
          columns={columns}
          isLoading={loading}
          selectedRows={selectedRows}
          numSelected={selectedRows.length}
          onNew={handleCreate}
          newLabel={t('print.add_new_paper')}
          onDeleteAll={handleDeleteAll}
          onRowClick={handleSelectPaper}
          onChangeChecked={handleCheckPaper}
        />
      </Grid>
      <Grid item xs={12} lg={5} sx={{ display: { xs: selectedPaper ? 'flex' : 'none', lg: 'flex' } }}>
        <S.Detail>
          <S.Toolbar>
            <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)} variant="fullWidth">
              <Tab label={isNew ? t('print.new') : t('print.details')} />
              <Tab label={t('print.galleries')} />
            </Tabs>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </S.Toolbar>
          {activeTab === 0 && (
            <S.TabPanel>
              {selectedPaper || isNew ? (
                <Grid container columns={6} spacing={20}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="name"
                      label={t('print.paper_name')}
                      disabled={loading}
                      variant="outlined"
                      value={values.name}
                      minRows={4}
                      error={Boolean(errors.name && touched.name)}
                      helperText={Boolean(errors.name && touched.name) && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="price"
                      type="number"
                      label={t('print.price')}
                      disabled={loading}
                      variant="outlined"
                      value={values.price}
                      error={Boolean(errors.price && touched.price)}
                      helperText={Boolean(errors.price && touched.price) && errors.price}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      type="number"
                      name="width.value"
                      variant="outlined"
                      disabled={loading}
                      value={values.width.value}
                      label={t('print.width')}
                      error={Boolean(errors.width?.value && touched.width?.value)}
                      helperText={Boolean(errors.width?.value && touched.width?.value) && errors.width?.value}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      select
                      fullWidth
                      defaultValue="m"
                      name="width.unit"
                      variant="outlined"
                      disabled={loading}
                      value={values.width.unit}
                      label={t('print.unit')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value="m">m</MenuItem>
                      <MenuItem value="cm">cm</MenuItem>
                      <MenuItem value="mm">mm</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      type="number"
                      name="height.value"
                      variant="outlined"
                      disabled={loading}
                      value={values.height.value}
                      label={t('print.height')}
                      error={Boolean(errors.height?.value && touched.height?.value)}
                      helperText={Boolean(errors.height?.value && touched.height?.value) && errors.height?.value}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      select
                      fullWidth
                      defaultValue="m"
                      name="height.unit"
                      variant="outlined"
                      disabled={loading}
                      value={values.height.unit}
                      label={t('print.unit')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <MenuItem value="m">m</MenuItem>
                      <MenuItem value="cm">cm</MenuItem>
                      <MenuItem value="mm">mm</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="discount"
                      label={t('print.discount')}
                      disabled={loading}
                      variant="outlined"
                      value={values.discount}
                      minRows={4}
                      error={Boolean(errors.discount && touched.discount)}
                      helperText={Boolean(errors.discount && touched.discount) && errors.discount}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ToggleInput
                      name="isAvailable"
                      label={t('print.availability')}
                      trueLabel="Available"
                      falseLabel="Unavailable"
                      checked={values.isAvailable}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={12}>
                      <Button
                        disabled={loading}
                        endIcon={loading && <CircularProgress size={18} />}
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                      <Button variant="text" onClick={handleDiscard}>
                        Discard
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              ) : (
                <Stack height="100%" alignItems="center" justifyContent="center" color="primary.main">
                  <WarningIcon />
                  <Typography mt={19} variant="body-s">
                    {t('print.select_item')}
                  </Typography>
                </Stack>
              )}
            </S.TabPanel>
          )}
          {activeTab === 1 && <S.TabPanel></S.TabPanel>}
        </S.Detail>
      </Grid>
    </Grid>
  );
};
