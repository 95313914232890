import { ENDPOINTS } from '../constants';
import { Http } from '../utils';

const register = (data): Promise<any> => {
  return Http.post(ENDPOINTS.AUTH.REGISTER, data);
};

export const verify = (token: any) => {
  return Http.post(ENDPOINTS.AUTH.VERIFY, token);
};

const login = (data): Promise<any> => {
  return Http.post(ENDPOINTS.AUTH.LOGIN, data);
};

export const me = (): Promise<any> => {
  return Http.get(ENDPOINTS.AUTH.ME);
};

export const forgotPassword = (data): Promise<any> => {
  return Http.post(ENDPOINTS.AUTH.FORGOT_PASSWORD, data);
};

export const AuthApi = {
  register,
  verify,
  login,
  me,
  forgotPassword
};
