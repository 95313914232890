import { createTheme, Theme } from '@mui/material';
import breakpoints from './breakpoints';
import components from './components';
import typography from './typography';
import variants from './variants';

const theme = {} as Theme;

variants.forEach(({ name, palette }) => {
  theme[name] = createTheme({
    spacing: 1,
    breakpoints,
    typography,
    components,
    palette
  });
});

export default theme;
