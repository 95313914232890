import { IGetParams } from '../interfaces';
import { ENDPOINTS } from '../constants';
import { Http } from '../utils';

const readAll = (params?: IGetParams): Promise<any> => {
  return Http.get(ENDPOINTS.ORDER.READ_ALL, params);
};

const proceed = (id: string): Promise<any> => {
  return Http.put(ENDPOINTS.ORDER.PROCEED.replace(':id', id));
};

export const OrderApi = {
  readAll,
  proceed,
};
