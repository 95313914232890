import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import { Box } from '@mui/material';

export const Toolbar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${(props) => props.theme.spacing(32, 0, 16)};
  border-bottom: 1px solid ${(props) => props.theme.palette.text.secondary};
  padding: ${(props) => props.theme.spacing(4, 0)};

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: ${(props) => props.theme.spacing(16)};
  }
`;

export const Content = styled(PerfectScrollbar)`
  max-width: 1088px;
  margin: auto;
  padding: ${(props) => props.theme.spacing(16, 0, 57)};
`;

export const SubToolbar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(4, 0, 4, 16)};
  margin: ${(props) => props.theme.spacing(32, 0, 20)};

  input {
    display: none;
  }
`;

export const GalleryLogo = styled(Box)`
  height: 260px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  max-width: 260px;

  & > div {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }

  & > img {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

export const Photo = styled.img`
  height: 122px;
  border-radius: 8px;
  width: 100%;
  max-width: 122px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
`;
