export enum Role {
  User = 'user',
  Admin = 'super-admin'
}

export enum Order {
  Asc = 'asc',
  Desc = 'desc'
}

export enum OrderStatus {
  Pending = 'Pending',
  Purchased = 'Purchased',
  Cancelled = 'Cancelled',
  Completed = 'Completed'
}

export enum IFilterType {
  String = 'String',
  Number = 'Number',
  Date = 'Date'
}

export interface IAccount {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  cart: IPrint[];
  gallery: IGallery[];
  role: Role;

  verify: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface IFile {
  _id: string;
  path: string;
  name: string;
  fieldname: string;
  filename: string;
}

export interface IUploadFile {
  file: File;
  uploadPercent: number;
  fileId: string;
}

export interface IGallery {
  id: string;
  name: string;
  logo: IFile;
  description: string;
  tags: string[];
  createdBy: IAccount;
  photos: IFile[];
  shareWith: IAccount[];
  prints: IPrint[];
  createdAt: string;
  updatedAt: string;
}

export interface IPhoto {
  name: string;
}

export interface IPrint {
  _id: string;
  name: string;
  size: string;
  paper: {
    price: number;
    width: {
      value: number;
      unit: string;
    };
    height: {
      value: number;
      unit: string;
    };
  };
  photo: IFile;
  updatedAt: string;
  quantity?: number;
}

export interface IPaper {
  _id?: string;
  name: string;
  price: string;
  discount: string;
  isAvailable: boolean;
  width: {
    value: number;
    unit: string;
  };
  height: {
    value: number;
    unit: string;
  };
}

export interface ISetting {
  _id: string;
  key: string;
  displayName: string;
  value: any;
  type: string;
  updatedAt: string;
}

export interface IOrder {
  _id?: string;
  updatedAt?: string;
  customer: {
    name: string;
    email: string;
    mobileNumber: string;
    country: string;
    city: string;
    zip: string;
    state: string;
    address: string;
  };
  schedule: {
    dates: string;
    note: string;
  };
  paymentType: string;
  paymentMethod: string;
  items: {
    print: string;
    quantity: number;
    price: number;
    total: number;
  }[];
}

export interface IGetParams {
  query?: any;
  projection?: any;
  options?: any;
  aggregate?: any;
}

export interface IRootState {
  authReducer: {
    account: IAccount;
  };
}

export enum Language {
  En = 'en',
  Ch = 'ch'
}
