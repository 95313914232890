import { Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { CheckIcon, LockIcon, UnlockIcon } from '../../../assets/icons';
import * as S from './styles';

export const ChangePasswordPage: FC = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('validation.invalid_email')).required(t('validation.required'))
  });

  const fields = [
    {
      name: 'password',
      icon: <LockIcon />,
      label: t('change_password.choose_password')
    },
    {
      name: 'confirmPassword',
      icon: <LockIcon />,
      label: t('change_password.repeat_password')
    }
  ];

  const { values, errors, touched, isSubmitting, setSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    onSubmit: (values) => {
      console.log(values);
      setSubmitting(false);
    }
  });

  const handleSend = () => {
    handleSubmit();
  };

  return (
    <S.Container>
      <S.Image>
        <UnlockIcon />
      </S.Image>
      <Typography mb={24} variant="display-s" color="secondary" align="center">
        {t('change_password.title')}
      </Typography>
      <Typography mb={34} variant="body-s" color="primary" align="center">
        {t('change_password.description')}
      </Typography>
      <S.Form>
        {fields.map(({ name, icon, label }, index) => (
          <TextField
            fullWidth
            key={index}
            name={name}
            type="password"
            placeholder={label}
            value={values[name]}
            disabled={isSubmitting}
            error={Boolean(errors[name] && touched[name])}
            helperText={Boolean(errors[name] && touched[name]) && errors[name]}
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{ mb: 32 }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
            }}
          />
        ))}
        <Button sx={{ mb: 16 }} startIcon={!isSubmitting && <CheckIcon />} onClick={handleSend} disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={20} /> : t('change_password.save')}
        </Button>
      </S.Form>
    </S.Container>
  );
};
