import {
  Button,
  Container,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { API_SERVER, ROUTES } from '../../../../constants';
import { getAccount } from '../../../../store/selectors';
import { FC, useEffect, useMemo, useState } from 'react';
import { setAccount } from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Counter } from '../../../../components';
import { IPrint } from '../../../../interfaces';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const shipping = 0;

export const CartPage: FC = () => {
  const [cart, setCart] = useState<IPrint[]>([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector(getAccount);

  const subTotal = useMemo(() => {
    return cart.reduce((sum, cur) => sum + (cur.quantity || 0) * cur.paper.price, 0);
  }, [cart]);

  const handleIncrease = (id) => () => {
    setCart([
      ...cart.map((item, index) => ({
        ...item,
        quantity: id === index ? (item.quantity || 0) + 1 : item.quantity
      }))
    ]);
  };

  const handleDecrease = (id) => () => {
    setCart([
      ...cart.map((item, index) => ({
        ...item,
        quantity: id === index ? (item.quantity || 0) - 1 : item.quantity
      }))
    ]);
  };

  const handleCheckOut = () => {
    dispatch(
      setAccount({
        ...account,
        cart
      })
    );
    navigate(ROUTES.MAIN.SHOP.CHECK_OUT);
  };

  useEffect(() => {
    setCart(
      account.cart.map((item) => ({
        ...item,
        quantity: 1
      }))
    );
    console.log(
      account.cart.map((item) => ({
        ...item,
        quantity: 1
      }))
    );
  }, []);

  return (
    <Container>
      <Stack mt={{ xs: 30, md: 61 }} pb={20} direction={{ xs: 'column', lg: 'row' }} alignItems="flex-start">
        <S.OrderList>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('shop.order')}</TableCell>
                <TableCell>{t('shop.price')}</TableCell>
                <TableCell>{t('shop.subtotal')}</TableCell>
                <TableCell align="right">{t('shop.quantity')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.map(({ photo, name, paper, quantity }, index) => (
                <TableRow key={index} className={quantity === 0 ? 'row-disabled' : ''}>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      {photo?.filename ? <img src={`${API_SERVER}/${photo?.filename}`} alt="" /> : ''}
                      <Typography ml={16} variant="body-m" color="primary.dark">
                        {name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>£{(paper.price || 0).toFixed(2)}</TableCell>
                  <TableCell>£{((paper.price || 0) * (quantity || 0)).toFixed(2)}</TableCell>
                  <TableCell align="right">
                    <Counter
                      count={quantity || 0}
                      onDecrease={handleDecrease(index)}
                      onIncrease={handleIncrease(index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </S.OrderList>
        <S.Summary>
          <S.Label variant="label-s" color="primary.dark">
            {t('shop.order_summary')}
          </S.Label>
          <Stack mb={29} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body-m" color="primary">
              {t('shop.subtotal')}
            </Typography>
            <Typography variant="title-m" color="common.black">
              £{(subTotal || 0).toFixed(2)}
            </Typography>
          </Stack>
          <Stack mb={32} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body-m" color="primary">
              {t('shop.shipping')}
            </Typography>
            <Typography variant="title-m" color={shipping > 0 ? 'common.black' : 'primary'}>
              {shipping > 0 ? shipping.toFixed(2) : '--'}
            </Typography>
          </Stack>
          <Divider />
          <Stack mt={29} mb={32} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body-m" color="primary">
              {t('shop.total_usd')}
            </Typography>
            <Typography variant="title-m" color="common.black">
              £{((shipping || 0) + (subTotal || 0)).toFixed(2)}
            </Typography>
          </Stack>
          <Button fullWidth onClick={handleCheckOut}>
            {t('shop.check_out')}
          </Button>
        </S.Summary>
      </Stack>
    </Container>
  );
};
