export const ENDPOINTS = {
  AUTH: {
    REGISTER: '/auth/register',
    VERIFY: '/auth/verify',
    LOGIN: '/auth/login',
    ME: '/auth/me',
    FORGOT_PASSWORD: '/auth/forgot-password'
  },
  GALLERY: {
    BASE: '/galleries',
    ID: '/galleries/:id'
  },
  PHOTO: {
    BASE: '/photos',
    ID: '/photos/:id'
  },
  PRINT: {
    BASE: '/prints',
    ID: '/prints/:id'
  },
  PAPER: {
    BASE: '/papers',
    ID: '/papers/:id'
  },
  USER: {
    BASE: '/users',
    ID: '/users/:id',
    CHANGE_PASSWORD: '/users/change-password'
  },
  ORDER: {
    READ_ALL: '/orders',
    PROCEED: '/orders/:id/proceed'
  },
  SETTING: {
    BASE: '/settings',
    ID: '/settings/:id'
  },
  SHOP: {
    TOGGLE_TO_CART: '/shop/toggle-to-cart',
    CLEAR_CART: '/shop/clear-cart',
    NEW_ORDER: '/shop/new-order',
    PAYMENT_METHOD: '/shop/payment-methods'
  }
};
