import { error, neutral, neutralVariant, primary, secondary, tertiary } from './colors';
import { alpha } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    bg: Palette['primary'];
    surface: Palette['primary'];
    surfaceVariant: Palette['primary'];
  }
  interface PaletteOptions {
    bg: PaletteOptions['primary'];
    surface: PaletteOptions['primary'];
    surfaceVariant: PaletteOptions['primary'];
  }
  interface PaletteColor {
    on?: string;
    outline?: string;
  }
  interface SimplePaletteColorOptions {
    on?: string;
    outline?: string;
  }
}

const lightVariant = {
  name: 'light',
  palette: {
    primary: {
      main: '#928F9A',
      light: primary[40],
      dark: '#191466',
      contrastText: primary[30]
    },
    secondary: {
      main: '#EB0B0C',
      light: secondary[40],
      dark: secondary[80],
      contrastText: secondary[0]
    },
    success: {
      main: '#02C58D'
    },
    tertiary: {
      main: tertiary[90],
      light: tertiary[40],
      dark: tertiary[100],
      contrastText: tertiary[30]
    },
    error: {
      main: error[90],
      light: error[40],
      dark: error[100],
      contrastText: error[30]
    },
    text: {
      primary: '#000000',
      secondary: '#707070'
    },
    action: {
      hoverOpacity: 0.11,
      disabledOpacity: 0.38
    },
    divider: alpha('#928F9A', 0.33),
    bg: {
      main: neutral[110],
      on: neutral[30]
    },
    surface: {
      main: neutral[90],
      on: neutral[30]
    },
    surfaceVariant: {
      main: neutralVariant[90],
      on: neutralVariant[40],
      outline: neutralVariant[60]
    }
  }
};

const darkVariant = {
  name: 'dark',
  palette: {
    primary: {
      main: '#928F9A',
      light: primary[40],
      dark: '#191466',
      contrastText: primary[30]
    },
    secondary: {
      main: '#EB0B0C',
      light: secondary[40],
      dark: secondary[80],
      contrastText: secondary[0]
    },
    success: {
      main: '#02C58D'
    },
    tertiary: {
      main: tertiary[90],
      light: tertiary[40],
      dark: tertiary[100],
      contrastText: tertiary[30]
    },
    error: {
      main: error[90],
      light: error[40],
      dark: error[100],
      contrastText: error[30]
    },
    text: {
      primary: '#000000'
    },
    action: {
      hoverOpacity: 0.11,
      disabledOpacity: 0.38
    },
    divider: alpha('#928F9A', 0.33),
    bg: {
      main: neutral[110],
      on: neutral[30]
    },
    surface: {
      main: neutral[90],
      on: neutral[30]
    },
    surfaceVariant: {
      main: neutralVariant[90],
      on: neutralVariant[40],
      outline: neutralVariant[60]
    }
  }
};

const variants = [lightVariant, darkVariant];

export default variants;
