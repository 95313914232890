import { Box, Dialog, Stack } from '@mui/material';
import styled from 'styled-components';
import { primary } from '../../../theme/colors';

export const Modal = styled(Dialog)`
  && {
    .MuiDialog-paper {
      width: 100%;
      max-width: 368px;
      border-radius: 16px;
    }
  }
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${primary[30]};
  padding: ${(props) => props.theme.spacing(10, 16)};
`;

export const Content = styled(Box)`
  && {
    padding: ${(props) => props.theme.spacing(24)};
  }
`;

export const Information = styled(Stack)`
  && {
    .MuiInputLabel-root {
      color: ${(props) => props.theme.palette.primary.main};
    }

    .MuiInput-input {
      -webkit-text-fill-color: ${(props) => props.theme.palette.common.black};
    }
  }
`;
