import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import { Box } from '@mui/material';

export const GalleryDetail = styled(Box)`
  border-left: 1px solid ${(props) => props.theme.palette.primary.main};

  ${(props) => props.theme.breakpoints.down('lg')} {
    border-left: none;
  }
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(4, 26)};
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
`;

export const Content = styled(PerfectScrollbar)`
  height: calc(100vh - 121px);
  padding: ${(props) => props.theme.spacing(20)};

  ${(props) => props.theme.breakpoints.down('md')} {
    height: calc(100vh - 97px);
  }
`;

export const Image = styled(Box)`
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 16px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 398px;
  width: 100%;
  height: 398px;
  overflow: hidden;

  img {
    max-width: 398px;
    max-height: 398px;
  }
`;

export const Print = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;

  img {
    max-width: 200px;
    max-height: 200px;
    width: auto;
    height: auto;
  }
`;
