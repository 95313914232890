import {
  Autocomplete,
  Avatar,
  Chip,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { CloseIcon, UserIcon } from '../../../assets/icons';
import { getAccount } from '../../../store/selectors';
import { IAccount, Role } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserApi } from '../../../apis';
import * as S from './styles';

interface ISearchPeopleProps {
  value: IAccount[];
  disabled?: boolean;
  onChange: (selected: IAccount[]) => void;
}

export const SearchPeople: FC<ISearchPeopleProps> = ({ value, disabled, onChange }) => {
  const [users, setUsers] = useState<IAccount[]>([]);

  const { t } = useTranslation();
  const account = useSelector(getAccount);

  const accesses = useMemo(
    () => [
      {
        ...account,
        name: `${account.name} (${t('gallery.you')})`
      },
      ...value
    ],
    [value]
  );

  const handleChange = (event: SyntheticEvent, newValue: IAccount[]) => {
    onChange(newValue);
  };

  const handleRemoveUser = (userId: string) => () => {
    onChange(value.filter(({ id }) => id !== userId));
  };

  useEffect(() => {
    UserApi.readAll({
      query: {
        role: Role.User
      }
    })
      .then((res) => {
        setUsers(res.users.filter(({ id }) => id !== account.id));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally();
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <Autocomplete
        multiple
        limitTags={1}
        options={users}
        // @ts-ignore
        getOptionLabel={(option) => option.name}
        value={value}
        onChange={handleChange}
        // @ts-ignore
        renderTags={(users: readonly IAccount[], getTagProps) =>
          users.map((user: IAccount, index: number) => (
            <Chip
              avatar={
                <Avatar>
                  <UserIcon />
                </Avatar>
              }
              deleteIcon={
                <IconButton>
                  <CloseIcon />
                </IconButton>
              }
              variant="outlined"
              label={user.name}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} disabled={disabled} fullWidth variant="outlined" label={t('gallery.add_people')} />
        )}
      />
      <Divider textAlign="left" sx={{ my: 20 }}>
        {t('gallery.people_with_access')}
      </Divider>
      <S.UserList>
        {accesses.map(({ id, name, email }, index) => (
          <S.User
            key={index}
            secondaryAction={
              account.id === id ? (
                <Typography mr={10} color="primary.dark" variant="label-s">
                  {t('gallery.owner')}
                </Typography>
              ) : (
                <IconButton onClick={handleRemoveUser(id)}>
                  <CloseIcon />
                </IconButton>
              )
            }
          >
            <ListItemAvatar>
              <Avatar>A</Avatar>
            </ListItemAvatar>
            <ListItemText primary={name} secondary={email} />
          </S.User>
        ))}
      </S.UserList>
    </>
  );
};
