import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import { Box } from '@mui/material';

export const Users = styled(PerfectScrollbar)`
  height: calc(100vh - 80px);
`;

export const Detail = styled(Box)`
  width: 100%;
  border-left: 1px solid ${(props) => props.theme.palette.primary.main};

  ${(props) => props.theme.breakpoints.down('lg')} {
    border-left: none;
  }
`;

export const Toolbar = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};

  ${(props) => props.theme.breakpoints.down('lg')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MuiIconButton-root {
    margin-right: ${(props) => props.theme.spacing(10)};
    display: none;

    ${(props) => props.theme.breakpoints.down('lg')} {
      display: flex;
    }
  }
`;
