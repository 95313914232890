import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material';
import { AscOrderIcon, DeleteIcon, PlusIcon } from '../../../../assets/icons';
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Card, ShareModal } from '../../../../components';
import { getAccount } from '../../../../store/selectors';
import { IGallery } from '../../../../interfaces';
import { ROUTES } from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GalleryApi } from '../../../../apis';
import { useSelector } from 'react-redux';
import * as S from './styles';

enum GalleryTab {
  Own,
  Shared
}

export const GalleryListPage: FC = () => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedId, setSelectedId] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [visibleShareModal, setVisibleShareModal] = useState(false);
  const [galleries, setGalleries] = useState<IGallery[]>([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const account = useSelector(getAccount);

  const tabs = [
    {
      label: t('gallery.own_galleries')
    },
    {
      label: t('gallery.shared_galleries')
    }
  ];

  const fetchGalleries = () => {
    setLoading(true);
    GalleryApi.readAll({
      query: {
        $or: [
          {
            createdBy: account.id
          },
          {
            shareWith: account.id
          }
        ]
      }
    })
      .then((res) => {
        setGalleries(res.galleries);
        setSelectedIds([]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [ownGalleries, sharedGalleries] = useMemo(() => {
    return [
      galleries.filter(({ createdBy }) => createdBy.id === account.id),
      galleries.filter(({ createdBy }) => createdBy.id !== account.id)
    ];
  }, [galleries]);

  const isSelectedAll = useMemo(() => {
    if (activeTab === GalleryTab.Own) {
      return ownGalleries.length === selectedIds.length && ownGalleries.length > 0;
    } else {
      return sharedGalleries.length === selectedIds.length && sharedGalleries.length > 0;
    }
  }, [selectedIds, ownGalleries, sharedGalleries, activeTab]);

  const isIndeterminate = useMemo(() => {
    if (activeTab === GalleryTab.Own) {
      return ownGalleries.length > selectedIds.length && selectedIds.length > 0;
    } else {
      return sharedGalleries.length > selectedIds.length && selectedIds.length > 0;
    }
  }, [ownGalleries, sharedGalleries, selectedIds]);

  const handleChangeTab = (_: SyntheticEvent, tab: number) => {
    setActiveTab(tab);
    setSelectedIds([]);
  };

  const handleEdit = (id) => () => {
    navigate(ROUTES.MAIN.GALLERY.EDIT.replace(':id', id));
  };

  const handleShare = (id) => () => {
    setSelectedId(id);
    setVisibleShareModal(true);
  };

  const handleCloseShareModal = () => {
    setVisibleShareModal(false);
  };

  const handleDelete = (id) => () => {
    setLoading(true);
    GalleryApi.remove(id)
      .then(() => {
        fetchGalleries();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleCreateGallery = () => {
    navigate(ROUTES.MAIN.GALLERY.NEW);
  };

  const handleSelect = (id) => () => {
    if (selectedIds.indexOf(id) > -1) {
      setSelectedIds(selectedIds.filter((selected) => id !== selected));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSelectAll = () => {
    if (activeTab === GalleryTab.Own) {
      if (selectedIds.length === ownGalleries.length) {
        setSelectedIds([]);
      } else {
        setSelectedIds([...ownGalleries.map(({ id }) => id)]);
      }
    } else {
      if (selectedIds.length === sharedGalleries.length) {
        setSelectedIds([]);
      } else {
        setSelectedIds([...sharedGalleries.map(({ id }) => id)]);
      }
    }
  };

  const handleMultiDelete = () => {
    if (selectedIds.length > 0) {
      setLoading(true);
      GalleryApi.removeAll(selectedIds)
        .then(() => {
          fetchGalleries();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleGoToDetail = (id) => () => {
    navigate(ROUTES.MAIN.GALLERY.DETAIL.replace(':id', id));
  };

  useEffect(() => {
    fetchGalleries();
  }, []);

  return (
    <Container>
      <S.Toolbar>
        <S.Tabs value={activeTab} onChange={handleChangeTab}>
          {tabs.map(({ label }, index) => (
            <S.Tab key={index} label={label} />
          ))}
        </S.Tabs>
        <S.Actions
          direction="row"
          spacing={8}
          divider={<Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />}
        >
          <Stack direction="row" spacing={{ xs: 4, md: 8 }} divider={<Divider orientation="vertical" flexItem />}>
            <Stack
              direction="row"
              spacing={{ xs: 4, md: 8 }}
              divider={<Divider orientation="vertical" flexItem sx={{ display: { xs: 'block', md: 'none' } }} />}
            >
              <Tooltip title={t(isSelectedAll ? 'common.deselect_all' : 'common.select_all')}>
                <Checkbox
                  disabled={loading}
                  indeterminate={isIndeterminate}
                  checked={isSelectedAll}
                  onChange={handleSelectAll}
                />
              </Tooltip>
              <Tooltip title={t('common.delete')}>
                <IconButton disabled={loading || selectedIds.length === 0} onClick={handleMultiDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Tooltip title={t('common.sort')}>
              <IconButton disabled={loading}>
                <AscOrderIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Button variant="text" disabled={loading} startIcon={<PlusIcon />} onClick={handleCreateGallery}>
            {t('gallery.add_new_gallery')}
          </Button>
        </S.Actions>
      </S.Toolbar>
      <S.Content>
        {loading ? (
          <Stack height={300} alignItems="center" justifyContent="center">
            <CircularProgress color="secondary" />
          </Stack>
        ) : (
          <Grid container spacing={16}>
            {activeTab === GalleryTab.Own &&
              ownGalleries.map(({ id, name, logo, shareWith }, index) => (
                <Grid key={index} item xs={12} md={6} lg={4} xl={3}>
                  <Card
                    selected={selectedIds.indexOf(id) > -1}
                    title={name}
                    thumbnail={logo}
                    shareWith={shareWith}
                    onEdit={handleEdit(id)}
                    onShare={handleShare(id)}
                    onDelete={handleDelete(id)}
                    onSelect={handleSelect(id)}
                    onDetail={handleGoToDetail(id)}
                  />
                </Grid>
              ))}
            {activeTab === GalleryTab.Shared &&
              sharedGalleries.map(({ id, logo, name, createdBy, photos, shareWith }, index) => (
                <Grid key={index} item xs={12} md={6} lg={4} xl={3}>
                  <Card
                    selected={selectedIds.indexOf(id) > -1}
                    title={name}
                    owner={createdBy}
                    thumbnail={logo}
                    shareWith={shareWith}
                    onEdit={handleEdit(id)}
                    onShare={handleShare(id)}
                    onDelete={handleDelete(id)}
                    onSelect={handleSelect(id)}
                    onDetail={handleGoToDetail(id)}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </S.Content>
      {visibleShareModal && selectedId && (
        <ShareModal galleryId={selectedId} open={visibleShareModal} onClose={handleCloseShareModal} />
      )}
    </Container>
  );
};
