import { FC, useMemo } from 'react';
import * as S from './styles';

interface IToggleButtonProps {
  value?: boolean | string;
  data: {
    label: string;
    value?: boolean | string;
    color: string;
  }[];
  onChange?: (value?: boolean | string) => void;
}

export const ToggleButton: FC<IToggleButtonProps> = ({ value, data, onChange }) => {
  const item = useMemo(() => {
    return data.find(({ value: v }) => v === value);
  }, [value, data]);

  const handleToggle = (e) => {
    e.stopPropagation();
    if (item) {
      const index = data.indexOf(item);
      let newValue;
      if (index + 1 < data.length) {
        newValue = data[index + 1].value;
      } else {
        newValue = data[0].value;
      }

      if (onChange) {
        onChange(newValue);
      }
    }
  };

  return (
    <S.ToggleButton onClick={handleToggle} sx={{ bgcolor: item?.color }}>
      {item?.label}
    </S.ToggleButton>
  );
};
