import { Button, Container, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { PaperApi, PhotoApi, PrintApi } from '../../../../apis';
import { API_SERVER, ROUTES } from '../../../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { IPaper, IPrint } from '../../../../interfaces';
import { AddCartIcon } from '../../../../assets/icons';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as S from './styles';
import * as Yup from 'yup';

export const NewPrintPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [papers, setPapers] = useState<IPaper[]>([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { photoId } = useParams<{ photoId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    photo: Yup.object().required(t('validation.required')),
    paper: Yup.string().required(t('validation.required'))
  });

  const { values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      photo: '',
      paper: ''
    },
    onSubmit: (values) => {
      const newPrint = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        // @ts-ignore
        if (key == 'price' || key === 'width' || key === 'height' || (key === 'photo' && value.fieldname)) {
          newPrint.append(key, JSON.stringify(value));
        } else {
          newPrint.append(key, value as string);
        }
      });
      PrintApi.create(newPrint as unknown as IPrint)
        .then((res) => {
          navigate(ROUTES.MAIN.PRINT.LIST);
          enqueueSnackbar(res.msg, { variant: 'success' });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.msg, { variant: 'error' });
        });
    }
  });

  const fetchPhoto = () => {
    if (photoId) {
      setLoading(true);
      PhotoApi.read(photoId)
        .then((res) => {
          setFieldValue('photo', res.photo);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchPapers = () => {
    setLoading(true);
    PaperApi.readAll()
      .then((res) => {
        console.log(res);
        setPapers(res.papers);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddToCart = () => {
    navigate(ROUTES.MAIN.SHOP.CART);
  };

  const handleSave = () => {
    handleSubmit();
  };

  useEffect(() => {
    fetchPhoto();
  }, [photoId]);

  useEffect(() => {
    fetchPapers();
  }, []);

  return (
    <Container>
      <Grid mt={{ xs: 16, sm: 32, lg: 64 }} container spacing={16}>
        <Grid item xs={12} md={6}>
          <S.Printing>
            <S.Dropzone>
              {/* @ts-ignore */}
              <S.Photo src={`${API_SERVER}/${values.photo?.filename}`} />
            </S.Dropzone>
            <Typography variant="title-s" color="primary" mt={5} ml={16}>
              {Boolean(errors.photo && touched.photo) && errors.photo}
            </Typography>
          </S.Printing>
        </Grid>
        <Grid item xs={12} md={6}>
          <S.Form>
            <Grid container columns={6} spacing={20}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="name"
                  disabled={loading}
                  variant="outlined"
                  value={values.name}
                  label={t('print.print_name')}
                  error={Boolean(errors.name && touched.name)}
                  helperText={Boolean(errors.name && touched.name) && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ mb: 16 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  fullWidth
                  name="paper"
                  disabled={loading}
                  variant="outlined"
                  value={values.paper}
                  label={t('print.paper')}
                  error={Boolean(errors.paper && touched.paper)}
                  helperText={Boolean(errors.paper && touched.paper) && errors.paper}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ mb: 16 }}
                >
                  {papers.map(({ _id, width, height }, index) => (
                    <MenuItem key={index} value={_id}>
                      {width.value}
                      {width.unit} ⨉ {height.value}
                      {height.unit}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Button sx={{ mt: { xs: 12, lg: 50 }, mb: 12 }} disabled={loading} onClick={handleSave}>
              {t('print.save')}
            </Button>
            <Button variant="text" startIcon={<AddCartIcon />} disabled={loading} onClick={handleAddToCart}>
              {t('print.add_to_cart')}
            </Button>
          </S.Form>
        </Grid>
      </Grid>
    </Container>
  );
};
