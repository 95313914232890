import { Button, Checkbox, IconButton, Stack } from '@mui/material';
import { DeleteIcon, PlusIcon } from '../../../../assets/icons';
import React, { FC } from 'react';
import * as S from './styles';

interface IToolbarProps {
  isLoading?: boolean;
  onNew: () => void;
  newLabel?: string;
  selectedRows?: any[];
  rowCount?: number;
  numSelected?: number;
  onDeleteAll?: () => void;
  onChangeChecked?: (isChangeCheckedAll: boolean, row?: any) => void;
}

const Toolbar: FC<IToolbarProps> = ({
  isLoading,
  rowCount = 0,
  numSelected,
  onNew,
  newLabel,
  onDeleteAll,
  onChangeChecked
}) => {
  const handleCheckedAllChange = () => {
    if (onChangeChecked) onChangeChecked(!(rowCount > 0 && numSelected === rowCount));
  };

  return (
    <S.Toolbar>
      {numSelected !== undefined && onDeleteAll && (
        <Stack direction="row">
          <Checkbox
            disabled={isLoading}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected == rowCount}
            onChange={handleCheckedAllChange}
          />
          <IconButton disabled={isLoading} onClick={onDeleteAll}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      )}
      <Button variant="text" disabled={isLoading} onClick={onNew} startIcon={<PlusIcon />}>
        {newLabel}
      </Button>
    </S.Toolbar>
  );
};

export default Toolbar;
