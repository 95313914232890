import styled from 'styled-components';
import { Box, Collapse } from '@mui/material';

export const UploadNotification = styled(Box)`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 100%;
  max-width: 362px;
  z-index: 20;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: calc(100% - 20px);
    max-width: 100%;
  }
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  padding: ${(props) => props.theme.spacing(12, 16)};
  background: ${(props) => props.theme.palette.primary.dark};
  border-radius: ${(props) => props.theme.spacing(4, 4, 0, 0)};

  .MuiIconButton-root {
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export const Content = styled(Collapse)`
  background: ${(props) => props.theme.palette.common.white};
`;

export const File = styled(Box)`
  && {
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.spacing(8, 16)};

    &:not(:last-of-type) {
      border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
    }

    .MuiTypography-root {
      white-space: nowrap;
      max-width: calc(100% - 120px);
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .MuiCircularProgress-root {
      margin-left: auto;
      border: 4px solid ${(props) => props.theme.palette.primary.main};
      border-radius: 100%;

      svg {
        margin: ${(props) => props.theme.spacing(-4)};
        color: ${(props) => props.theme.palette.primary.dark};
      }
    }
  }
`;

export const Image = styled(Box)`
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.spacing(16)};

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;
