import * as S from './styles';
import { FC, useState } from 'react';
import { Button, CircularProgress, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CloseIcon, LockIcon } from '../../../assets/icons';
import { useSelector } from 'react-redux';
import { getAccount } from '../../../store/selectors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UserApi } from '../../../apis';
import { useSnackbar } from 'notistack';

interface IProfileModalProps {
  open: boolean;
  onClose: () => void;
}

export const ProfileModal: FC<IProfileModalProps> = ({ open, onClose }) => {
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const account = useSelector(getAccount);

  const information = [
    {
      name: 'name',
      label: t('profile.username')
    },
    {
      name: 'email',
      label: t('profile.email_address')
    }
  ];

  const fields = [
    {
      name: 'oldPassword',
      icon: <LockIcon />,
      label: t('profile.current_password')
    },
    {
      name: 'newPassword',
      icon: <LockIcon />,
      label: t('profile.new_password')
    },
    {
      name: 'confirmPassword',
      icon: <LockIcon />,
      label: t('profile.confirm_password')
    }
  ];

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('validation.required')),
    newPassword: Yup.string().required(t('validation.required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), ''], 'Confirm password does not matched!')
      .required(t('validation.required'))
  });

  const { values, errors, touched, isSubmitting, setSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    onSubmit: (values) => {
      UserApi.changePassword(values)
        .then(() => {
          onClose();
          enqueueSnackbar(t('message.password_changed'), { variant: 'success' });
        })
        .catch((err) => {
          if (err.status === 412) {
            enqueueSnackbar(t('message.password_not_matched'), { variant: 'warning' });
          } else {
            enqueueSnackbar(t('message.network_error'), { variant: 'error' });
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const handleOpenPasswordSection = () => {
    setVisiblePassword(true);
  };

  const handleClosePasswordSection = () => {
    setVisiblePassword(false);
  };

  const handleChangePassword = () => {
    handleSubmit();
  };

  return (
    <S.Modal open={open} onClose={onClose}>
      <S.Header>
        <Typography color="primary.dark" variant="title-m">
          {t('header.profile')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </S.Header>
      <S.Content>
        {visiblePassword ? (
          <Stack spacing={20}>
            {fields.map(({ name, icon, label }, index) => (
              <TextField
                fullWidth
                key={index}
                name={name}
                type="password"
                label={label}
                value={values[name]}
                disabled={isSubmitting}
                error={Boolean(errors[name] && touched[name])}
                helperText={Boolean(errors[name] && touched[name]) && errors[name]}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
                }}
              />
            ))}
            <Stack direction="row" spacing={20}>
              <Button fullWidth variant="outlined" onClick={handleClosePasswordSection}>
                Cancel
              </Button>
              <Button
                fullWidth
                disabled={isSubmitting}
                startIcon={isSubmitting && <CircularProgress size={18} color="secondary" />}
                onClick={handleChangePassword}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        ) : (
          <S.Information spacing={20}>
            {information.map(({ name, label }, index) => (
              <TextField disabled key={index} label={label} value={account[name]} InputLabelProps={{ shrink: true }} />
            ))}
            <Button onClick={handleOpenPasswordSection}>{t('profile.change_password')}</Button>
          </S.Information>
        )}
      </S.Content>
    </S.Modal>
  );
};
