import { alpha, Pagination as MuiPagination } from '@mui/material';
import styled from 'styled-components';

export const Pagination = styled(MuiPagination)`
  && {
    margin: ${(props) => props.theme.spacing(64, 'auto')};
    background-color: ${(props) => props.theme.palette.primary.light};
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down('lg')} {
      margin: ${(props) => props.theme.spacing(32, 'auto')};
    }

    .MuiPaginationItem-root {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin: ${(props) => props.theme.spacing(0)};
      ${(props) => props.theme.typography.button};
      color: ${(props) => props.theme.palette.text.secondary};

      &.Mui-selected {
        color: ${(props) => props.theme.palette.primary.dark};
        background-color: transparent;
      }

      &:hover {
        color: ${(props) => props.theme.palette.primary.dark};
        background-color: ${(props) => alpha(props.theme.palette.primary.dark, 0.11)};
      }
    }

    .MuiPaginationItem-previousNext {
      color: ${(props) => props.theme.palette.action.active};

      &:hover {
        svg {
          color: ${(props) => props.theme.palette.primary.dark};
        }
      }

      svg {
        color: ${(props) => props.theme.palette.primary.main};
      }
    }
  }
`;
