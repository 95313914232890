import { alpha, Box, Stack, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

export const Toolbar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing(32)};
  border-bottom: 1px solid ${(props) => props.theme.palette.text.secondary};

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    margin-top: ${(props) => props.theme.spacing(16)};
  }
`;

export const Tabs = styled(MuiTabs)`
  && {
    margin-bottom: ${(props) => props.theme.spacing(-1)};

    .MuiTabs-indicator {
      display: none;
      height: 3px;
      border-radius: ${(props) => props.theme.spacing(8, 8, 0, 0)};
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      min-height: 42px;
      margin-right: auto;
    }
  }
`;

export const Tab = styled(MuiTab)`
  && {
    border-radius: 8px 8px 0 0;
    color: ${(props) => props.theme.palette.surfaceVariant.on};
    text-transform: none;
    padding: ${(props) => props.theme.spacing(9, 35, 12, 24)};
    ${(props) => props.theme.typography['label-l']};

    &:after {
      content: '';
      width: 0;
      height: 3px;
      background-color: ${(props) => props.theme.palette.primary.dark};
      position: absolute;
      bottom: 0;
      border-radius: 8px 8px 0 0;
    }

    &:hover {
      color: ${(props) => props.theme.palette.primary.dark};
      background-color: ${(props) => alpha(props.theme.palette.primary.dark, 0.33)};
    }

    &:active:after {
      width: 100%;
      transition-duration: 0.3s;
    }

    &.Mui-selected {
      color: ${(props) => props.theme.palette.primary.dark};

      &:after {
        display: block;
        width: 100%;
      }
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      padding: ${(props) => props.theme.spacing(6, 20, 9, 18)};
      min-height: 42px;
    }
  }
`;

export const Actions = styled(Stack)`
  align-items: center;

  .MuiDivider-root {
    height: 28px;
    margin-top: auto;
    margin-bottom: auto;
    border-color: #e0fbfc;
    padding: 0;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    justify-content: space-between;
    width: 100%;
    margin-bottom: ${(props) => props.theme.spacing(6)};
  }
`;

export const Content = styled(PerfectScrollbar)`
  height: calc(100vh - 256px);
  padding-top: ${(props) => props.theme.spacing(16)};

  .ps__rail-y {
    display: none;
  }
`;
