export const primary = {
  0: '#FFFFFF',
  10: '#FFFBFF',
  20: '#F2EFFF',
  30: '#E2DFFF',
  40: '#C2C1FF',
  50: '#A4A2FC',
  60: '#8988DF',
  70: '#6F6EC3',
  80: '#5655A8',
  90: '#3E3C8F',
  100: '#272377',
  110: '#0F0563',
  120: '#000000'
};

export const secondary = {
  0: '#FFFFFF',
  10: '#FFFBFF',
  20: '#FFEDEA',
  30: '#FFDAD5',
  40: '#FFB4A9',
  50: '#FF8A79',
  60: '#FF5543',
  70: '#ED0F0E',
  80: '#C00004',
  90: '#930002',
  100: '#690001',
  110: '#410000',
  120: '#000000'
};

export const tertiary = {
  0: '#FFFFFF',
  10: '#F6FEFF',
  20: '#D0F8FF',
  30: '#97F0FF',
  40: '#4FD8EB',
  50: '#22BCCF',
  60: '#00A0B0',
  70: '#008391',
  80: '#006874',
  90: '#004F58',
  100: '#00363D',
  110: '#001F24',
  120: '#000000'
};

export const error = {
  0: '#FFFFFF',
  10: '#FFFBFF',
  20: '#FFEDEA',
  30: '#FFDAD6',
  40: '#FFB4AB',
  50: '#FF897D',
  60: '#FF5449',
  70: '#DE3730',
  80: '#BA1A1A',
  90: '#93000A',
  100: '#690005',
  110: '#410002',
  120: '#000000'
};

export const neutral = {
  0: '#FFFFFF',
  10: '#FFFBFF',
  20: '#F3EFF4',
  30: '#E5E1E6',
  40: '#C9C5CA',
  50: '#ADAAAF',
  60: '#929094',
  70: '#78767A',
  80: '#5F5E62',
  90: '#47464A',
  100: '#313034',
  110: '#1C1B1F',
  120: '#000000'
};

export const neutralVariant = {
  0: '#FFFFFF',
  10: '#FFFBFF',
  20: '#F3EFFA',
  30: '#E4E1EC',
  40: '#C8C5D0',
  50: '#ACAAB4',
  60: '#928F9A',
  70: '#787680',
  80: '#5F5D67',
  90: '#47464F',
  100: '#302F38',
  110: '#1B1B23',
  120: '#000000'
};
