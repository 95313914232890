import { TableBody, CircularProgress, Checkbox } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { Fragment, useMemo } from 'react';
import { PAGE_LIMIT } from '../../../constants';
import TableHead, { IColumn } from './Head';
import { Pagination } from '../Pagination';
import Toolbar from './Toolbar';
import * as S from './styles';

interface ITableProps {
  data: any[];
  columns: IColumn[];
  pageLimit?: number;
  isLoading?: boolean;
  isMobileDisabled?: boolean;
  totalPage?: number;
  pageNumber?: number;
  contentHeight?: any;
  selectedRows?: any[];
  numSelected?: number;
  onPageChange?: (page: number) => void;
  onNew?: () => void;
  newLabel?: string;
  onDelete?: (id: string) => void;
  onDeleteAll?: () => void;
  onEdit?: (id: string) => void;
  onView?: (id: string, tokenId?: string) => void;
  onFilter?: (filter: any) => void;
  onRowClick?: (id: string) => void;
  onChangeChecked?: (isChangeCheckedAll: boolean, row?: any) => void;
}

export const Table = ({
  data = [],
  columns,
  pageLimit = PAGE_LIMIT,
  pageNumber = 0,
  totalPage = 0,
  onPageChange,
  isLoading = false,
  contentHeight,
  selectedRows = [],
  onNew,
  newLabel,
  onDelete,
  onDeleteAll,
  onEdit,
  onView,
  onFilter,
  onRowClick,
  onChangeChecked
}: ITableProps) => {
  const handlePageChange = (event: unknown, newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage - 1);
    }
  };

  const isVisibleTableHead = useMemo(() => {
    let isVisible = false;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].title) {
        isVisible = !!columns[i].title;
      }
    }

    return isVisible;
  }, [columns]);

  const isVisiblePagination = useMemo(
    () => !isLoading && data.length > 0 && onPageChange,
    [isLoading, data, onPageChange]
  );

  const colSpan = useMemo(() => {
    if (onView || onEdit || onDelete) {
      return columns.length + 1;
    } else {
      return columns.length;
    }
  }, [columns]);

  return (
    <S.Container>
      {onNew && (
        <Toolbar
          isLoading={isLoading}
          rowCount={data.length}
          numSelected={selectedRows?.length || 0}
          onNew={onNew}
          newLabel={newLabel}
          onDeleteAll={onDeleteAll}
          onChangeChecked={onChangeChecked}
        />
      )}
      <S.Content component={PerfectScrollbar} sx={{ height: contentHeight }}>
        <S.Table>
          {isVisibleTableHead && <TableHead columns={columns} visibleAction={!!(onDelete || onEdit || onView)} onFilter={onFilter} />}
          <TableBody>
            {!isLoading ? (
              data.length > 0 ? (
                <Fragment>
                  {data.map((row, rIndex) => (
                    <S.Row
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rIndex}
                      className={selectedRows.some((item) => item.id === row.id) ? 'checked' : ''}
                      onClick={() => onRowClick && onRowClick(row.id)}
                    >
                      {columns.map((column, rowId) =>
                        column.field == 'check' ? (
                          <S.Cell
                            align={column.align}
                            key={`table-cell-${rIndex}-${rowId}`}
                            sx={{ pr: '0 !important' }}
                          >
                            <Checkbox
                              checked={selectedRows.includes(row.id)}
                              onChange={() => onChangeChecked && onChangeChecked(false, row)}
                            />
                          </S.Cell>
                        ) : (
                          <S.Cell align={column.align} key={`table-cell-${rIndex}-${rowId}`}>
                            {column.render ? column.render(row, rowId, data) : column.field ? row[column.field] : null}
                          </S.Cell>
                        )
                      )}
                    </S.Row>
                  ))}
                </Fragment>
              ) : (
                <S.Row
                  sx={{
                    height: 300
                  }}
                >
                  <S.Cell
                    colSpan={colSpan}
                    sx={{ textAlign: 'center', borderBottom: 'none', backgroundColor: 'transparent !important' }}
                  >
                    There is no data to display!
                  </S.Cell>
                </S.Row>
              )
            ) : (
              <S.Row
                sx={{
                  height: 300
                }}
              >
                <S.Cell
                  colSpan={colSpan}
                  sx={{ textAlign: 'center', borderBottom: 'none', backgroundColor: 'transparent !important' }}
                >
                  <CircularProgress />
                </S.Cell>
              </S.Row>
            )}
          </TableBody>
        </S.Table>
      </S.Content>
      {isVisiblePagination && (
        <Pagination count={Math.ceil(totalPage / pageLimit)} page={pageNumber + 1} onChange={handlePageChange} />
      )}
    </S.Container>
  );
};

export * from './Head';
