import React from 'react';
import breakpoints from './breakpoints';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'display-l': React.CSSProperties;
    'display-m': React.CSSProperties;
    'display-s': React.CSSProperties;
    'headline-l': React.CSSProperties;
    'headline-m': React.CSSProperties;
    'headline-s': React.CSSProperties;
    'title-l': React.CSSProperties;
    'title-m': React.CSSProperties;
    'title-s': React.CSSProperties;
    'label-l': React.CSSProperties;
    'label-m': React.CSSProperties;
    'label-s': React.CSSProperties;
    'body-l': React.CSSProperties;
    'body-m': React.CSSProperties;
    'body-s': React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    'display-l': React.CSSProperties;
    'display-m': React.CSSProperties;
    'display-s': React.CSSProperties;
    'headline-l': React.CSSProperties;
    'headline-m': React.CSSProperties;
    'headline-s': React.CSSProperties;
    'title-l': React.CSSProperties;
    'title-m': React.CSSProperties;
    'title-s': React.CSSProperties;
    'label-l': React.CSSProperties;
    'label-m': React.CSSProperties;
    'label-s': React.CSSProperties;
    'body-l': React.CSSProperties;
    'body-m': React.CSSProperties;
    'body-s': React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'display-l': true;
    'display-m': true;
    'display-s': true;
    'headline-l': true;
    'headline-m': true;
    'headline-s': true;
    'title-l': true;
    'title-m': true;
    'title-s': true;
    'label-l': true;
    'label-m': true;
    'label-s': true;
    'body-l': true;
    'body-m': true;
    'body-s': true;
  }
}

const typography = {
  fontFamily: 'Inter',
  'display-l': {
    fontSize: 88,
    fontWeight: 400,
    lineHeight: '117px',
    letterSpacing: '-5px',
    [`@media (max-width: ${breakpoints.values.lg - 0.05}px)`]: {
      fontSize: 70,
      lineHeight: '92px',
      letterSpacing: '-3px'
    },
    [`@media (max-width: ${breakpoints.values.md - 0.05}px)`]: {
      fontSize: 46,
      lineHeight: '52px',
      letterSpacing: '-2px'
    }
  },
  'display-m': {
    fontSize: 68,
    fontWeight: 400,
    lineHeight: '90px',
    letterSpacing: '-5px'
  },
  'display-s': {
    fontSize: 54,
    fontWeight: 400,
    lineHeight: '72px',
    letterSpacing: '-5px',
    [`@media (max-width: ${breakpoints.values.md - 0.05}px)`]: {
      fontSize: 38,
      lineHeight: '46px',
      letterSpacing: '-2px'
    }
  },
  'headline-l': {
    fontSize: 48,
    fontWeight: 400,
    lineHeight: '64px',
    letterSpacing: '-4px'
  },
  'headline-m': {
    fontSize: 42,
    fontWeight: 400,
    lineHeight: '56px',
    letterSpacing: '-4px'
  },
  'headline-s': {
    fontSize: 36,
    fontWeight: 400,
    lineHeight: '48px',
    letterSpacing: '-4px'
  },
  'title-l': {
    fontSize: 32,
    fontWeight: 500,
    lineHeight: '43px',
    letterSpacing: '0'
  },
  'title-m': {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0',
    [`@media (max-width: ${breakpoints.values.md - 0.05}px)`]: {
      fontSize: 20,
      lineHeight: '28px'
    }
  },
  'title-s': {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '27px',
    letterSpacing: '0',
    [`@media (max-width: ${breakpoints.values.md - 0.05}px)`]: {
      fontSize: 16,
      lineHeight: '22px'
    }
  },
  'label-l': {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '27px',
    letterSpacing: '0',
    [`@media (max-width: ${breakpoints.values.md - 0.05}px)`]: {
      fontSize: 16,
      lineHeight: '22px'
    }
  },
  'label-m': {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0'
  },
  'label-s': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '21px',
    letterSpacing: '0',
    [`@media (max-width: ${breakpoints.values.md - 0.05}px)`]: {
      fontSize: 14,
      lineHeight: '18px'
    }
  },
  'body-l': {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0',
    [`@media (max-width: ${breakpoints.values.md - 0.05}px)`]: {
      fontSize: 18,
      lineHeight: '20px'
    }
  },
  'body-m': {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '0',
    [`@media (max-width: ${breakpoints.values.md - 0.05}px)`]: {
      fontSize: 16,
      lineHeight: '20px'
    }
  },
  'body-s': {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0',
    [`@media (max-width: ${breakpoints.values.md - 0.05}px)`]: {
      fontSize: 14,
      lineHeight: '20px'
    }
  }
};

export default typography;
