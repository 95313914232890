import {
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { CheckOutProcess } from '../../../../components/Modal/CheckOutProcess';
import { API_SERVER, ROUTES } from '../../../../constants';
import { getAccount } from '../../../../store/selectors';
import { FC, useEffect, useMemo, useState } from 'react';
import { IOrder, IPrint } from '../../../../interfaces';
import { setAccount } from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Counter } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShopApi } from '../../../../apis';
import { useFormik } from 'formik';
import * as S from './styles';
import * as Yup from 'yup';

const shipping = 0;

export const CheckOutPage: FC = () => {
  const [cart, setCart] = useState<IPrint[]>([]);
  const [order, setOrder] = useState<IOrder>();
  const [showCheckOutProcess, setShowCheckOutProcess] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const account = useSelector(getAccount);
  const dispatch = useDispatch();

  const subTotal = useMemo(() => {
    return cart.reduce((sum, cur) => sum + (cur.quantity || 0) * cur.paper.price, 0);
  }, [cart]);

  const deliveryFields = [
    {
      label: t('shop.name'),
      name: 'name'
    },
    {
      label: t('shop.email'),
      name: 'email'
    },
    {
      label: t('shop.mobile_number'),
      name: 'mobileNumber'
    },
    {
      label: t('shop.country'),
      name: 'country',
      type: 'select',
      children: []
    },
    {
      label: t('shop.city'),
      name: 'city'
    },
    {
      label: t('shop.zip'),
      name: 'zip',
      size: 1
    },
    {
      label: t('shop.state'),
      name: 'state',
      type: 'select',
      size: 1,
      children: []
    },
    {
      label: t('shop.address'),
      name: 'address',
      size: 4
    }
  ];
  const scheduleFields = [
    {
      label: t('shop.dates'),
      name: 'dates',
      type: 'date'
    },
    {
      label: t('shop.note'),
      name: 'note',
      size: 4
    }
  ];
  const paymentFields = [
    {
      label: t('shop.online_payment'),
      value: 'online'
    },
    {
      label: t('shop.cash_on_delivery'),
      value: 'cod'
    },
    {
      label: t('shop.pos_on_delivery'),
      value: 'pod'
    }
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    email: Yup.string().required(t('validation.required')),
    mobileNumber: Yup.string().required(t('validation.required')),
    // country: Yup.string().required(t('validation.required')),
    // city: Yup.string().required(t('validation.required')),
    // zip: Yup.string().required(t('validation.required')),
    // state: Yup.string().required(t('validation.required')),
    address: Yup.string().required(t('validation.required')),
    paymentType: Yup.string().required(t('validation.required')),
    // description: Yup.string().required(t('validation.required')),
    tags: Yup.array().max(8, 'You can add up to 8 tags')
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    submitCount,
    setSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setFieldValue
  } = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      email: '',
      mobileNumber: '',
      country: '',
      city: '',
      zip: '',
      state: '',
      address: '',
      paymentType: paymentFields[0].value,
      dates: '',
      note: ''
    },
    onSubmit: async (values) => {
      if (showCheckOutProcess) return;
      if (values.paymentType === 'online') {
        setOrder({
          customer: {
            name: values.name,
            email: values.email,
            mobileNumber: values.mobileNumber,
            country: values.country,
            city: values.city,
            zip: values.zip,
            state: values.state,
            address: values.address
          },
          schedule: {
            dates: values.dates,
            note: values.note
          },
          paymentType: values.paymentType,
          paymentMethod: '',
          items: (cart || []).map((cartItem) => ({
            print: cartItem._id,
            quantity: cartItem.quantity || 0,
            price: cartItem.paper.price,
            total: cartItem.paper.price * (cartItem.quantity || 0)
          }))
        });
        setShowCheckOutProcess(true);
      } else {
        await ShopApi.newOrder({
          customer: {
            name: values.name,
            email: values.email,
            mobileNumber: values.mobileNumber,
            country: values.country,
            city: values.city,
            zip: values.zip,
            state: values.state,
            address: values.address
          },
          schedule: {
            dates: values.dates,
            note: values.note
          },
          paymentType: values.paymentType,
          paymentMethod: '',
          items: (cart || []).map((cartItem) => ({
            print: cartItem._id,
            quantity: cartItem.quantity || 0,
            price: cartItem.paper.price,
            total: cartItem.paper.price * (cartItem.quantity || 0)
          }))
        });
        const { user } = await ShopApi.clearCart();
        dispatch(setAccount(user));
        navigate(ROUTES.HOME);
      }
    }
  });

  const checkOutEnable = useMemo(() => {
    return cart.reduce((sum, cur) => sum + (cur.quantity || 0), 0) > 0 && !isSubmitting;
  }, [cart, isSubmitting]);

  const handleCheckOutProcessModalClose = async (isSuccess) => {
    if (isSuccess) {
      const { user } = await ShopApi.clearCart();
      dispatch(setAccount(user));
      navigate(ROUTES.HOME);
    }
    setSubmitting(false);
    setShowCheckOutProcess(false);
  };

  const handleIncrease = (id) => () => {
    setCart([
      ...cart.map((item, index) => ({
        ...item,
        quantity: id === index ? (item.quantity || 0) + 1 : item.quantity
      }))
    ]);
  };

  const handleDecrease = (id) => () => {
    setCart([
      ...cart.map((item, index) => ({
        ...item,
        quantity: id === index ? (item.quantity || 0) - 1 : item.quantity
      }))
    ]);
  };

  useEffect(() => {
    setCart(
      account.cart.map((item) => ({
        ...item,
        quantity: item.quantity || 1
      }))
    );
  }, []);

  console.log(order);
  return (
    <Container>
      <Stack mt={{ xs: 30, md: 61 }} pb={20} direction={{ xs: 'column', lg: 'row' }} alignItems="flex-start">
        <S.Information>
          <Divider textAlign="left">{t('shop.delivery_information')}</Divider>
          <Grid container columns={4} rowSpacing={20} columnSpacing={16}>
            {deliveryFields.map(({ label, name, type = 'text', size = 2, children = null }, index) => (
              <Grid item xs={size * 2} md={size} key={index}>
                <TextField
                  select={type === 'select'}
                  children={children}
                  type={type}
                  fullWidth
                  name={name}
                  label={label}
                  disabled={isSubmitting}
                  variant="outlined"
                  value={values[name]}
                  minRows={4}
                  error={Boolean(errors[name] && (touched[name] || submitCount > 0))}
                  helperText={Boolean(errors[name] && (touched[name] || submitCount > 0)) && errors[name]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
            ))}
          </Grid>
          <Divider textAlign="left">{t('shop.schedule_delivery')}</Divider>
          <Grid container columns={4} columnSpacing={16} rowSpacing={20}>
            {scheduleFields.map(({ label, name, type = 'text', size = 2 }, index) => (
              <Grid item xs={4} md={size} key={index}>
                <TextField
                  select={type === 'select'}
                  type={type}
                  fullWidth
                  name={name}
                  label={label}
                  disabled={isSubmitting}
                  variant="outlined"
                  value={values[name]}
                  minRows={4}
                  error={Boolean(errors[name] && (touched[name] || submitCount > 0))}
                  helperText={Boolean(errors[name] && (touched[name] || submitCount > 0)) && errors[name]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
            ))}
          </Grid>
          <Divider textAlign="left">{t('shop.payment_method')}</Divider>
          <RadioGroup>
            {paymentFields.map(({ label, value }, index) => (
              <FormControlLabel
                key={index}
                value={label}
                onChange={() => setFieldValue('paymentType', value)}
                name="paymentType"
                checked={values['paymentType'] === value}
                control={<Radio />}
                label={label}
                disabled={isSubmitting}
              />
            ))}
          </RadioGroup>
        </S.Information>
        <S.Summary>
          <Stack minHeight={{ xs: 200, md: 400 }} mb={20}>
            {cart.map(({ photo, name, paper, quantity }, index) => (
              <ListItem
                key={index}
                className={quantity === 0 ? 'item-disabled' : ''}
                secondaryAction={
                  <Counter
                    count={quantity || 0}
                    onDecrease={handleDecrease(index)}
                    onIncrease={handleIncrease(index)}
                  />
                }
              >
                <ListItemAvatar>
                  {photo?.filename ? <img src={`${API_SERVER}/${photo?.filename}`} alt="" /> : ''}
                </ListItemAvatar>
                <ListItemText primary={name} secondary={`£${paper.price.toFixed(2)}`} />
              </ListItem>
            ))}
          </Stack>
          <S.Label variant="label-s" color="primary.dark">
            {t('shop.order_summary')}
          </S.Label>
          <Stack mb={29} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body-m" color="primary">
              {t('shop.subtotal')}
            </Typography>
            <Typography variant="title-m" color="common.black">
              £{subTotal.toFixed(2)}
            </Typography>
          </Stack>
          <Stack mb={32} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body-m" color="primary">
              {t('shop.shipping')}
            </Typography>
            <Typography variant="title-m" color={shipping > 0 ? 'common.black' : 'primary'}>
              {shipping > 0 ? shipping.toFixed(2) : '--'}
            </Typography>
          </Stack>
          <Divider />
          <Stack mt={29} mb={32} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body-m" color="primary">
              {t('shop.total_usd')}
            </Typography>
            <Typography variant="title-m" color="common.black">
              £{(shipping + subTotal).toFixed(2)}
            </Typography>
          </Stack>
          <Button disabled={!checkOutEnable} onClick={submitForm} fullWidth>
            {t('shop.confirm_order')}
          </Button>
        </S.Summary>
      </Stack>
      {showCheckOutProcess && order && (
        <CheckOutProcess open={showCheckOutProcess} order={order!} onClose={handleCheckOutProcessModalClose} />
      )}
    </Container>
  );
};
