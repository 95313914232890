import { Box, Dialog, Stack } from '@mui/material';
import styled from 'styled-components';
import { primary } from '../../../theme/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Modal = styled(Dialog)`
  && {
    .MuiDialog-paper {
      max-width: 616px;
      border-radius: 16px;
    }
  }
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${primary[30]};
  padding: ${(props) => props.theme.spacing(10, 16)};
`;

export const Content = styled(PerfectScrollbar)`
  padding: ${(props) => props.theme.spacing(24)};
`;

export const Print = styled(Box)`
  height: 568px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    height: 400px;
    max-width: 400px;
    margin: auto;
  }

  ${(props) => props.theme.breakpoints.down(400)} {
    height: 300px;
    max-width: 300px;
    margin: auto;
  }
`;

export const Actions = styled(Stack)`
  justify-content: flex-end;
  margin: ${(props) => props.theme.spacing(24)};
`;
