import { Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import { AccountBoxIcon, EmailIcon, LockIcon, PlusIcon } from '../../../assets/icons';
import LogoImage from '../../../assets/iamges/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../constants';
import { AuthApi } from '../../../apis';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as S from './styles';
import { FC } from 'react';
import * as Yup from 'yup';

export const SignUpPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('validation.required')),
    lastName: Yup.string().required(t('validation.required')),
    email: Yup.string().email(t('validation.invalid_email')).required(t('validation.required')),
    password: Yup.string().min(8, t('validation.invalid_length')).required(t('validation.required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Confirm password does not matched!')
      .required(t('validation.required'))
  });

  const fields = [
    {
      name: 'firstName',
      icon: <AccountBoxIcon />,
      label: t('sign_up.first_name')
    },
    {
      name: 'lastName',
      icon: <AccountBoxIcon />,
      label: t('sign_up.last_name')
    },
    {
      name: 'email',
      icon: <EmailIcon />,
      label: t('sign_up.email')
    },
    {
      name: 'password',
      icon: <LockIcon />,
      type: 'password',
      label: t('sign_up.password')
    },
    {
      name: 'confirmPassword',
      icon: <LockIcon />,
      type: 'password',
      label: t('sign_up.repeat_password')
    }
  ];

  const { values, errors, touched, isSubmitting, setSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    onSubmit: (values) => {
      AuthApi.register(values)
        .then((res) => {
          navigate(ROUTES.AUTH.SIGN_IN);
          enqueueSnackbar(res.msg, { variant: 'success' });
        })
        .catch((err) => {
          console.log(err);
          if (err.status === 409) {
            enqueueSnackbar(t('message.email_exist'), { variant: 'warning' });
          } else {
            enqueueSnackbar(t('message.network_error'), { variant: 'warning' });
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const handleLogin = () => {
    handleSubmit();
  };

  return (
    <S.Container>
      <S.Logo src={LogoImage} />
      <Typography variant="display-l" align="center">
        {t('sign_up.title')}
      </Typography>
      <Typography variant="title-m" align="center" mt={{ xs: 12, md: 0 }}>
        {t('sign_up.sub_title')}
      </Typography>
      <S.Form>
        {fields.map(({ name, icon, type = 'text', label }, index) => (
          <TextField
            fullWidth
            key={index}
            name={name}
            type={type}
            placeholder={label}
            value={values[name]}
            disabled={isSubmitting}
            error={Boolean(errors[name] && touched[name])}
            helperText={Boolean(errors[name] && touched[name]) && errors[name]}
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{ mb: 16 }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
            }}
          />
        ))}
        <Button fullWidth startIcon={!isSubmitting && <PlusIcon />} sx={{ mt: 16 }} onClick={handleLogin}>
          {isSubmitting ? <CircularProgress /> : t('sign_up.create_account')}
        </Button>
        <Typography mt={27} variant="label-s" color="text.secondary" textAlign="center">
          {t('sign_up.question')}{' '}
          <Typography
            ml={16}
            color="secondary"
            component={Link}
            variant="label-s"
            to={ROUTES.AUTH.SIGN_IN}
            sx={{ textDecoration: 'none' }}
          >
            {t('sign_up.login')}
          </Typography>
        </Typography>
      </S.Form>
    </S.Container>
  );
};
