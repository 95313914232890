import { ENDPOINTS } from '../constants';
import { IOrder } from '../interfaces';
import { Http } from '../utils';

const toggleToCart = (ids: string[]): Promise<any> => {
  return Http.post(ENDPOINTS.SHOP.TOGGLE_TO_CART, { ids });
};

const clearCart = (): Promise<any> => {
  return Http.post(ENDPOINTS.SHOP.CLEAR_CART, {});
};

const getPaymentMethod = (): Promise<any> => {
  return Http.get(ENDPOINTS.SHOP.PAYMENT_METHOD);
};

const newOrder = (order: IOrder): Promise<any> => {
  return Http.post(ENDPOINTS.SHOP.NEW_ORDER, { ...order });
};

export const ShopApi = {
  toggleToCart,
  getPaymentMethod,
  newOrder,
  clearCart,
};
