import { Header } from '../../components';
import * as S from './styles';
import { FC } from 'react';

export const UserLayout: FC = ({ children }) => {
  return (
    <S.Layout>
      <Header />
      <S.Content>{children}</S.Content>
    </S.Layout>
  );
};
