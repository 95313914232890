import { Button, IconButton, Typography } from '@mui/material';
import { IAccount } from '../../../interfaces';
import { CloseIcon } from '../../../assets/icons';
import { FC, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { SearchPeople } from '../../Common';
import { GalleryApi } from '../../../apis';
import * as S from './styles';

interface IShareModalProps {
  open: boolean;
  galleryId: string;
  onClose: () => void;
}

export const AccessModal: FC<IShareModalProps> = ({ open, galleryId, onClose }) => {
  // const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<IAccount[]>([]);

  const fetchGallery = () => {
    GalleryApi.read(galleryId)
      .then((res) => {
        setSelectedUsers(res.gallery.shareWith);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePeopleChange = (value) => {
    setSelectedUsers(value);
  };

  const handleShare = () => {
    GalleryApi.update(galleryId, { shareWith: [...selectedUsers] })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchGallery();
  }, [galleryId]);

  return (
    <S.Modal fullWidth open={open} onClose={onClose}>
      <S.Header>
        <Typography color="primary.dark" variant="title-m">
          People with access
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </S.Header>
      <S.Content>
        <SearchPeople value={selectedUsers} onChange={handlePeopleChange} />
      </S.Content>
      <S.Actions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleShare}>Save</Button>
      </S.Actions>
    </S.Modal>
  );
};
