import styled from 'styled-components';
import { Box } from '@mui/material';

export const Counter = styled(Box)`
  && {
    width: fit-content;
    border-radius: 40px;
    display: inline-flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.palette.primary.dark};
    background: ${(props) => props.theme.palette.common.white};

    .MuiIconButton-root {
      color: ${(props) => props.theme.palette.primary.dark};
    }

    .MuiTypography-root {
      text-align: center;
      margin: auto;
      min-width: 48px;
      padding: ${(props) => props.theme.spacing(0, 8)};
      display: inline-block;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      .MuiTypography-root {
        min-width: 30px;
        padding: ${(props) => props.theme.spacing(0, 4)};
      }
    }
  }
`;
