import { alpha, ListItem } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import { primary } from '../../../theme/colors';

export const UserList = styled(PerfectScrollbar)`
  max-height: 294px;
  flex: 1;

  .ps__rail-y {
    z-index: 1;
  }
`;

export const User = styled(ListItem)`
  && {
    border-radius: 36px;
    background-color: ${alpha(primary[30], 0.33)};
    padding: ${(props) => props.theme.spacing(16, 30, 16, 16)};

    .MuiListItemAvatar-root {
      .MuiAvatar-root {
        width: 40px;
        height: 40px;
        color: ${primary[100]};
        background-color: ${primary[30]};
        ${(props) => props.theme.typography['label-s']};
      }
    }

    .MuiListItemText-root {
      margin: ${(props) => props.theme.spacing(-7, 0, -8)};

      .MuiListItemText-primary {
        ${(props) => props.theme.typography['label-l']};
        color: ${(props) => props.theme.palette.primary.dark};
        margin-bottom: ${(props) => props.theme.spacing(3)};
      }

      .MuiListItemText-secondary {
        ${(props) => props.theme.typography['body-s']};
        color: ${primary[90]};
      }
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
      padding: ${(props) => props.theme.spacing(12, 30, 12, 12)};
    }
  }
`;
