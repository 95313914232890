import { Box, ButtonBase, Container as MuiContainer, InputBase, Stack } from '@mui/material';
import { primary } from '../../../theme/colors';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(MuiContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled(Box)<{ isAdmin: boolean }>`
  background-color: ${(props) => props.theme.palette.primary.contrastText};
  position: relative;
  padding: ${(props) => props.theme.spacing(20, 0)};
  height: 80px;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${(props) => props.theme.spacing(16, 0)};
    height: 64px;
  }

  ${(props) =>
    props.isAdmin &&
    css`
      height: 78.5px;
      border-bottom: 1.5px solid ${(props) => props.theme.palette.primary.main};

      ${(props) => props.theme.breakpoints.down('md')} {
        height: 62.5px;
      }
    `}

  ${Container} {
    ${(props) =>
      props.isAdmin &&
      css`
        max-width: 1872px;
      `}
  }
`;

export const Logo = styled(Link)`
  cursor: pointer;

  div {
    height: 40px;

    img {
      height: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    div {
      height: 30px;
    }
  }
`;

export const MenuItem = styled(ButtonBase)`
  text-decoration: none;
  ${(props) => props.theme.typography['label-s']};
  color: ${primary[90]};
`;

export const Menu = styled(Stack)<{ isOpen: boolean }>`
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.palette.primary.contrastText};

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    top: 65px;
    width: 100%;
    z-index: 1100;
    flex-direction: column;
    padding: ${(props) => props.theme.spacing(12)};

    ${MenuItem} {
      display: block;
      padding: ${(props) => props.theme.spacing(12, 24)};
    }
  }
`;

export const Toolbar = styled(Stack)`
  align-items: center;

  .MuiIconButton-root {
    color: ${(props) => props.theme.palette.primary.dark};
  }
`;

export const SearchInput = styled(InputBase)`
  && {
    border-radius: 4px;
    background-color: ${primary[40]};

    input {
      padding: ${(props) => props.theme.spacing(4, 0, 5)};
    }

    .MuiInputAdornment-root {
      margin: ${(props) => props.theme.spacing(4, 8)};
    }
  }
`;

export const Cart = styled(Box)<{ isShop: boolean }>`
  height: 60px;
  position: relative;
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.spacing(-20)} !important;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    left: 50%;
    height: 3px;
    background: ${(props) => props.theme.palette.primary.dark};
    border-radius: ${(props) => props.theme.spacing(3, 3, 0, 0)};
    transition-duration: 500ms;
  }

  &:hover {
    &:after {
      width: 100%;
      left: 0;
    }
  }

  ${(props) =>
    props.isShop &&
    css`
      &:after {
        width: 100%;
        left: 0;
      }
    `}

  ${(props) => props.theme.breakpoints.down('md')} {
    height: 47px;
    margin-bottom: ${(props) => props.theme.spacing(-17)} !important;
  }

  .MuiBadge-badge {
    width: 6px;
    height: 6px;
    min-width: unset;
  }
`;
