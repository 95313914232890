import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

export const Toolbar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing(32)};
  border-bottom: 1px solid ${(props) => props.theme.palette.text.secondary};

  input {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: ${(props) => props.theme.spacing(16)};
  }
`;

export const Actions = styled(Stack)`
  align-items: center;
`;

export const Content = styled(PerfectScrollbar)`
  height: calc(100vh - 200px);
  padding-top: ${(props) => props.theme.spacing(16)};

  .ps__rail-y {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    height: calc(100vh - 150px);
  }
`;
