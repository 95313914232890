import styled from 'styled-components';
import { Box } from '@mui/material';

export const ToggleInput = styled(Box)<{ checked?: boolean }>`
  position: relative;

  .MuiInputBase-input {
    color: ${(props) => (props.checked ? props.theme.palette.success.main : props.theme.palette.text.secondary)};
  }

  .MuiSwitch-root {
    top: 14px;
    right: 14px;
    position: absolute;
  }
`;
