import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

export const TextInfo = styled.div.attrs((props: any) => ({
  size: props?.size
}))`
  color: ${(props) => props.theme.palette.primary[90]};
  font-size: ${(prop) => prop.size};
`;

export const OrderWrapper = styled(Stack)`
  margin: 0;
  height: calc(100vh - 130px);
  overflow-y: auto;
`;

export const Detail = styled(Box)`
  width: 100%;
  border-left: 1px solid ${(props) => props.theme.palette.primary.main};

  ${(props) => props.theme.breakpoints.down('lg')} {
    border-left: none;
  }
`;

export const Toolbar = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};

  ${(props) => props.theme.breakpoints.down('lg')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MuiIconButton-root {
    margin-right: ${(props) => props.theme.spacing(10)};
    display: none;

    ${(props) => props.theme.breakpoints.down('lg')} {
      display: flex;
    }
  }
`;
