import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material';
import { AddCartIcon, ArrowLeftIcon, AscOrderIcon, DeleteIcon } from '../../../../assets/icons';
import { Card, PrintDetailModal } from '../../../../components';
import { FC, useEffect, useMemo, useState } from 'react';
import { setAccount } from '../../../../store/actions';
import { PrintApi, ShopApi } from '../../../../apis';
import { IPrint } from '../../../../interfaces';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as S from './styles';

export const PrintListPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [prints, setPrints] = useState<IPrint[]>([]);
  const [selectedPrint, setSelectedPrint] = useState<IPrint>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSelectedAll = useMemo(() => {
    return prints.length === selectedIds.length && prints.length > 0;
  }, [selectedIds, prints]);

  const isIndeterminate = useMemo(() => {
    return prints.length > selectedIds.length && selectedIds.length > 0;
  }, [selectedIds, prints]);

  const fetchPrints = () => {
    setLoading(true);
    PrintApi.readAll()
      .then((res) => {
        setPrints(res.prints);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelect = (id) => () => {
    if (selectedIds.indexOf(id) > -1) {
      setSelectedIds(selectedIds.filter((selected) => id !== selected));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedIds.length === prints.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds([...prints.map(({ _id }) => _id)]);
    }
  };

  const handleDelete = (id) => () => {
    setLoading(true);
    PrintApi.remove(id)
      .then(() => {
        setSelectedIds([]);
        fetchPrints();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDeleteAll = () => {
    setLoading(true);
    PrintApi.removeAll(selectedIds)
      .then(() => {
        setSelectedIds([]);
        fetchPrints();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleMultiToggleToCart = () => {
    setLoading(true);
    ShopApi.toggleToCart(selectedIds)
      .then((res) => {
        dispatch(setAccount(res.user));
        setSelectedIds([]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseDetailModal = () => {
    setOpenDetailModal(false);
  };

  const handleOpenDetailModal = (id) => () => {
    setSelectedPrint(id);
    setOpenDetailModal(true);
  };

  const handleDeleted = () => {
    setSelectedIds([]);
    fetchPrints();
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchPrints();
  }, []);

  return (
    <Container>
      <S.Toolbar>
        <S.Actions direction="row" spacing={{ xs: 4, md: 8 }} divider={<Divider orientation="vertical" flexItem />}>
          <Button variant="text" startIcon={<ArrowLeftIcon />} onClick={handleBack}>
            {t('print.back')}
          </Button>
          <Box>
            <Tooltip title={t(isSelectedAll ? 'common.deselect_all' : 'common.select_all')}>
              <Checkbox
                disabled={loading}
                indeterminate={isIndeterminate}
                checked={isSelectedAll}
                onChange={handleSelectAll}
              />
            </Tooltip>
            <Tooltip title={t('common.add_to_cart')}>
              <IconButton disabled={loading} onClick={handleMultiToggleToCart}>
                <AddCartIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('common.delete')}>
              <IconButton disabled={loading || selectedIds.length === 0} onClick={handleDeleteAll}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Tooltip title={t('common.sort')}>
            <IconButton disabled={loading}>
              <AscOrderIcon />
            </IconButton>
          </Tooltip>
        </S.Actions>
      </S.Toolbar>
      <S.Content>
        {loading ? (
          <Stack width="1" height={300} alignItems="center" justifyContent="center">
            <CircularProgress color="secondary" />
          </Stack>
        ) : (
          <Grid container spacing={16}>
            {prints.map((print, index) => (
              <Grid key={index} item xs={12} md={6} lg={4} xl={3}>
                <Card
                  selected={selectedIds.indexOf(print._id) > -1}
                  title={print.name}
                  thumbnail={print.photo}
                  printId={print._id}
                  onDetail={handleOpenDetailModal(print)}
                  onDelete={handleDelete(print._id)}
                  onSelect={handleSelect(print._id)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </S.Content>
      {openDetailModal && selectedPrint && (
        <PrintDetailModal
          open={openDetailModal}
          print={selectedPrint}
          onClose={handleCloseDetailModal}
          onDeleted={handleDeleted}
        />
      )}
    </Container>
  );
};
