import styled from 'styled-components';
import { Box } from '@mui/material';

export const Toolbar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${(props) => props.theme.spacing(4, 20)};
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
  height: 49px;

  ${(props) => props.theme.breakpoints.down('xl')} {
    padding: ${(props) => props.theme.spacing(4, 12)};
  }
`;
