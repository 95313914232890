import styled from 'styled-components';
import { Box } from '@mui/material';

export const Body = styled(Box)`
  display: flex;
  height: calc(100vh - 80px);

  ${(props) => props.theme.breakpoints.down('md')} {
    height: calc(100vh - 64px);
  }
`;

export const Content = styled(Box)`
  display: flex;
  width: 100%;
  flex: 1;
`;
