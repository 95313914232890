import { IPhoto } from '../interfaces';
import { Http } from '../utils';
import { ENDPOINTS } from '../constants';

const create = (photo: FormData, onUpload): Promise<any> => {
  return Http.post(ENDPOINTS.PHOTO.BASE, photo, onUpload);
};

const read = (id: string): Promise<any> => {
  return Http.get(ENDPOINTS.PHOTO.ID.replace(':id', id));
};

const update = (id: string, photo: IPhoto): Promise<any> => {
  return Http.patch(ENDPOINTS.PHOTO.ID.replace(':id', id), photo);
};

const remove = (id: string): Promise<any> => {
  return Http.delete(ENDPOINTS.PHOTO.ID.replace(':id', id));
};

const removeAll = (ids: string[], galleryId: string): Promise<any> => {
  return Http.delete(ENDPOINTS.PHOTO.BASE, { ids, galleryId });
};

export const PhotoApi = {
  create,
  read,
  update,
  remove,
  removeAll
};
