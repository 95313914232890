import { Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { AccountBoxIcon, CloseIcon, EmailIcon, LockIcon } from '../../../assets/icons';
import { getAccount } from '../../../store/selectors';
import { AuthApi, UserApi } from '../../../apis';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useFormik } from 'formik';
import * as S from './styles';
import * as Yup from 'yup';

interface IProfileModalProps {
  open: boolean;
  onClose: () => void;
}

export const NewUserModal: FC<IProfileModalProps> = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fields = [
    {
      name: 'firstName',
      icon: <AccountBoxIcon />,
      label: t('sign_up.first_name')
    },
    {
      name: 'lastName',
      icon: <AccountBoxIcon />,
      label: t('sign_up.last_name')
    },
    {
      name: 'email',
      icon: <EmailIcon />,
      label: t('sign_up.email')
    },
    {
      name: 'password',
      icon: <LockIcon />,
      type: 'password',
      label: t('sign_up.password')
    },
    {
      name: 'confirmPassword',
      icon: <LockIcon />,
      type: 'password',
      label: t('sign_up.repeat_password')
    }
  ];

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('validation.required')),
    lastName: Yup.string().required(t('validation.required')),
    email: Yup.string().email(t('validation.invalid_email')).required(t('validation.required')),
    password: Yup.string().min(8, t('validation.invalid_length')).required(t('validation.required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Confirm password does not matched!')
      .required(t('validation.required'))
  });

  const { values, errors, touched, isSubmitting, setSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    onSubmit: (values) => {
      AuthApi.register(values)
        .then((res) => {
          onClose();
          enqueueSnackbar(res.msg, { variant: 'success' });
        })
        .catch((err) => {
          if (err.status === 409) {
            enqueueSnackbar(t('message.email_exist'), { variant: 'warning' });
          } else {
            enqueueSnackbar(t('message.network_error'), { variant: 'warning' });
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const handleCreateUser = () => {
    handleSubmit();
  };

  return (
    <S.Modal open={open} onClose={onClose}>
      <S.Header>
        <Typography color="primary.dark" variant="title-m">
          {t('user.new_user')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </S.Header>
      <S.Content>
        <S.Information spacing={20}>
          {fields.map(({ name, icon, type = 'text', label }, index) => (
            <TextField
              key={index}
              name={name}
              type={type}
              label={label}
              value={values[name]}
              disabled={isSubmitting}
              error={Boolean(errors[name] && touched[name])}
              helperText={Boolean(errors[name] && touched[name]) && errors[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                startAdornment: icon && <InputAdornment position="start">{icon}</InputAdornment>
              }}
            />
          ))}
          <Button disabled={isSubmitting} onClick={handleCreateUser}>{t('user.create_user')}</Button>
        </S.Information>
      </S.Content>
    </S.Modal>
  );
};
