import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemText,
  styled,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useEffect, useMemo, useState } from 'react';
import { ISetting } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import { SettingApi } from '../../../apis';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export const SettingsPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  // const [rowSelected, setRowSelected] = useState<string[]>([]);
  // const [lastRowSelected, setLastRowSelected] = useState<string | null>(null);
  const [settings, setSettings] = useState<ISetting[]>([]);
  const [expandedCards, setExpandedCards] = useState<string[]>([]);

  const { t } = useTranslation();

  const fetchSettings = () => {
    setLoading(true);
    SettingApi.readAll({})
      .then((res) => {
        setSettings(res.settings);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleSwitchChange = (e, field, id) => {
    setSettings(
      settings.map((setting) => {
        if (setting._id === id) {
          return {
            ...setting,
            value: {
              ...(setting.value || {}),
              [field]: e.target.checked,
              ...(field === 'isTestMode' && e.target.checked ? { isProductionMode: false } : {}),
              ...(field === 'isProductionMode' && e.target.checked ? { isTestMode: false } : {})
            }
          };
        }

        return setting;
      })
    );
  };

  const handleTextChange = (e, field, id) => {
    setSettings(
      settings.map((setting) => {
        if (setting._id === id) {
          return {
            ...setting,
            value: {
              ...(setting.value || {}),
              [field]: e.target.value
            }
          };
        }

        return setting;
      })
    );
  };

  const handleExpandClick = (settingId) => {
    if (expandedCards.includes(settingId)) {
      setExpandedCards(expandedCards.filter((id) => id !== settingId));
    } else {
      setExpandedCards([...expandedCards, settingId]);
    }
  };

  const handleSave = async (id) => {
    setIsSaving(true);
    try {
      const setting = settings.find((setting) => setting._id === id);
      if (setting && setting._id) {
        await SettingApi.update(id, setting);
      }
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  };

  const generalSettings = useMemo(() => settings.filter((setting) => setting.type === 'general'), [settings]);
  const paymentSettings = useMemo(() => settings.filter((setting) => setting.type === 'payment'), [settings]);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ height: '100%' }}>
        <Box sx={{ height: '100%', borderLeft: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)} variant="fullWidth">
            <Tab label={t('settings.general')} />
            <Tab label={t('settings.payment_methods')} />
          </Tabs>
          <Box sx={{ height: 'calc(100% - 48px)', borderTop: 1, borderColor: 'divider' }}>
            <Box sx={{ height: '100%', overflow: 'auto', maxHeight: '100%' }}>
              {activeTab === 0 ? (
                <Box>
                  <List>
                    {(generalSettings || []).map((setting) => (
                      <ListItem key={setting._id}>
                        <ListItemText>
                          <Typography color="text.disabled">{setting.displayName}:</Typography>
                        </ListItemText>
                        <Switch disabled />
                      </ListItem>
                    ))}
                    <ListItem>
                      <Button variant="contained" disabled>
                        {t('settings.change_super_admin_password')}
                      </Button>
                    </ListItem>
                  </List>
                </Box>
              ) : activeTab === 1 ? (
                <Box>
                  <List>
                    {(paymentSettings || []).map((setting) => (
                      <Card key={setting._id} sx={{ width: '100%', marginBottom: '2em' }}>
                        <CardHeader
                          action={
                            <ExpandMore
                              expand={expandedCards.includes(setting._id)}
                              onClick={() => handleExpandClick(setting._id)}
                              aria-expanded={expandedCards.includes(setting._id)}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </ExpandMore>
                          }
                          title={setting.displayName}
                        />
                        <Collapse in={expandedCards.includes(setting._id)} timeout="auto" unmountOnExit>
                          {setting.key === 'STRIPE' ? (
                            <CardContent>
                              <FormControlLabel
                                sx={{ width: '100%', justifyContent: 'start' }}
                                checked={setting?.value?.enabled}
                                control={<Switch checked={setting?.value?.enabled} />}
                                label={t('settings.is_enabled') + '?'}
                                labelPlacement="start"
                                onChange={(e) => handleSwitchChange(e, 'enabled', setting._id)}
                              />
                              <FormControlLabel
                                sx={{ width: '100%', justifyContent: 'start' }}
                                checked={setting?.value?.isTestMode}
                                control={<Switch checked={setting?.value?.isTestMode} />}
                                label={t('settings.is_test_mode') + '?'}
                                labelPlacement="start"
                                onChange={(e) => handleSwitchChange(e, 'isTestMode', setting._id)}
                              />
                              <FormControlLabel
                                sx={{ width: '100%', justifyContent: 'start' }}
                                checked={setting?.value?.isProductionMode}
                                control={<Switch checked={setting?.value?.isProductionMode} />}
                                label={t('settings.is_production_mode') + '?'}
                                labelPlacement="start"
                                onChange={(e) => handleSwitchChange(e, 'isProductionMode', setting._id)}
                              />
                              {(setting.value?.isTestMode) && (
                                <>
                                  <TextField
                                    fullWidth
                                    value={setting?.value?.testModePublicKey}
                                    label={t('settings.public_key')}
                                    variant="standard"
                                    onChange={(e) => handleTextChange(e, 'testModePublicKey', setting._id)}
                                  />
                                  <TextField
                                    fullWidth
                                    value={setting?.value?.testModeSecretKey}
                                    label={t('settings.secret_key')}
                                    variant="standard"
                                    onChange={(e) => handleTextChange(e, 'testModeSecretKey', setting._id)}
                                  />
                                </>
                              )}
                              {(setting.value?.isProductionMode) && (
                                <>
                                  <TextField
                                    fullWidth
                                    value={setting?.value?.productionModePublicKey}
                                    label={t('settings.public_key')}
                                    variant="standard"
                                    onChange={(e) => handleTextChange(e, 'productionModePublicKey', setting._id)}
                                  />
                                  <TextField
                                    fullWidth
                                    value={setting?.value?.productionModeSecretKey}
                                    label={t('settings.secret_key')}
                                    variant="standard"
                                    onChange={(e) => handleTextChange(e, 'productionModeSecretKey', setting._id)}
                                  />
                                </>
                              )}
                              <Button
                                fullWidth
                                variant="outlined"
                                disabled={isSaving}
                                onClick={() => handleSave(setting._id)}
                                sx={{ marginTop: '3em' }}
                              >
                                {t('settings.save')}
                              </Button>
                            </CardContent>
                          ) : (
                            ''
                          )}
                        </Collapse>
                      </Card>
                    ))}
                  </List>
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
