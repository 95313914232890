import { alpha, Components } from '@mui/material';
import { primary } from './colors';

const components: Components = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        'display-l': 'h1',
        'display-m': 'h2',
        'display-s': 'h3',
        'headline-l': 'h4',
        'headline-m': 'h5',
        'headline-s': 'h6',
        'title-l': 'h6',
        'title-m': 'h6',
        'title-s': 'h6',
        'label-l': 'p',
        'label-m': 'p',
        'label-s': 'span',
        'body-l': 'p',
        'body-m': 'p',
        'body-s': 'span'
      },
      variant: 'body-s'
    }
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: 'xl',
      fixed: true
    },
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          paddingLeft: theme.spacing(20),
          paddingRight: theme.spacing(20),
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(20),
            paddingRight: theme.spacing(20)
          }
        })
      }
    ]
  },
  MuiInputAdornment: {
    variants: [
      {
        props: { position: 'start' },
        style: ({ theme }) => ({
          width: 20,
          height: 20,
          justifyContent: 'center',
          margin: theme.spacing(8, 16, 8, 12)
        })
      }
    ]
  },
  MuiInputBase: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          ...theme.typography['body-s']
        })
      }
    ]
  },
  MuiInput: {
    variants: [
      {
        props: { color: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.primary.main,
          ':before': {
            borderBottomColor: alpha(theme.palette.primary.main, 0.33)
          },
          ':after': {
            borderBottomColor: alpha(theme.palette.primary.main, 0.33)
          },
          '.MuiInputAdornment-root': {
            color: theme.palette.primary.main
          }
        })
      },
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          height: 32,
          '.MuiInputBase-input': {
            padding: theme.spacing(2, 0)
          }
        })
      },
      {
        props: { size: 'medium' },
        style: () => ({
          height: 36
        })
      }
    ]
  },
  MuiFilledInput: {
    variants: [
      {
        props: {},
        style: () => ({
          ':before': {
            borderBottom: 'none !important'
          },
          ':after': {
            display: 'none'
          },
          '.MuiSelect-select': {
            ':focus': {
              backgroundColor: 'transparent'
            }
          }
        })
      },
      {
        props: { color: 'primary' },
        style: ({ theme }) => ({
          backgroundColor: `${theme.palette.primary.light} !important`
        })
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          borderRadius: 4,
          '.MuiFilledInput-input': {
            padding: theme.spacing(6, 12, 7)
          }
        })
      }
    ]
  },
  MuiOutlinedInput: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          ...theme.typography['body-l'],
          '&.Mui-error': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.palette.error.main} !important`
            }
          }
        })
      },
      {
        props: { color: 'primary' },
        style: ({ theme }) => ({
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.dark
            }
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main
          }
        })
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          '&.MuiInputBase-multiline': {
            padding: 0
          },
          '.MuiOutlinedInput-input': {
            padding: theme.spacing(14.5, 15, 12),
            '&.MuiInputBase-inputMultiline': {
              padding: theme.spacing(14.5, 15, 12)
            }
          }
        })
      }
    ]
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard'
    },
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          '.MuiInputLabel-root.Mui-error': {
            color: `${theme.palette.error.main} !important`
          }
        })
      },
      {
        props: { color: 'primary' },
        style: ({ theme }) => ({
          '.MuiInputLabel-root': {
            color: theme.palette.primary.main,
            '&.Mui-focused': {
              color: theme.palette.primary.dark
            }
          }
        })
      },
      {
        props: { variant: 'outlined' },
        style: ({ theme }) => ({
          '.MuiInputLabel-root': {
            ...theme.typography['body-l'],
            transform: 'translate(14px, 14px) scale(1)',
            [theme.breakpoints.down('md')]: {
              transform: 'translate(14px, 14px) scale(1)'
            },
            '&.Mui-focused, &.MuiInputLabel-shrink': {
              transform: 'translate(16px, -11px) scale(0.7)',
              [theme.breakpoints.down('md')]: {
                transform: 'translate(14px, -7px) scale(0.7)'
              }
            }
          }
        })
      }
    ]
  },
  MuiAutocomplete: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          '.MuiOutlinedInput-root': {
            padding: theme.spacing(7, 10, 7.5),
            '.MuiAutocomplete-tag': {
              margin: theme.spacing(5.5, 4, 5.5, 0)
            },
            '.MuiAutocomplete-input': {
              padding: theme.spacing(5.5, 4, 5.5, 0)
            }
          },
          '.MuiAutocomplete-endAdornment': {
            display: 'none'
          }
        })
      }
    ]
  },
  MuiCheckbox: {
    variants: [
      {
        props: { color: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.surfaceVariant.outline,
          '&.Mui-checked': {
            color: theme.palette.primary.dark
          },
          '&.MuiCheckbox-indeterminate': {
            color: theme.palette.primary.dark
          }
        })
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          padding: theme.spacing(8),
          [theme.breakpoints.down('md')]: {
            width: 32,
            height: 32
          }
        })
      }
    ]
  },
  MuiRadio: {
    variants: [
      {
        props: { color: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.text.secondary,
          '&.Mui-checked': {
            color: theme.palette.text.secondary
          }
        })
      },
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          padding: theme.spacing(8)
        })
      }
    ]
  },
  MuiSwitch: {
    defaultProps: {
      color: 'success'
    },
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          '.MuiSwitch-switchBase': {
            '&:hover': {
              background: 'none'
            },
            '.MuiSwitch-thumb': {
              boxShadow: 'none',
              backgroundColor: theme.palette.text.secondary
            }
          },
          '.MuiSwitch-switchBase.Mui-checked': {
            '.MuiSwitch-thumb': {
              backgroundColor: theme.palette.common.white
            },
            '&+.MuiSwitch-track': {
              opacity: 1
            }
          },
          '.MuiSwitch-track': {
            opacity: 1,
            background: '#E1E1E1',
            borderRadius: 'inherit',
            border: `2px solid ${theme.palette.text.secondary}`
          }
        })
      },
      {
        props: {
          size: 'medium'
        },
        style: ({ theme }) => ({
          width: 52,
          height: 32,
          borderRadius: 16,
          padding: 0,
          '.MuiSwitch-switchBase': {
            padding: theme.spacing(8),
            '.MuiSwitch-thumb': {
              width: 16,
              height: 16
            },
            '&.Mui-checked': {
              padding: theme.spacing(4),
              '.MuiSwitch-thumb': {
                width: 24,
                height: 24
              }
            }
          }
        })
      },
      {
        props: { color: 'success' },
        style: ({ theme }) => ({
          '.MuiSwitch-switchBase.Mui-checked': {
            '&+.MuiSwitch-track': {
              borderColor: theme.palette.success.main
            }
          }
        })
      }
    ]
  },
  MuiButton: {
    defaultProps: {
      color: 'secondary',
      variant: 'contained',
      disableElevation: true
    },
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          textTransform: 'initial',
          ...theme.typography['label-s'],
          '.MuiButton-startIcon': {
            width: 18,
            height: 18,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          '.MuiButton-endIcon': {
            width: 18,
            height: 18,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        })
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          height: 40,
          borderRadius: 22,
          padding: theme.spacing(9, 16, 10),
          '.MuiButton-startIcon': {
            margin: theme.spacing(0, 12, 0, 0)
          },
          '.MuiButton-endIcon': {
            margin: theme.spacing(0, 0, 0, 12)
          },
          [theme.breakpoints.down('md')]: {
            height: 32,
            borderRadius: 16,
            padding: theme.spacing(10, 12),
            '.MuiButton-startIcon': {
              margin: theme.spacing(0, 8, 0, 0)
            },
            '.MuiButton-endIcon': {
              margin: theme.spacing(0, 0, 0, 8)
            }
          }
        })
      },
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          height: 36,
          borderRadius: 18,
          padding: theme.spacing(7, 12, 8),
          '.MuiButton-startIcon': {
            margin: theme.spacing(0, 8, 0, 0)
          }
        })
      }
    ]
  },
  MuiIconButton: {
    variants: [
      {
        props: { color: 'default' },
        style: ({ theme }) => ({
          ...theme.typography['label-s'],
          color: theme.palette.surfaceVariant.outline,
          ':hover': {
            color: theme.palette.primary.dark
          }
        })
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          width: 40,
          height: 40,
          [theme.breakpoints.down('md')]: {
            width: 32,
            height: 32,
            svg: {
              maxHeight: 16,
              maxWidth: 16
            }
          }
        })
      },
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          width: 32,
          height: 32,
          [theme.breakpoints.down('md')]: {
            width: 26,
            height: 26
          }
        })
      }
    ]
  },
  MuiFab: {
    variants: [
      {
        props: { size: 'medium' },
        style: () => ({
          width: 40,
          height: 40
        })
      }
    ]
  },
  MuiSpeedDial: {
    defaultProps: {
      FabProps: {
        size: 'medium'
      }
    }
  },
  MuiSpeedDialAction: {
    defaultProps: {
      tooltipTitle: ''
    }
  },
  MuiDivider: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          '.MuiDivider-wrapper': {
            ...theme.typography['body-s'],
            color: theme.palette.primary.main,
            padding: theme.spacing(0, 9)
          }
        })
      }
    ]
  },
  MuiChip: {
    defaultProps: {
      color: 'primary'
    },
    variants: [
      {
        props: { variant: 'outlined', color: 'primary' },
        style: ({ theme }) => ({
          backgroundColor: primary[30],
          '.MuiAvatar-root': {
            color: theme.palette.common.white,
            backgroundColor: '#49454f'
          },
          '.MuiChip-label': {
            ...theme.typography['title-s'],
            color: theme.palette.primary.dark
          },
          '.MuiIconButton-root': {
            width: 24,
            height: 24
          }
        })
      }
    ]
  },
  MuiPagination: {
    defaultProps: {
      shape: 'circular'
    }
  },
  MuiTabs: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          '.MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.dark
          }
        })
      }
    ]
  },
  MuiTab: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          ...theme.typography['label-l'],
          textTransform: 'unset',
          padding: theme.spacing(9, 16, 10),
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.dark, 0.33)
          },
          '&.Mui-selected': {
            color: theme.palette.primary.dark
          },
          '&:first-of-type': {
            borderTopLeftRadius: 4
          },
          '&:last-of-type': {
            borderTopRightRadius: 4
          }
        })
      }
    ]
  },
  MuiCircularProgress: {
    defaultProps: {
      color: 'secondary'
    }
  },
  MuiSlider: {
    variants: [
      {
        props: { color: 'primary' },
        style: ({ theme }) => ({
          // color: '#52af77',
          height: 4,
          '& .MuiSlider-track': {
            border: 'none',
            backgroundColor: theme.palette.primary.dark
          },
          '& .MuiSlider-thumb': {
            height: 20,
            width: 20,
            backgroundColor: theme.palette.primary.dark,
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 10px ${alpha(theme.palette.primary.dark, 0.08)}`
            },
            '&:before': {
              display: 'none'
            },
            '&:after': {
              width: 40,
              height: 40
            }
          },
          '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            top: -4,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: theme.palette.primary.dark,
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
              transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
            },
            '& > *': {
              transform: 'rotate(45deg)'
            }
          }
        })
      }
    ]
  }
};

export default components;
