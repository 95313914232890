import { alpha, Box, Dialog, ListItem } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { primary } from '../../../theme/colors';
import styled from 'styled-components';

export const Modal = styled(Dialog)`
  && {
    .MuiDialog-paper {
      max-width: 468px;
      border-radius: 16px;
    }
  }
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${primary[30]};
  padding: ${(props) => props.theme.spacing(10, 16)};
`;

export const Content = styled(PerfectScrollbar)`
  padding: ${(props) => props.theme.spacing(32, 24, 24)};
`;

export const Gallery = styled(ListItem)`
  && {
    border-radius: 12px;
    background-color: ${alpha(primary[30], 0.33)};
    margin-bottom: ${(props) => props.theme.spacing(32)};
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    padding: ${(props) => props.theme.spacing(19, 0, 19, 16)};

    .MuiListItemAvatar-root {
      .MuiAvatar-root {
        width: 40px;
        height: 40px;
        color: ${primary[100]};
        background-color: ${primary[30]};
        ${(props) => props.theme.typography['label-s']};
      }
    }

    .MuiListItemText-root {
      margin: ${(props) => props.theme.spacing(-6, 0)};

      .MuiListItemText-primary {
        ${(props) => props.theme.typography['label-l']};
        color: ${(props) => props.theme.palette.primary.dark};
        margin-bottom: ${(props) => props.theme.spacing(0)};
      }

      .MuiListItemText-secondary {
        ${(props) => props.theme.typography['body-s']};
        color: ${primary[90]};
      }
    }

    .MuiListItemSecondaryAction-root {
      right: 0;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      border-radius: 8px;
      padding: ${(props) => props.theme.spacing(14, 0, 14, 12)};
    }
  }
`;

export const GalleryLogo = styled(Box)`
  background-color: ${(props) => props.theme.palette.common.white};
  width: 78px;
  height: 78px;
  border-radius: 0 11px 11px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 68px;
    height: 68px;
    border-radius: 0 11px 11px 0;
  }
`;

export const Actions = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin: ${(props) => props.theme.spacing(24)};

  .MuiButton-root {
    max-width: 91px;
    width: 100%;
    margin-left: ${(props) => props.theme.spacing(16)};
  }
`;
