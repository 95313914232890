import { Header } from '../../components';
import { Sidebar } from './Sidebar';
import * as S from './styles';
import { FC } from 'react';

export const AdminLayout: FC = ({ children }) => {
  return (
    <>
      <Header />
      <S.Body>
        <Sidebar />
        <S.Content>{children}</S.Content>
      </S.Body>
    </>
  );
};
