import PerfectScrollbar from 'react-perfect-scrollbar';
import { alpha, Box, Stack } from '@mui/material';
import styled, { css } from 'styled-components';

export const Printing = styled(Box)`
  margin-left: auto;
  max-width: 568px;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('lg')} {
    margin-left: unset;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 400px;
    margin: auto;
  }
`;

export const Dropzone = styled(Box)`
  width: 100%;
  height: 569px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: auto;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.palette.text.secondary};
  border-radius: 16px;

  ${(props) => props.theme.breakpoints.down('lg')} {
    height: 420px;
  }
`;

export const Photo = styled.img`
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: -1;

  ${(props) => props.theme.breakpoints.down('lg')} {
    height: 420px;
  }
`;

export const Form = styled(Stack)`
  max-width: 400px;

  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 100%;
    max-height: 280px;
  }

  .MuiSelect-select.MuiSelect-standard.MuiInput-input {
    padding-right: ${(props) => props.theme.spacing(20)};
  }
`;

export const Content = styled(PerfectScrollbar)`
  margin: ${(props) => props.theme.spacing(88, 0, 24)};

  ${(props) => props.theme.breakpoints.down('md')} {
    margin: ${(props) => props.theme.spacing(40, 0, 24)};
  }
`;

export const Frames = styled(Stack)`
  width: fit-content;
  padding-bottom: ${(props) => props.theme.spacing(12)};
`;

export const Frame = styled(Box)<{ selected: boolean }>`
  width: 160px;
  height: 160px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.palette.primary.main};

  &:hover {
    border-color: ${(props) => props.theme.palette.common.black};
    box-shadow: 0 3px 6px ${(props) => alpha(props.theme.palette.primary.dark, 0.16)};
  }

  ${(props) =>
    props.selected &&
    css`
      border: 2px solid ${(props) => props.theme.palette.primary.dark} !important;
    `}

  img {
    max-width: 80%;
    max-height: 80%;
    width: auto;
    height: auto;
  }
`;
