import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  height: 139px;
  margin-bottom: ${(props) => props.theme.spacing(10.5)};

  ${(props) => props.theme.breakpoints.down('md')} {
    height: 80px;
  }
`;

export const Form = styled(Stack)`
  max-width: 400px;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(100)};

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: ${(props) => props.theme.spacing(50)};
  }
`;
