import { Avatar, Button, IconButton, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CloseIcon } from '../../../assets/icons';
import { SearchPeople } from '../../Common';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { GalleryApi } from '../../../apis';
import { IAccount, IGallery } from '../../../interfaces';
import { API_SERVER } from '../../../constants';

interface IShareModalProps {
  open: boolean;
  galleryId: string;
  onClose: () => void;
}

export const ShareModal: FC<IShareModalProps> = ({ open, galleryId, onClose }) => {
  const { t } = useTranslation();
  const [gallery, setGallery] = useState<IGallery>();
  const [selectedUsers, setSelectedUsers] = useState<IAccount[]>([]);

  const fetchGallery = () => {
    GalleryApi.read(galleryId)
      .then((res) => {
        setGallery(res.gallery);
        setSelectedUsers(res.gallery.shareWith);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePeopleChange = (value) => {
    setSelectedUsers(value);
  };

  const handleShare = () => {
    GalleryApi.update(galleryId, { shareWith: [...selectedUsers] })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchGallery();
  }, [galleryId]);

  return (
    <S.Modal fullWidth open={open} onClose={onClose}>
      <S.Header>
        <Typography color="primary.dark" variant="title-m">
          {t('gallery.share_gallery')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </S.Header>
      <S.Content>
        <S.Gallery
          secondaryAction={
            <S.GalleryLogo>
              <img src={`${API_SERVER}/${gallery?.logo.filename}`} alt={gallery?.logo.filename} />
            </S.GalleryLogo>
          }
        >
          <ListItemAvatar>
            <Avatar>A</Avatar>
          </ListItemAvatar>
          <ListItemText primary={gallery?.name} secondary={gallery?.createdBy.name} />
        </S.Gallery>
        <SearchPeople value={selectedUsers} onChange={handlePeopleChange} />
      </S.Content>
      <S.Actions>
        <Button onClick={handleShare}>{t('gallery.done')}</Button>
      </S.Actions>
    </S.Modal>
  );
};
