import { alpha, ButtonBase, Popover, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

export const Head = styled(TableHead)`
  margin-bottom: ${(props) => props.theme.spacing(8)};
  position: sticky;
  top: 0;
  z-index: 1;
  height: 40px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: 0 1px 0 ${(props) => props.theme.palette.primary.main};
`;

export const Row = styled(TableRow)``;

export const Cell = styled(TableCell)`
  height: 36px;
  ${(props) => props.theme.typography.overline};
  color: ${(props) => props.theme.palette.text.disabled};
  padding: ${(props) => props.theme.spacing(4, 12)};
  border-bottom: none;
  white-space: nowrap;

  ${(props) => props.theme.breakpoints.down('xl')} {
    ${(props) => props.theme.typography['body-s']};
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${(props) => props.theme.spacing(4, 8)};
  }

  .MuiCheckbox-root {
    opacity: 1;
    color: ${(props) => props.theme.palette.text.disabled};
  }
`;

export const SortLabel = styled(TableSortLabel)`
  && {
    flex-direction: row;
    text-transform: none;
    color: ${(props) => props.theme.palette.surfaceVariant.on};
    padding: ${(props) => props.theme.spacing(4, 8)};
    border-radius: 4px;
    height: 28px;
    ${(props) => props.theme.typography['body-s']}

    &:hover {
      color: ${(props) => props.theme.palette.primary.dark};
      background-color: ${(props) => alpha(props.theme.palette.primary.dark, 0.33)};

      .MuiTableSortLabel-icon {
        opacity: 1;
        color: ${(props) => props.theme.palette.primary.dark};
      }
    }

    .MuiTableSortLabel-icon {
      transform: rotate(90deg);
      margin: ${(props) => props.theme.spacing(0, 3, 0, 15)};
      opacity: 1;
      width: 10px;
      height: 10px;
    }
  }
`;

export const Label = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 60px;
  }
`;

export const Filter = styled(Popover)`
  && {
    .MuiPaper-root {
      margin-top: ${(props) => props.theme.spacing(5)};
      background-color: ${(props) => props.theme.palette.primary.contrastText};
    }
  }
`;

export const RangePicker = styled(StaticDateRangePicker)`
  && {
    background-color: ${alpha('#6750A4', 0.011)};

    .MuiDateRangePickerToolbar-root {
      padding: ${(props) => props.theme.spacing(16, 20)};
      border-bottom: 2px solid #cac4d0;
    }
  }
`;

export const Selectable = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: column;
  height: 300px;
`;

export const SelectItem = styled(ButtonBase)`
  .MuiFormControlLabel-root {
    width: 100%;
    padding: ${(props) => props.theme.spacing(0, 24, 0, 16)};
  }
`;
