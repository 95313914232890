import { Avatar, Box, Grid, IconButton, List, ListItem, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import { IColumn, NewUserModal, Table, ToggleInput } from '../../../components';
import * as CardStyle from '../../../components/Common/Card/styles';
import { IAccount, IFilterType, Role } from '../../../interfaces';
import { CloseIcon, WarningIcon } from '../../../assets/icons';
import { API_SERVER, PAGE_LIMIT } from '../../../constants';
import { primary } from '../../../theme/colors';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserApi } from '../../../apis';
import { useSnackbar } from 'notistack';
import * as lodash from 'lodash';
import * as S from './styles';
import moment from 'moment';

export const UserPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [rowSelected, setSelectedRows] = useState<string[]>([]);
  const [users, setUsers] = useState<IAccount[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>();
  const [selectedUser, setSelectedUser] = useState<IAccount>();
  const [visibleNewModal, setVisibleNewModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filter, setFilter] = useState<any>();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fetchUsers = () => {
    setLoading(true);
    UserApi.readAll({
      query: {
        role: Role.User,
        ...filter
      },
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT
      }
    })
      .then((res) => {
        setUsers(res.users);
        setTotalPage(res.pagination.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: IColumn[] = [
    {
      field: 'check'
    },
    {
      title: t('user.user'),
      filterType: IFilterType.String,
      field: 'firstName',
      render: (row) => row.name
    },
    {
      title: t('user.email'),
      filterType: IFilterType.String,
      field: 'email',
      render: (row) => row.email
    },
    {
      title: t('user.galleries_owned'),
      filterType: IFilterType.Number,
      field: 'galleries',
      render: (row) => (row.galleries || []).length
    },
    {
      title: t('user.joining_date'),
      filterType: IFilterType.Date,
      field: 'createdAt',
      align: 'right',
      render: (row) => moment(row.createdAt).format('DD MMM YYYY')
    }
  ];

  const handlePageChange = (pageN: number) => {
    setPageNumber(pageN);
  };

  const handleCreate = () => {
    setVisibleNewModal(true);
  };

  const handleDeleteAll = () => {
    setLoading(true);
    UserApi.removeAll(rowSelected)
      .then((res) => {
        fetchUsers();
        enqueueSnackbar(res.msg, { variant: 'success' });
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err.msg, { variant: 'error' });
      });
  };

  const handleRowClick = (userId) => {
    setSelectedUser(users.find(({ id }) => id === userId));
  };

  const handleCheckUser = (isAllChecked: boolean, row?: any) => {
    if (row) {
      if (rowSelected.includes(row.id)) {
        setSelectedRows(rowSelected.filter((selected) => selected != row.id));
      } else {
        setSelectedRows([...rowSelected, row.id]);
      }
    } else {
      if (isAllChecked) {
        setSelectedRows(users.map(({ id }) => id));
      } else {
        setSelectedRows([]);
      }
    }
  };

  const handleClose = () => {
    setSelectedUser(undefined);
  };

  const handleCloseNewModal = () => {
    setVisibleNewModal(false);
    fetchUsers();
  };

  const handleVerify = (e) => {
    if (selectedUser) {
      UserApi.update(selectedUser.id, {
        verify: e.target.checked
      })
        .then((res) => {
          setSelectedUser({
            ...selectedUser,
            verify: !selectedUser.verify
          });
          fetchUsers();
          enqueueSnackbar(res.msg, { variant: 'success' });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.msg, { variant: 'error' });
        });
    }
  };

  const handleFilter = (newFilter) => {
    if (!lodash.isEqual(newFilter, filter)) {
      setFilter(newFilter);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [pageNumber, filter]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={7} sx={{ display: { xs: selectedUser ? 'none' : 'flex', lg: 'flex' } }}>
          <Table
            data={users}
            isLoading={loading}
            columns={columns}
            totalPage={totalPage}
            pageNumber={pageNumber}
            selectedRows={rowSelected}
            numSelected={rowSelected.length}
            contentHeight={{ xs: 'calc(100vh - 216px)', md: 'calc(100vh - 232px)', lg: 'calc(100vh - 296px)' }}
            onNew={handleCreate}
            newLabel={t('user.add_new_user')}
            onDeleteAll={handleDeleteAll}
            onPageChange={handlePageChange}
            onChangeChecked={handleCheckUser}
            onFilter={handleFilter}
            onRowClick={handleRowClick}
          />
        </Grid>
        <Grid item xs={12} lg={5} sx={{ display: { xs: selectedUser ? 'flex' : 'none', lg: 'flex' } }}>
          <S.Detail>
            <S.Toolbar>
              <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)} variant="fullWidth">
                <Tab label={t('user.details')} />
                <Tab label={t('user.galleries')} />
              </Tabs>
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </S.Toolbar>
            <Box sx={{ height: 'calc(100% - 48px)', borderTop: 1, borderColor: 'divider' }}>
              <Box sx={{ height: '100%', overflow: 'auto', maxHeight: '100%' }}>
                {selectedUser && selectedUser.id ? (
                  activeTab === 0 ? (
                    <Box px={16} pt={38}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider', py: '3em' }}>
                        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                          <Avatar sx={{ mb: '1em', width: '4em', height: '4em' }} src="" alt="logo" />
                          <Typography sx={{ fontSize: '1.5em', fontWeight: '600', color: primary[90] }}>
                            {selectedUser.name}
                          </Typography>
                        </Grid>
                      </Box>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <List>
                          <ListItem>
                            <ListItemText>
                              <Typography color="text.disabled">{t('user.user_id')}:</Typography>
                            </ListItemText>
                            <Typography sx={{ color: primary[90] }}>@{selectedUser.id}</Typography>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <Typography color="text.disabled">{t('user.email')}:</Typography>
                            </ListItemText>
                            <Typography sx={{ color: primary[90] }}>{selectedUser.email}</Typography>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <Typography color="text.disabled">{t('user.joining_date')}:</Typography>
                            </ListItemText>
                            <Typography sx={{ color: primary[90] }}>
                              {moment(selectedUser.createdAt).format('DD MMM YYYY')}
                            </Typography>
                          </ListItem>
                        </List>
                      </Box>
                      <Box>
                        <List>
                          <ListItem>
                            <ListItemText>
                              <Typography color="text.disabled">{t('user.galleries_owned')}:</Typography>
                            </ListItemText>
                            <Typography sx={{ color: primary[90] }}>{(selectedUser.gallery || []).length}</Typography>
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <Typography color="text.disabled">{t('user.total_orders')}:</Typography>
                            </ListItemText>
                            <Typography sx={{ color: primary[90] }}>{(selectedUser.cart || []).length}</Typography>
                          </ListItem>
                        </List>
                        <ToggleInput
                          trueLabel="true"
                          falseLabel="false"
                          label="Verified"
                          checked={selectedUser.verify}
                          onChange={handleVerify}
                        />
                      </Box>
                    </Box>
                  ) : activeTab === 1 ? (
                    <Grid container gap={6}>
                      {(selectedUser?.gallery || []).map(({ name, logo }, index) => (
                        <Grid key={index} item xs={12}>
                          <CardStyle.Card>
                            <CardStyle.Thumbnail
                              src={
                                logo?.fieldname
                                  ? `${API_SERVER}/${logo?.filename}`
                                  : logo && URL.createObjectURL(logo as unknown as Blob).toString()
                              }
                              alt=""
                            />
                            <CardStyle.Header>
                              <Typography title={name} variant="title-s">
                                {name}
                              </Typography>
                            </CardStyle.Header>
                          </CardStyle.Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    ''
                  )
                ) : (
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: '100%' }}
                  >
                    <WarningIcon />
                    <Typography mt={19} color="primary.main">
                      {t('user.select_user')}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Box>
          </S.Detail>
        </Grid>
      </Grid>
      <NewUserModal open={visibleNewModal} onClose={handleCloseNewModal} />
    </>
  );
};
