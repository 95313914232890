import { Autocomplete, Box, Button, Chip, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from 'react';
import { SearchPeople, UploadNotification } from '../../../../components';
import { IAccount, IGallery, IUploadFile } from '../../../../interfaces';
import { ArrowLeftIcon, PlusIcon } from '../../../../assets/icons';
import { API_SERVER, ROUTES } from '../../../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { GalleryApi, PhotoApi } from '../../../../apis';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import * as S from './styles';
import * as Yup from 'yup';

export const GalleryFormPage: FC = () => {
  const [gallery, setGallery] = useState<IGallery | null>(null);
  const [uploadImages, setUploadImages] = useState<IUploadFile[]>([]);
  const [openUploadNotification, setOpenUploadNotification] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    description: Yup.string().required(t('validation.required')),
    tags: Yup.array().max(8, 'You can add up to 8 tags')
  });

  const fields = [
    {
      name: 'name',
      label: t('gallery.gallery_name')
    },
    {
      name: 'description',
      label: t('gallery.description'),
      multiline: true
    }
  ];

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    validationSchema,
    initialValues: {
      name: '',
      description: '',
      tags: [],
      shareWith: [],
      photos: [],
      logo: ''
    },
    onSubmit: (values) => {
      values = {
        ...values,
        photos: values.photos.map(({ id }) => id),
        shareWith: values.shareWith.map(({ id }) => id)
      };

      const newGallery = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (gallery === null || (gallery && !isEqual(gallery[key], value))) {
          if (key === 'photos' || key === 'shareWith' || key === 'tags') {
            // @ts-ignore
            value.forEach((item) => {
              newGallery.append(key, item);
            });
          } else {
            // @ts-ignore
            newGallery.append(key, value);
          }
        }
      });

      if (id) {
        GalleryApi.update(id, newGallery as unknown as IGallery)
          .then(() => {
            navigate(ROUTES.MAIN.GALLERY.LIST);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        GalleryApi.create(newGallery as unknown as IGallery)
          .then(() => {
            navigate(ROUTES.MAIN.GALLERY.LIST);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    }
  });

  const handleBack = () => {
    navigate(ROUTES.MAIN.GALLERY.LIST);
  };

  const handleTagsChange = (event: SyntheticEvent, value: string[]) => {
    setFieldValue('tags', value);
  };

  const handlePeopleChange = (value: IAccount[]) => {
    setFieldValue('shareWith', value);
  };

  const handleDrop = (files) => {
    setFieldValue('logo', files[0]);
  };

  const handleUploadPhoto = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      let files = Object.values(e.target.files).map((file) => ({
        file,
        fileId: `${Date.now()}-${file.name}`,
        uploadPercent: 0
      }));
      const photos: any[] = [...values.photos];

      const onUpload = (progressEvent: ProgressEvent, fileId: string) => {
        files = files.map((image) => {
          if (image.fileId === fileId) {
            return {
              ...image,
              uploadPercent: Math.round((progressEvent.loaded * 100) / progressEvent.total)
            };
          } else {
            return image;
          }
        });
        setUploadImages(files);
      };

      setOpenUploadNotification(true);
      for (let i = 0; i < files.length; i++) {
        const photo = new FormData();
        photo.append('photo', files[i].file);
        if (id) {
          photo.append('galleryId', id);
        }
        PhotoApi.create(photo, (e) => onUpload(e, files[i].fileId))
          .then((res) => {
            photos.push(res.photo);
            setFieldValue('photos', photos);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleCreate = () => {
    handleSubmit();
  };

  const handleCloseUploadNotification = () => {
    setOpenUploadNotification(false);
  };

  useEffect(() => {
    if (id) {
      GalleryApi.read(id)
        .then((res) => {
          const { name, logo, photos, shareWith, tags, description } = res.gallery;
          setFieldValue('name', name);
          setFieldValue('logo', logo);
          setFieldValue('photos', photos);
          setFieldValue('shareWith', shareWith);
          setFieldValue('tags', tags);
          setFieldValue('description', description);
          setGallery(res.gallery);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setGallery(null);
    }
  }, [id]);

  return (
    <Container>
      <S.Toolbar>
        <Button variant="text" startIcon={<ArrowLeftIcon />} onClick={handleBack}>
          {t('gallery.back')}
        </Button>
        <Button onClick={handleCreate}>{id ? t('gallery.edit_this_gallery') : t('gallery.create_this_gallery')}</Button>
      </S.Toolbar>
      <S.Content>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6}>
            {fields.map(({ name, label, multiline }, index) => (
              <TextField
                fullWidth
                key={index}
                name={name}
                label={label}
                disabled={isSubmitting}
                variant="outlined"
                multiline={multiline}
                value={values[name]}
                minRows={4}
                error={Boolean(errors[name] && touched[name])}
                helperText={Boolean(errors[name] && touched[name]) && errors[name]}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mb: 20 }}
              />
            ))}
            {/*@ts-ignore*/}
            <Autocomplete
              multiple
              freeSolo
              value={values.tags}
              // @ts-ignore
              onChange={handleTagsChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // @ts-ignore
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={t('gallery.tags')}
                    variant="outlined"
                    disabled={isSubmitting}
                    error={Boolean(errors.tags && touched.tags)}
                    helperText={Boolean(errors.tags && touched.tags) && errors.tags}
                  />
                );
              }}
              options={[]}
            />
            <Divider textAlign="left" sx={{ mt: 20, mb: 12 }}>
              {t('gallery.recently_used')}
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchPeople disabled={isSubmitting} value={values.shareWith} onChange={handlePeopleChange} />
          </Grid>
        </Grid>
        <S.SubToolbar>
          <Typography color="primary.dark">{t('gallery.photos_selected', { count: values.photos.length })}</Typography>
          <label htmlFor="contained-button-file">
            <input accept="image/*" id="contained-button-file" multiple type="file" onChange={handleUploadPhoto} />
            <Button variant="text" component="span" startIcon={<PlusIcon />}>
              {t('gallery.add_new_photo')}
            </Button>
          </label>
        </S.SubToolbar>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <S.GalleryLogo>
                  {/* @ts-ignore */}
                  {typeof values.logo === 'object' && (
                    <img
                      src={
                        // @ts-ignore
                        values.logo.fieldname
                          ? // @ts-ignore
                            `${API_SERVER}/${values.logo.filename}`
                          : URL.createObjectURL(values.logo).toString()
                      }
                      alt=""
                    />
                  )}
                  <div {...getRootProps()}>
                    <input {...getInputProps()} multiple={false} />
                  </div>
                </S.GalleryLogo>
              )}
            </Dropzone>
            <Typography mt={4} ml={16} color="primary">
              {t('gallery.gallery_cover')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={9}>
            <Box>
              <Grid container spacing={16}>
                {values.photos.map((file, index) => (
                  <Grid item xs={4} sm={6} md={3} lg={2} key={index}>
                    <S.Photo
                      // @ts-ignore
                      src={file?.fieldname ? `${API_SERVER}/${file?.filename}` : URL.createObjectURL(file).toString()}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </S.Content>
      {openUploadNotification && <UploadNotification files={uploadImages} onClose={handleCloseUploadNotification} />}
    </Container>
  );
};
