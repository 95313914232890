import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from '@mui/material';
import { CloseIcon, ShoppingBagIcon, WarningIcon } from '../../../assets/icons';
import { IColumn, Table, ToggleButton } from '../../../components';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { IFilterType, OrderStatus } from '../../../interfaces';
import { API_SERVER, PAGE_LIMIT } from '../../../constants';
import { primary } from '../../../theme/colors';
import { useTranslation } from 'react-i18next';
import { OrderApi } from '../../../apis';
import moment from 'moment/moment';
import * as S from './styles';
import _ from 'lodash';

export const OrderPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [rowSelected, setSelectedRows] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const { t } = useTranslation();

  const fetchOrders = () => {
    setLoading(true);
    OrderApi.readAll({
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT
      }
    })
      .then((res) => {
        setOrders(res.orders);
        setTotalPage(res.pagination.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const proceedOrder = (id) => {
    setLoading(true);
    OrderApi.proceed(id)
      .then(() => {
        if (selectedOrder?._id === id)
          setSelectedOrder({
            ...selectedOrder,
            status: OrderStatus.Completed
          });
        fetchOrders();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handlePageChange = (pageN: number) => {
    setPageNumber(pageN);
  };

  const handleCreate = () => {
    console.log('create');
  };

  const handleDeleteAll = () => {
    console.log('delete all');
  };

  const handleRowClick = (orderId) => {
    setSelectedOrder(orders.find(({ id }) => id === orderId));
  };

  const handleCheckOrder = (isAllChecked: boolean, row?: any) => {
    if (row) {
      if (rowSelected.includes(row.id)) {
        setSelectedRows(rowSelected.filter((selected) => selected != row.id));
      } else {
        setSelectedRows([...rowSelected, row.id]);
      }
    } else {
      if (isAllChecked) {
        setSelectedRows(orders.map(({ id }) => id));
      } else {
        setSelectedRows([]);
      }
    }
  };

  const handleClose = () => {
    setSelectedOrder(undefined);
  };

  const columns: IColumn[] = [
    {
      field: 'check',
      title: '',
      align: 'center'
    },
    {
      title: t('order.order_number'),
      field: '_id',
    },
    {
      title: t('order.customer_name'),
      filterType: IFilterType.String,
      field: 'customer',
      render: (row) => row.customer?.name
    },
    {
      title: t('order.order_date'),
      filterType: IFilterType.Date,
      field: 'createdAt',
      render: (row) => moment(row.createdAt).format('DD MMM YYYY')
    },
    {
      title: t('order.order_status'),
      field: 'status',
      align: 'right',
      render: ({ status }) => (
        <ToggleButton
          value={status}
          data={[
            {
              label: t('order.pending'),
              color: '#CECECE',
              value: OrderStatus.Pending
            },
            {
              label: t('order.purchased'),
              color: '#FBBD2D',
              value: OrderStatus.Purchased
            },
            {
              label: t('order.cancelled'),
              color: '#FC5454',
              value: OrderStatus.Cancelled
            },
            {
              label: t('order.completed'),
              color: '#02C58D',
              value: OrderStatus.Completed
            }
          ]}
        />
      )
    }
  ];

  useEffect(() => {
    fetchOrders();
  }, [pageNumber]);

  return (
    <Grid container>
      <Grid item xs={12} lg={7} sx={{ display: { xs: selectedOrder ? 'none' : 'flex', lg: 'flex' } }}>
        <Table
          data={orders}
          isLoading={loading}
          columns={columns}
          totalPage={totalPage}
          pageNumber={pageNumber}
          selectedRows={rowSelected}
          numSelected={rowSelected.length}
          contentHeight="calc(100vh - 130px)"
          onNew={handleCreate}
          newLabel={t('order.add_new_order')}
          onDeleteAll={handleDeleteAll}
          onPageChange={handlePageChange}
          onChangeChecked={handleCheckOrder}
          onRowClick={handleRowClick}
        />
      </Grid>
      <Grid item xs={12} lg={5} sx={{ display: { xs: selectedOrder ? 'flex' : 'none', lg: 'flex' } }}>
        <S.Detail>
          <S.Toolbar>
            <Tabs variant="fullWidth" value={currentTab} onChange={handleTabChange}>
              <Tab label={t('order.details')} />
              <Tab label={t('order.manage')} />
            </Tabs>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </S.Toolbar>
          {currentTab === 0 && selectedOrder ? (
            <S.OrderWrapper>
              <Stack color="text.secondary" width="100%" px={20} alignItems="center">
                <ShoppingBagIcon />
                <div>{t('order.order_number')}</div>
                <S.TextInfo size="24px">{selectedOrder?._id}</S.TextInfo>
                {[t('order.pending'), t('order.purchased')].includes(selectedOrder?.status) && (
                  <Button onClick={() => proceedOrder(selectedOrder?._id)}>Proceed</Button>
                )}
                <Stack width="100%" pt={20}>
                  <Divider />
                  <Stack pt={20} direction="row" alignContent="center" justifyContent="space-between">
                    <div>{t('order.order_status')}:</div>
                    <ToggleButton
                      value={selectedOrder?.status}
                      data={[
                        {
                          label: t('order.pending'),
                          color: '#CECECE',
                          value: OrderStatus.Pending
                        },
                        {
                          label: t('order.purchased'),
                          color: '#FBBD2D',
                          value: OrderStatus.Purchased
                        },
                        {
                          label: t('order.cancelled'),
                          color: '#FC5454',
                          value: OrderStatus.Cancelled
                        },
                        {
                          label: t('order.completed'),
                          color: '#02C58D',
                          value: OrderStatus.Completed
                        }
                      ]}
                    />
                  </Stack>
                  <Stack py={20} direction="row" alignContent="center" justifyContent="space-between">
                    <div>{t('order.order_date')}:</div>
                    <S.TextInfo>{moment(selectedOrder?.createdAt).format('DD MMM YYYY')}</S.TextInfo>
                  </Stack>
                  <Divider />
                  <Stack pt={20} direction="row" alignContent="center" justifyContent="space-between">
                    <div>{t('order.customer_name')}:</div>
                    <S.TextInfo>{selectedOrder?.customer?.name}</S.TextInfo>
                  </Stack>
                  <Stack py={20} direction="row" alignContent="center" justifyContent="space-between">
                    <div>{'order.customer_email'}:</div>
                    <S.TextInfo>{selectedOrder?.customer?.email}</S.TextInfo>
                  </Stack>
                  <Stack py={20} direction="row" alignContent="center" justifyContent="space-between">
                    <div>{t('order.customer_phone')}:</div>
                    <S.TextInfo>{selectedOrder?.customer?.mobileNumber}</S.TextInfo>
                  </Stack>
                  <Stack py={20} direction="row" alignContent="center" justifyContent="space-between">
                    <div>{t('order.customer_address')}:</div>
                    <S.TextInfo>{selectedOrder?.customer?.address}</S.TextInfo>
                  </Stack>
                </Stack>
              </Stack>
              <Box>
                <TableContainer>
                  <MuiTable width="100%">
                    <TableHead style={{ backgroundColor: primary[90] }}>
                      <TableRow>
                        <TableCell>
                          <Box color={primary[0]}>{t('order.order')}</Box>
                        </TableCell>
                        <TableCell>
                          <Box color={primary[0]}>{t('order.price')}</Box>
                        </TableCell>
                        <TableCell>
                          <Box color={primary[0]}>{t('order.quantity')}</Box>
                        </TableCell>
                        <TableCell>
                          <Box color={primary[0]}>{t('order.subtotal')}</Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: 'scroll' }}>
                      {_.map(_.get(selectedOrder, 'items') || [], (row) => (
                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            <Stack spacing={5} direction="row" alignItems="center">
                              {row.print?.photo?.filename ? (
                                <Avatar variant="square" src={`${API_SERVER}/${row.print?.photo?.filename}`} />
                              ) : (
                                ''
                              )}
                              <S.TextInfo>{row.print?.name}</S.TextInfo>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Box color="text.secondary">
                              {row.unit}
                              {row.price}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box color="text.secondary">{row.quantity}</Box>
                          </TableCell>
                          <TableCell>
                            <Box color="text.secondary">
                              {row.unit}
                              {row.total}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </MuiTable>
                </TableContainer>
              </Box>
            </S.OrderWrapper>
          ) : (
            <S.OrderWrapper alignItems="center" justifyContent="center" width="100%" color="text.secondary">
              <WarningIcon />
              <Typography mt={19} variant="body-s" textAlign="center">
                {t('order.select_item')}
              </Typography>
            </S.OrderWrapper>
          )}
          {currentTab === 1 && <></>}
        </S.Detail>
      </Grid>
    </Grid>
  );
};
