import { IGetParams, IPaper } from '../interfaces';
import { ENDPOINTS } from '../constants';
import { Http } from '../utils';

const create = (paper: IPaper): Promise<any> => {
  return Http.post(ENDPOINTS.PAPER.BASE, paper);
};

const read = (id: string): Promise<any> => {
  return Http.get(ENDPOINTS.PAPER.ID.replace(':id', id));
};

const readAll = (params?: IGetParams): Promise<any> => {
  return Http.get(ENDPOINTS.PAPER.BASE, params);
};

const update = (id: string, paper: Partial<IPaper>): Promise<any> => {
  return Http.patch(ENDPOINTS.PAPER.ID.replace(':id', id), paper);
};

const remove = (id: string): Promise<any> => {
  return Http.delete(ENDPOINTS.PAPER.ID.replace(':id', id));
};

const removeAll = (ids: string[]): Promise<any> => {
  return Http.delete(ENDPOINTS.PAPER.BASE, { ids });
};

export const PaperApi = {
  create,
  read,
  readAll,
  update,
  remove,
  removeAll
};
