import { Box, Button, Checkbox, CircularProgress, Container, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import { AscOrderIcon, DeleteIcon, PlusIcon } from '../../../../assets/icons';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Card, UploadNotification } from '../../../../components';
import { IGallery, IUploadFile } from '../../../../interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import { GalleryApi, PhotoApi } from '../../../../apis';
import { ROUTES } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

export const GalleryDetailPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [gallery, setGallery] = useState<IGallery>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [uploadImages, setUploadImages] = useState<IUploadFile[]>([]);
  const [openUploadNotification, setOpenUploadNotification] = useState(false);

  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSelectedAll = useMemo(() => {
    return gallery?.photos && gallery?.photos.length > 0 && gallery?.photos.length === selectedIds.length;
  }, [selectedIds, gallery]);

  const isIndeterminate = useMemo(() => {
    return gallery && gallery?.photos.length > selectedIds.length && selectedIds.length > 0;
  }, [selectedIds, gallery]);

  const fetchGallery = () => {
    if (id) {
      setLoading(true);
      GalleryApi.read(id)
        .then((res) => {
          setGallery(res.gallery);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handlePrint = (id) => () => {
    navigate(ROUTES.MAIN.PRINT.NEW_FROM_PHOTO.replace(':photoId', id));
  };

  const handleEdit = () => {
    console.log('handler');
  };

  const handleDelete = (id) => () => {
    setLoading(true);
    PhotoApi.remove(id)
      .then(() => {
        setSelectedIds([]);
        fetchGallery();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDeleteAll = () => {
    if (selectedIds.length > 0) {
      setLoading(true);
      PhotoApi.removeAll(selectedIds, id as string)
        .then(() => {
          setSelectedIds([]);
          fetchGallery();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleSelect = (id) => () => {
    if (selectedIds.indexOf(id) > -1) {
      setSelectedIds(selectedIds.filter((selected) => id !== selected));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedIds.length === gallery?.photos.length) {
      setSelectedIds([]);
    } else {
      let allPhotos: string[] = [];
      if (gallery && gallery.photos) {
        allPhotos = [...gallery.photos.map(({ _id }) => _id)];
      }
      setSelectedIds([...allPhotos]);
    }
  };

  const handleUploadPhoto = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      let files = Object.values(e.target.files).map((file) => ({
        file,
        fileId: `${Date.now()}-${file.name}`,
        uploadPercent: 0
      }));

      const onUpload = (progressEvent: ProgressEvent, fileId: string) => {
        files = files.map((image) => {
          if (image.fileId === fileId) {
            return {
              ...image,
              uploadPercent: Math.round((progressEvent.loaded * 100) / progressEvent.total)
            };
          } else {
            return image;
          }
        });
        setUploadImages(files);
      };

      setOpenUploadNotification(true);
      for (let i = 0; i < files.length; i++) {
        const photo = new FormData();
        photo.append('photo', files[i].file);
        photo.append('galleryId', id as string);
        PhotoApi.create(photo, (e) => onUpload(e, files[i].fileId))
          .then(() => {
            fetchGallery();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleCloseUploadNotification = () => {
    setOpenUploadNotification(false);
  };

  useEffect(() => {
    fetchGallery();
  }, [id]);

  return (
    <Container>
      <S.Toolbar>
        <S.Actions direction="row" spacing={8}>
          <Box>
            <Tooltip title={t(isSelectedAll ? 'common.deselect_all' : 'common.select_all')}>
              <Checkbox
                disabled={loading}
                indeterminate={isIndeterminate}
                checked={isSelectedAll}
                onChange={handleSelectAll}
              />
            </Tooltip>
            <Tooltip title={t('common.delete')}>
              <IconButton disabled={loading || selectedIds.length === 0} onClick={handleDeleteAll}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Tooltip title={t('common.sort')}>
            <IconButton disabled={loading || gallery?.photos.length === 0}>
              <AscOrderIcon />
            </IconButton>
          </Tooltip>
        </S.Actions>
        <label htmlFor="contained-button-file">
          <input accept="image/*" id="contained-button-file" multiple type="file" onChange={handleUploadPhoto} />
          <Button variant="text" disabled={loading} component="span" startIcon={<PlusIcon />}>
            {t('gallery.add_new_photo')}
          </Button>
        </label>
      </S.Toolbar>
      <S.Content>
        {loading ? (
          <Stack width="1" height={300} alignItems="center" justifyContent="center">
            <CircularProgress color="secondary" />
          </Stack>
        ) : (
          <Grid container spacing={16}>
            {gallery &&
              gallery.photos.map((photo, index) => (
                <Grid key={index} item xs={12} md={6} lg={4} xl={3}>
                  <Card
                    selected={selectedIds.indexOf(photo._id) > -1}
                    title={photo.name}
                    thumbnail={photo}
                    onPrint={handlePrint(photo._id)}
                    onEdit={handleEdit}
                    onDelete={handleDelete(photo._id)}
                    onSelect={handleSelect(photo._id)}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </S.Content>
      {openUploadNotification && <UploadNotification files={uploadImages} onClose={handleCloseUploadNotification} />}
    </Container>
  );
};
