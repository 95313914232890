import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, ButtonBase } from '@mui/material';
import styled from 'styled-components';

export const TabPanel = styled(PerfectScrollbar)`
  height: calc(100vh - 129px);
  padding: ${(props) => props.theme.spacing(40, 20)};

  ${(props) => props.theme.breakpoints.down('md')} {
    height: calc(100vh - 111px);
  }
`;

export const Detail = styled(Box)`
  width: 100%;
  border-left: 1px solid ${(props) => props.theme.palette.primary.main};

  ${(props) => props.theme.breakpoints.down('lg')} {
    border-left: none;
  }
`;

export const Toolbar = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};

  ${(props) => props.theme.breakpoints.down('lg')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MuiIconButton-root {
    margin-right: ${(props) => props.theme.spacing(10)};
    display: none;

    ${(props) => props.theme.breakpoints.down('lg')} {
      display: flex;
    }
  }
`;

export const ToggleButton = styled(ButtonBase)`
  border-radius: 8px;
  color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(6, 16)};
  ${(props) => props.theme.typography['label-l']};
`;
