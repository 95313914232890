import { ENDPOINTS } from '../constants';
import { IGetParams, ISetting } from '../interfaces';
import { Http } from '../utils';

const readAll = (params?: IGetParams): Promise<any> => {
  return Http.get(ENDPOINTS.SETTING.BASE, params);
};

const update = (id: string, setting: ISetting): Promise<any> => {
  return Http.patch(ENDPOINTS.SETTING.ID.replace(':id', id), setting);
};

export const SettingApi = {
  readAll,
  update
};
