import { AboutUsPage, AdminPages as Admin, AuthPages as Auth, MainPages as Main } from '../pages';
import { FC } from 'react';

export interface IRoute {
  path: string;
  element: FC;
}

export const ROUTES = {
  AUTH: {
    SIGN_IN: '/auth/sign-in',
    SIGN_UP: '/auth/sign-up',
    CONFIRM_EMAIL: '/auth/verify-email',
    FORGOT_PASSWORD: '/auth/forgot-password',
    CHANGE_PASSWORD: '/auth/change-password'
  },
  HOME: '/',
  MAIN: {
    GALLERY: {
      LIST: '/',
      NEW: '/galleries/create',
      DETAIL: '/galleries/:id',
      EDIT: '/galleries/:id/edit'
    },
    PRINT: {
      LIST: '/prints',
      NEW: '/prints/create',
      NEW_FROM_PHOTO: '/prints/:photoId/create'
    },
    SHOP: {
      CART: '/shop/cart',
      CHECK_OUT: '/shop/check-out'
    }
  },
  ADMIN: {
    GALLERY: '/galleries',
    ORDER: '/orders',
    PRINT: '/prints',
    USER: '/users',
    SETTING: '/setting'
  },
  ABOUT_US: '/about-us'
};

export const AUTH_ROUTES: IRoute[] = [
  {
    path: ROUTES.AUTH.SIGN_IN,
    element: Auth.SignInPage
  },
  {
    path: ROUTES.AUTH.SIGN_UP,
    element: Auth.SignUpPage
  },
  {
    path: ROUTES.AUTH.CONFIRM_EMAIL,
    element: Auth.VerifyEmailPage
  },
  {
    path: ROUTES.AUTH.FORGOT_PASSWORD,
    element: Auth.ForgotPasswordPage
  },
  {
    path: ROUTES.AUTH.CHANGE_PASSWORD,
    element: Auth.ChangePasswordPage
  }
];

export const USER_ROUTES: IRoute[] = [
  {
    path: ROUTES.MAIN.GALLERY.LIST,
    element: Main.GalleryListPage
  },
  {
    path: ROUTES.MAIN.GALLERY.NEW,
    element: Main.GalleryFormPage
  },
  {
    path: ROUTES.MAIN.GALLERY.EDIT,
    element: Main.GalleryFormPage
  },
  {
    path: ROUTES.MAIN.GALLERY.DETAIL,
    element: Main.GalleryDetailPage
  },
  {
    path: ROUTES.MAIN.PRINT.LIST,
    element: Main.PrintListPage
  },
  {
    path: ROUTES.MAIN.PRINT.NEW,
    element: Main.NewPrintPage
  },
  {
    path: ROUTES.MAIN.PRINT.NEW_FROM_PHOTO,
    element: Main.NewPrintPage
  },
  {
    path: ROUTES.MAIN.SHOP.CART,
    element: Main.CartPage
  },
  {
    path: ROUTES.MAIN.SHOP.CHECK_OUT,
    element: Main.CheckOutPage
  },
  {
    path: ROUTES.ABOUT_US,
    element: AboutUsPage
  }
];

export const ADMIN_ROUTES: IRoute[] = [
  {
    path: ROUTES.HOME,
    element: Admin.DashboardPage
  },
  {
    path: ROUTES.ADMIN.GALLERY,
    element: Admin.GalleryPage
  },
  {
    path: ROUTES.ADMIN.ORDER,
    element: Admin.OrderPage
  },
  {
    path: ROUTES.ADMIN.PRINT,
    element: Admin.PrintPage
  },
  {
    path: ROUTES.ADMIN.USER,
    element: Admin.UserPage
  },
  {
    path: ROUTES.ADMIN.SETTING,
    element: Admin.SettingsPage
  }
];
