import { alpha, Box, ButtonBase, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Sidebar = styled(Box)`
  background-color: #eceaff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  border-right: 1.5px solid ${(props) => props.theme.palette.primary.main};
  padding: ${(props) => props.theme.spacing(12, 12, 0, 0)};

  ${(props) => props.theme.breakpoints.down('xl')} {
    width: 80px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 60px;
    padding: ${(props) => props.theme.spacing(12, 8, 0, 0)};
  }
`;

export const NavIcon = styled(Box)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.spacing(12)};
`;

export const NavLabel = styled(Typography)`
  ${(props) => props.theme.breakpoints.down('xl')} {
    display: none;
  }
`;

export const NavItem = styled(ButtonBase)<{ isActive: boolean }>`
  justify-content: flex-start;
  padding: ${(props) => props.theme.spacing(16, 24, 16, 28)};
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${(props) => props.theme.spacing(16, 12, 16, 20)};
  }

  &:hover {
    background-color: ${(props) => alpha(props.theme.palette.primary.dark, 0.11)};
    color: ${(props) => props.theme.palette.primary.dark};

    ${NavIcon} {
      svg {
        transform: scale(1.1);
      }
    }
  }

  &:active {
    color: ${(props) => props.theme.palette.primary.dark};
    background-color: ${(props) => alpha(props.theme.palette.primary.dark, 0.33)};
  }

  ${(props) =>
    props.isActive &&
    css`
      color: ${(props) => props.theme.palette.primary.dark} !important;
      background-color: ${(props) => alpha(props.theme.palette.primary.dark, 0.33)} !important;
    `}
`;
