import { Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import { EmailIcon, LockIcon } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../constants';
import { AuthApi } from '../../../apis';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as S from './styles';
import { FC } from 'react';
import * as Yup from 'yup';

export const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('validation.invalid_email')).required(t('validation.required'))
  });

  const fields = [
    {
      name: 'email',
      icon: <EmailIcon />,
      label: t('forgot_password.enter_email')
    }
  ];

  const { values, errors, touched, isSubmitting, setSubmitting, handleChange, handleBlur, handleSubmit } = useFormik<{
    email: string;
  }>({
    validationSchema,
    initialValues: {
      email: ''
    },
    onSubmit: (values) => {
      AuthApi.forgotPassword(values)
        .then((res) => {
          enqueueSnackbar(res.msg, { variant: 'success' });
        })
        .catch((err) => {
          enqueueSnackbar(err.msg, { variant: 'error' });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const handleSend = () => {
    handleSubmit();
  };

  const handleCancel = () => {
    navigate(ROUTES.AUTH.SIGN_IN);
  };

  return (
    <S.Container>
      <S.Image>
        <LockIcon />
      </S.Image>
      <Typography mb={24} variant="display-s" color="secondary" align="center">
        {t('forgot_password.title')}
      </Typography>
      <Typography mb={34} color="primary" variant="body-s" align="center">
        {t('forgot_password.description')}
      </Typography>
      <S.Form>
        {fields.map(({ name, icon, label }, index) => (
          <TextField
            fullWidth
            key={index}
            name={name}
            placeholder={label}
            value={values[name]}
            disabled={isSubmitting}
            error={Boolean(errors[name] && touched[name])}
            helperText={Boolean(errors[name] && touched[name]) && errors[name]}
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{ mb: 32 }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
            }}
          />
        ))}
        <Button sx={{ mb: 16 }} disabled={isSubmitting} onClick={handleSend}>
          {isSubmitting ? <CircularProgress size={28} color="warning" /> : t('forgot_password.send')}
        </Button>
        <Button variant="text" onClick={handleCancel}>
          {t('forgot_password.cancel')}
        </Button>
      </S.Form>
    </S.Container>
  );
};
