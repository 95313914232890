import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

export const Layout = styled(PerfectScrollbar)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing(120, 20, 105)};

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${(props) => props.theme.spacing(60, 20, 50)};
  }
`;
