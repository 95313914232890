import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { AddCartIcon, CheckIcon, CloseIcon } from '../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from '../../../store/selectors';
import { setAccount } from '../../../store/actions';
import { PrintApi, ShopApi } from '../../../apis';
import { API_SERVER } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { FC, useMemo, useState } from 'react';
import { IPrint } from '../../../interfaces';
import * as S from './styles';
import moment from 'moment';

interface IPrintDetailModalProps {
  open: boolean;
  print: IPrint;
  onClose: () => void;
  onDeleted: () => void;
}

export const PrintDetailModal: FC<IPrintDetailModalProps> = ({ open, print, onClose, onDeleted }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const account = useSelector(getAccount);

  const isInCart = useMemo(() => {
    return Boolean(account.cart.find(({ _id }) => _id === print._id));
  }, [print, account]);

  const handleDelete = () => {
    setDeleteLoading(true);
    PrintApi.remove(print._id)
      .then(() => {
        onClose();
        onDeleted();
      })
      .catch((err) => {
        console.log(err);
        setDeleteLoading(false);
      });
  };

  const handleToggleToCart = () => {
    setLoading(true);
    ShopApi.toggleToCart([print._id])
      .then((res) => {
        dispatch(setAccount(res.user));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <S.Modal fullWidth open={open} onClose={onClose}>
      <S.Header>
        <Typography color="primary.dark" variant="title-m">
          {t('print.print_details')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </S.Header>
      <S.Content>
        <S.Print>
          <img src={`${API_SERVER}/${print.photo?.filename}`} alt="" />
        </S.Print>
        <Stack mt={17}>
          <Typography variant="body-l" color="primary.dark">
            {print.name}
          </Typography>
          <Typography variant="body-s" color="primary.dark">
            £{print.paper.price.toFixed(2)}
          </Typography>
          <Typography variant="body-s" color="primary.dark">
            {print.paper.width.value} {print.paper.width.unit} × {print.paper.height.value} {print.paper.height.unit}
          </Typography>
          <Typography variant="body-s">{moment(print.updatedAt).format('DD MMM YYYY')}</Typography>
        </Stack>
      </S.Content>
      <S.Actions direction="row" spacing={8}>
        <Button variant="text" disabled={deleteLoading || loading} onClick={handleDelete}>
          {deleteLoading ? <CircularProgress size={18} /> : t('print.remove')}
        </Button>
        <Button
          disabled={loading || deleteLoading}
          startIcon={loading ? <CircularProgress size={18} /> : isInCart ? <CheckIcon /> : <AddCartIcon />}
          onClick={handleToggleToCart}
        >
          {isInCart ? t('print.added_to_cart') : t('print.add_to_cart')}
        </Button>
      </S.Actions>
    </S.Modal>
  );
};
