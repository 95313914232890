import { alpha, Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const OrderList = styled(Box)`
  width: 100%;

  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableCell-root {
        padding: ${(props) => props.theme.spacing(8, 16)};
        ${(props) => props.theme.typography['title-s']};
        color: ${(props) => props.theme.palette.primary.dark};
        border-color: ${(props) => props.theme.palette.text.secondary};
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:hover {
          background: ${(props) => alpha(props.theme.palette.primary.dark, 0.11)};
        }

        &:last-of-type {
          .MuiTableCell-root {
            border-bottom: none;
          }
        }

        &.row-disabled {
          .MuiTableCell-root:not(:last-of-type) {
            opacity: 0.33;
          }
        }

        .MuiTableCell-root {
          ${(props) => props.theme.typography['body-s']};
          color: ${(props) => props.theme.palette.primary.main};
          padding: ${(props) => props.theme.spacing(8, 16)};
          border-color: ${(props) => props.theme.palette.text.secondary};

          img {
            width: 56px;
            height: 56px;
          }
        }
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableCell-root {
          padding: ${(props) => props.theme.spacing(8, 4)};
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            padding: ${(props) => props.theme.spacing(8, 4)};

            img {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }
  }
`;

export const Summary = styled(Box)`
  max-width: 536px;
  min-width: 536px;
  margin-top: ${(props) => props.theme.spacing(13)};
  margin-left: ${(props) => props.theme.spacing(292)};
  border: 1px solid ${(props) => props.theme.palette.primary.dark};
  border-radius: 4px;
  padding: ${(props) => props.theme.spacing(45, 16, 20)};
  position: relative;

  .MuiDivider-root {
    border-color: ${(props) => props.theme.palette.primary.dark};
  }

  ${(props) => props.theme.breakpoints.down('xl')} {
    max-width: 420px;
    min-width: 420px;
    margin-left: ${(props) => props.theme.spacing(120)};
  }

  ${(props) => props.theme.breakpoints.down('lg')} {
    margin-top: ${(props) => props.theme.spacing(60)};
    margin-left: auto;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: ${(props) => props.theme.spacing(30)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const Label = styled(Typography)`
  position: absolute;
  padding: ${(props) => props.theme.spacing(1, 15, 0, 4)};
  background: ${(props) => props.theme.palette.common.white};
  top: -13px;
  left: 12px;
  display: block;
  width: fit-content;
`;
