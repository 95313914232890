import { Avatar, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { IFilterType, IGallery } from '../../../interfaces';
import { AccessModal, IColumn, Table } from '../../../components';
import { CloseIcon, UserIcon } from '../../../assets/icons';
import { API_SERVER, PAGE_LIMIT } from '../../../constants';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GalleryApi } from '../../../apis';
import * as lodash from 'lodash';
import * as S from './styles';
import moment from 'moment';

export const GalleryPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const [galleries, setGalleries] = useState<IGallery[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedGallery, setSelectedGallery] = useState<IGallery | null>(null);
  const [visiblePrints, setVisiblePrints] = useState<boolean>(false);
  const [visibleAccess, setVisibleAccess] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>();

  const { t } = useTranslation();

  const fetchGallery = (id) => {
    GalleryApi.read(id)
      .then((res) => {
        setSelectedGallery(res.gallery);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchGalleries = () => {
    setLoading(true);
    GalleryApi.readAll({
      query: {
        ...filter
      },
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT
      }
    })
      .then((res) => {
        setGalleries(res.galleries);
        setTotalPage(res.pagination.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const accessWith = useMemo(() => {
    return selectedGallery?.shareWith.filter(({ email }) => email !== selectedGallery?.createdBy.email) || [];
  }, [selectedGallery]);

  const columns: IColumn[] = [
    {
      title: t('gallery.owner'),
      filterType: IFilterType.String,
      field: 'owner',
      render: (row) => (
        <Stack direction="row" spacing={12} alignItems="center">
          <Avatar src="" alt="logo" sx={{ bgcolor: 'primary.dark' }}>
            {row.createdBy?.name.slice(0, 1).toUpperCase()}
          </Avatar>
          <Typography>{row.createdBy?.name}</Typography>
        </Stack>
      )
    },
    {
      title: t('gallery.gallery_name'),
      field: 'name',
      filterType: IFilterType.String,
      render: (row) => row.name
    },
    {
      title: t('gallery.print_inside'),
      filterType: IFilterType.Number,
      field: 'prints',
      render: (row) => (row.prints.length > 0 ? row.prints.length : '_')
    },
    {
      title: t('gallery.date_modified'),
      filterType: IFilterType.Date,
      field: 'updatedAt',
      render: (row) => moment(row.updatedAt).format('DD MMM YYYY')
    }
  ];

  const handlePageChange = (pageN: number) => {
    setPageNumber(pageN);
  };

  const handleRowClick = (id) => {
    setSelectedId(id);
    setVisiblePrints(false);
  };

  const handleCloseDetailPanel = () => {
    setSelectedGallery(null);
  };

  const handleToggleViewPrints = () => {
    setVisiblePrints(!visiblePrints);
  };

  const handleViewAccess = () => {
    setVisibleAccess(true);
  };

  const handleClose = () => {
    setVisibleAccess(false);
    if (selectedId) {
      fetchGallery(selectedId);
    }
  };

  const handleFilter = (newFilter) => {
    if (!lodash.isEqual(newFilter, filter)) {
      setFilter(newFilter);
    }
  };

  useEffect(() => {
    if (selectedId) {
      fetchGallery(selectedId);
    }
  }, [selectedId]);

  useEffect(() => {
    fetchGalleries();
  }, [pageNumber, filter]);

  useEffect(() => {
    console.log(selectedGallery);
  }, [selectedGallery]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={selectedGallery ? 0 : 12}
          lg={selectedGallery ? 7 : 12}
          sx={{ display: { xs: selectedGallery ? 'none' : 'flex', lg: 'flex' } }}
        >
          <Table
            data={galleries}
            columns={columns}
            totalPage={totalPage}
            pageNumber={pageNumber}
            contentHeight={{ xs: 'calc(100vh - 167px)', md: 'calc(100vh - 183px)', lg: 'calc(100vh - 247px)' }}
            isLoading={loading}
            onFilter={handleFilter}
            onPageChange={handlePageChange}
            onRowClick={handleRowClick}
          />
        </Grid>
        <Grid item xs={selectedGallery ? 12 : 0} lg={selectedGallery ? 5 : 0}>
          {selectedGallery && (
            <S.GalleryDetail>
              <S.Header>
                <Typography variant="title-s">{t('gallery.gallery_details')}</Typography>
                <IconButton size="small" onClick={handleCloseDetailPanel}>
                  <CloseIcon />
                </IconButton>
              </S.Header>
              <S.Content>
                <S.Image>
                  <img src={`${API_SERVER}/${selectedGallery.logo.filename}`} alt="logo" />
                </S.Image>
                <Typography mt={20} variant="headline-s" textAlign="center">
                  {selectedGallery.name}
                </Typography>
                <Divider sx={{ mt: 16, mb: 40 }} />
                <Stack spacing={37}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body-m" color="primary.main">
                      {t('gallery.owner')}:
                    </Typography>
                    <Typography variant="body-m" color="primary.dark">
                      {selectedGallery.createdBy.name}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body-m" color="primary.main">
                      {t('gallery.owner_id')}:
                    </Typography>
                    <Typography variant="body-m" color="primary.dark">
                      {selectedGallery.createdBy.email}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body-m" color="primary.main">
                      {t('gallery.modified')}:
                    </Typography>
                    <Typography variant="body-m" color="primary.dark">
                      {moment(selectedGallery.updatedAt).format('DD MMMM YYYY')}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body-m" color="primary.main">
                      {t('gallery.created')}:
                    </Typography>
                    <Typography variant="body-m" color="primary.dark">
                      {moment(selectedGallery.updatedAt).format('DD MMMM YYYY')}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider sx={{ mt: 27, mb: 4 }} />
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body-m" color="primary.main">
                    {t('gallery.people_with_access')}
                  </Typography>
                  <Button variant="text" onClick={handleViewAccess}>
                    {t('gallery.view_all')}
                  </Button>
                </Stack>
                <Stack
                  mt={12}
                  spacing={8}
                  direction="row"
                  alignItems="center"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Avatar sx={{ width: 38, height: 38, bgcolor: 'primary.dark' }}>
                    {selectedGallery.shareWith
                      .find(({ email }) => email === selectedGallery?.createdBy.email)
                      ?.firstName.slice(0, 1)
                      .toUpperCase()}
                  </Avatar>
                  <Stack direction="row" spacing={8}>
                    {accessWith
                      .filter((_, index) => index < 3)
                      .map((_, index) => (
                        <Avatar
                          key={index}
                          sx={{ width: 24, height: 24, bgcolor: index % 2 === 0 ? '#49454F' : 'text.secondary' }}
                        >
                          <UserIcon />
                        </Avatar>
                      ))}
                    {accessWith.length > 3 && <Typography>+ {accessWith.length - 3}</Typography>}
                  </Stack>
                </Stack>
                {selectedGallery?.prints?.length > 0 && (
                  <>
                    <Divider sx={{ mt: 20, mb: 4 }} />
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="body-m" color="primary.main">
                        {t('gallery.prints_inside', { count: selectedGallery?.prints?.length })}
                      </Typography>
                      <Button variant="text" onClick={handleToggleViewPrints}>
                        {visiblePrints ? t('gallery.hide') : t('gallery.view_all')}
                      </Button>
                    </Stack>
                    {visiblePrints && (
                      <Grid mt={12} container spacing={18.5}>
                        {selectedGallery.prints.map(({ photo }) => (
                          <Grid item xs={4}>
                            <S.Print>
                              <img
                                src={`${API_SERVER}/${
                                  // @ts-ignore
                                  selectedGallery?.photos.find(({ _id }) => _id === photo).filename
                                }`}
                                alt="print"
                              />
                            </S.Print>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </>
                )}
              </S.Content>
            </S.GalleryDetail>
          )}
        </Grid>
      </Grid>
      {visibleAccess && selectedId && <AccessModal open={visibleAccess} galleryId={selectedId} onClose={handleClose} />}
    </>
  );
};
