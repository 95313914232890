import { IGetParams, IPrint } from '../interfaces';
import { Http } from '../utils';
import { ENDPOINTS } from '../constants';

const create = (print: IPrint): Promise<any> => {
  return Http.post(ENDPOINTS.PRINT.BASE, print);
};

const read = (id: string): Promise<any> => {
  return Http.get(ENDPOINTS.PRINT.ID.replace(':id', id));
};

const readAll = (params?: IGetParams): Promise<any> => {
  return Http.get(ENDPOINTS.PRINT.BASE, params);
};

const update = (id: string, print: IPrint): Promise<any> => {
  return Http.patch(ENDPOINTS.PRINT.ID.replace(':id', id), print);
};

const remove = (id: string): Promise<any> => {
  return Http.delete(ENDPOINTS.PRINT.ID.replace(':id', id));
};

const removeAll = (ids: string[]): Promise<any> => {
  return Http.delete(ENDPOINTS.PRINT.BASE, { ids });
};

export const PrintApi = {
  create,
  read,
  readAll,
  update,
  remove,
  removeAll
};
