import { FC, useEffect, useMemo, useState } from 'react';
import * as S from './styles';
import { CloseIcon, KeyboardArrowDownIcon } from '../../../assets/icons';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IUploadFile } from '../../../interfaces';

interface IUploadNotificationProps {
  files: IUploadFile[];
  onClose: () => void;
}

export const UploadNotification: FC<IUploadNotificationProps> = ({ files, onClose }) => {
  const [open, setOpen] = useState<boolean>(true);

  const { t } = useTranslation();

  const uploadFiles = useMemo(() => {
    return files.filter(({ uploadPercent }) => {
      return uploadPercent !== 100;
    });
  }, [files]);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (uploadFiles.length === 0) {
      setTimeout(() => onClose(), 1000);
    }
  }, [uploadFiles]);

  return (
    <S.UploadNotification>
      <S.Header>
        <Typography variant="body-m" color="common.white">
          {t('notification.title', { count: uploadFiles.length })}
        </Typography>
        <Box>
          <IconButton size="small" onClick={handleToggle}>
            <Box component={KeyboardArrowDownIcon} sx={{ transform: open ? 'rotateZ(0deg)' : 'rotateZ(180deg)' }} />
          </IconButton>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon width={16} height={16} />
          </IconButton>
        </Box>
      </S.Header>
      <S.Content in={open}>
        {files.map(({ file, uploadPercent }, index) => (
          <S.File key={index}>
            <S.Image>
              <img src={URL.createObjectURL(file).toString()} alt="" />
            </S.Image>
            <Typography title={file.name} variant="body-s" color="primary.dark">
              {file.name}
            </Typography>
            <CircularProgress size={40} thickness={4} color="primary" variant="determinate" value={uploadPercent} />
          </S.File>
        ))}
      </S.Content>
    </S.UploadNotification>
  );
};
