import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { SnackbarProvider } from 'notistack';
import './assets/styles/font.css';
import AppRouter from './router';
import theme from './theme';
import React from 'react';

const App = () => {
  return (
    <MuiThemeProvider theme={theme['light']}>
      <StyledThemeProvider theme={theme['light']}>
        <SnackbarProvider maxSnack={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <AppRouter />
          </LocalizationProvider>
        </SnackbarProvider>
      </StyledThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
