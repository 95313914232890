import { alpha, Avatar as MuiAvatar, AvatarGroup, Box, Button, SpeedDial, SpeedDialAction } from '@mui/material';
import styled, { css } from 'styled-components';

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => alpha(props.theme.palette.primary.contrastText, 0.66)};
  padding: ${(props) => props.theme.spacing(16)};
  position: relative;

  .MuiTypography-root {
    color: ${(props) => alpha(props.theme.palette.primary.main, 0.66)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 50px);
  }
`;

export const Owner = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing(-4)};
`;

export const Avatar = styled(MuiAvatar)`
  background-color: #6750a4;
  margin-right: ${(props) => props.theme.spacing(16)};
`;

export const Footer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${(props) => props.theme.spacing(16)};
  background-color: ${(props) => alpha(props.theme.palette.primary.contrastText, 0.66)};
`;

export const CartButton = styled(Button)<{ isInCart: boolean }>`
  && {
    min-width: unset;
    width: 40px;
    padding: ${(props) => props.theme.spacing(9)};

    ${(props) =>
      props.isInCart &&
      css`
        width: 160px;
      `}

    .MuiButton-startIcon {
      margin-right: ${(props) => props.theme.spacing(props.isInCart ? 8 : 0)};
    }
  }
`;

export const Users = styled(AvatarGroup)<{ isOver: boolean }>`
  && {
    height: 32px;
    border: 1px solid ${(props) => props.theme.palette.surfaceVariant.outline};
    align-items: center;
    padding: ${(props) => (props.isOver ? props.theme.spacing(4, 45, 4, 4) : props.theme.spacing(4))};
    border-radius: 16px;
    flex-direction: row;
    position: relative;
    cursor: pointer;

    .MuiAvatar-root {

      &:last-of-type {
        margin-left: ${(props) => props.theme.spacing(-16)};
      }

      ${(props) =>
        props.isOver
          ? css`
            &:first-of-type {
              position: absolute;
              width: 24px;
              height: 24px;
              border: none;
              background-color: transparent !important;
              color: ${(props) => props.theme.palette.primary.main};
              ${(props) => props.theme.typography['title-s']};
              margin-left: ${(props) => props.theme.spacing(8)};
              right: 12px;
            }

            &:nth-of-type(2) {
              margin-left: ${(props) => props.theme.spacing(0)};
            }

            &:nth-of-type(2n) {
              background-color: #49454F;
            }

            &:nth-of-type(2n + 1) {
              background-color: ${(props) => props.theme.palette.text.secondary};
            }
          }
          `
          : css`
              &:first-of-type {
                margin-left: ${(props) => props.theme.spacing(0)};
              }

              &:nth-of-type(2n + 1) {
                background-color: #49454f;
              }

              &:nth-of-type(2n) {
                background-color: ${(props) => props.theme.palette.text.secondary};
              }
            `}
    }
`;

export const User = styled(MuiAvatar)`
  && {
    width: 24px;
    height: 24px;
    border: none;
    margin-left: -16px;
  }
`;

export const Actions = styled(SpeedDial)`
  && {
    margin-left: auto;

    .MuiSpeedDial-fab {
      box-shadow: none !important;
      background-color: transparent;
      color: ${(props) => props.theme.palette.surfaceVariant.outline};

      &[aria-expanded='true'] {
        color: ${(props) => props.theme.palette.primary.dark};
      }
    }

    .MuiSpeedDial-actions {
      padding-right: ${(props) => props.theme.spacing(40)};
      margin-right: ${(props) => props.theme.spacing(-40)};
    }
  }
`;

export const Action = styled(SpeedDialAction)`
  && {
    box-shadow: none !important;
    background-color: transparent;
    color: ${(props) => props.theme.palette.surfaceVariant.outline};
    margin: ${(props) => props.theme.spacing(0)};

    &:hover {
      color: ${(props) => props.theme.palette.primary.dark};
    }
  }
`;

export const Thumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Card = styled(Box)`
  height: 398px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  max-width: 398px;
  margin: auto;

  &:hover {
    ${Header} {
      background-color: ${(props) => props.theme.palette.primary.contrastText};

      .MuiTypography-root {
        color: ${(props) => props.theme.palette.primary.main};
      }
    }

    ${Footer} {
      background-color: ${(props) => props.theme.palette.primary.contrastText};
    }
  }
`;
