import { Button, CircularProgress, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthApi } from '../../../apis';
import LogoImage from '../../../assets/iamges/logo.png';
import { EmailIcon, GoogleIcon, LockIcon, LoginIcon } from '../../../assets/icons';
import { ACCESS_TOKEN_KEY, ROUTES } from '../../../constants';
import { setAccount } from '../../../store/actions';
import * as S from './styles';
import { useSnackbar } from 'notistack';

export const SignInPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('validation.invalid_email')).required(t('validation.required')),
    password: Yup.string().required(t('validation.required'))
  });

  const fields = [
    {
      name: 'email',
      icon: <EmailIcon />,
      label: t('sign_in.email')
    },
    {
      name: 'password',
      icon: <LockIcon />,
      type: 'password',
      label: t('sign_in.password')
    }
  ];

  const { values, errors, touched, isSubmitting, setSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      AuthApi.login(values)
        .then((res) => {
          if (res.user) {
            enqueueSnackbar(t('message.login_successfully'), { variant: 'success' });
            if (res.token) {
              localStorage.setItem(ACCESS_TOKEN_KEY, res.token);
            }

            navigate(ROUTES.ABOUT_US);
            dispatch(setAccount(res.user));
          }
        })
        .catch((err) => {
          if (err.status === 404) {
            enqueueSnackbar(t('message.user_not_found'), { variant: 'warning' });
          } else if (err.status === 424) {
            enqueueSnackbar(t('message.incorrect_password'), { variant: 'warning' });
          } else if (err.status === 400) {
            enqueueSnackbar(err.msg, { variant: 'warning' });
          } else {
            enqueueSnackbar(t('message.network_error'), { variant: 'error' });
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const handleLogin = () => {
    handleSubmit();
  };

  return (
    <S.Container>
      <S.Logo src={LogoImage} />
      <Typography variant="display-l" align="center">
        {t('sign_in.title')}
      </Typography>
      <Typography variant="title-m" align="center" mt={{ xs: 12, md: 0 }}>
        {t('sign_in.sub_title')}
      </Typography>
      <S.Form>
        {fields.map(({ name, icon, type = 'text', label }, index) => (
          <TextField
            fullWidth
            key={index}
            name={name}
            type={type}
            placeholder={label}
            value={values[name]}
            disabled={isSubmitting}
            error={Boolean(errors[name] && touched[name])}
            helperText={Boolean(errors[name] && touched[name]) && errors[name]}
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{ mb: 16 }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
            }}
          />
        ))}
        <Button
          fullWidth
          disabled={isSubmitting}
          startIcon={!isSubmitting && <LoginIcon />}
          sx={{ mt: 16 }}
          onClick={handleLogin}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : t('sign_in.login')}
        </Button>
        <Typography
          mt={21}
          variant="label-s"
          color="primary"
          textAlign="center"
          component={Link}
          to={ROUTES.AUTH.FORGOT_PASSWORD}
          sx={{ textDecoration: 'none' }}
        >
          {t('sign_in.question_1')}
        </Typography>
        <Typography my={27} variant="label-s" color="primary" textAlign="center">
          {t('sign_in.question_2')}
          <Typography
            ml={16}
            color="secondary"
            component={Link}
            variant="label-s"
            to={ROUTES.AUTH.SIGN_UP}
            sx={{ textDecoration: 'none' }}
          >
            {t('sign_in.sign_up')}
          </Typography>
        </Typography>
        {false && (
          <>
            <Divider>{t('sign_in.or')}</Divider>
            <Button fullWidth variant="outlined" startIcon={<GoogleIcon />} sx={{ mt: 32 }}>
              {t('sign_in.continue')}
            </Button>
          </>
        )}
      </S.Form>
    </S.Container>
  );
};
