import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language } from '../interfaces';
import ch from './locales/ch.json';
import en from './locales/en.json';
import { LANGUAGE_KEY } from '../constants';

const resources = {
  en: { translation: en },
  ch: { translation: ch }
};

const language = localStorage.getItem(LANGUAGE_KEY) || Language.En;

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: Language.En,
  interpolation: {
    escapeValue: false
  }
});

export const getLanguage = (): string => {
  return i18n.language;
};

export const setLanguage = (lang: Language): void => {
  i18n.changeLanguage(lang);
  localStorage.setItem(LANGUAGE_KEY, lang);
};

export default i18n;
