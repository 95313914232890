import { Box, Checkbox, FormControlLabel, Slider, Stack } from '@mui/material';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { ChevronRightIcon } from '../../../../assets/icons';
import { IFilterType } from '../../../../interfaces';
import * as S from './styles';
import dayjs from 'dayjs';

interface TableHeadProps {
  columns: readonly IColumn[];
  visibleAction?: boolean;
  onFilter?: (filter: any) => void;
}

export interface IColumn {
  title?: string | ReactElement;
  field?: string;
  filterType?: IFilterType;
  sort?: boolean;
  align?: 'left' | 'center' | 'right';
  render?: (row: any, index: number, data: any[]) => string | ReactElement | null | Promise<any>;
}

const TableHead: FC<TableHeadProps> = ({ columns, visibleAction = false, onFilter }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<{ type: IFilterType; field: string }>();
  const [filter, setFilter] = useState<any>({});

  const handleChangeRange = (event: Event, newValue: number | number[]) => {
    if (selectedFilter) {
      setFilter({
        ...filter,
        [selectedFilter.field]: {
          $gte: newValue[0],
          $lte: newValue[1]
        }
      });
    }
  };

  const handleSort = (event: React.MouseEvent<HTMLSpanElement>, filter: { type: IFilterType; field: string }) => {
    setSelectedFilter(filter);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeDate = (value) => {
    if (selectedFilter) {
      setFilter({
        ...filter,
        [selectedFilter.field]: {
          $gte: value[0].format(),
          $lte: value[1].format()
        }
      });
    }
  };

  const handleSelectAll = () => {
    if (selectedFilter) {
      setFilter({
        ...filter,
        [selectedFilter.field]:
          filter[selectedFilter.field].length === 26 ? '' : selectable.reduce((s, c) => s + c.value, '')
      });
    }
  };

  const handleSelect = (e) => {
    if (selectedFilter) {
      setFilter({
        ...filter,
        [selectedFilter.field]: filter[selectedFilter.field].includes(e.target.value)
          ? filter[selectedFilter.field].replace(e.target.value, '')
          : filter[selectedFilter.field] + e.target.value
      });
    }
  };

  const selectable = [
    {
      label: 'A',
      value: 'a'
    },
    {
      label: 'B',
      value: 'b'
    },
    {
      label: 'C',
      value: 'c'
    },
    {
      label: 'D',
      value: 'd'
    },
    {
      label: 'E',
      value: 'e'
    },
    {
      label: 'F',
      value: 'f'
    },
    {
      label: 'G',
      value: 'g'
    },
    {
      label: 'H',
      value: 'h'
    },
    {
      label: 'I',
      value: 'i'
    },
    {
      label: 'J',
      value: 'j'
    },
    {
      label: 'K',
      value: 'k'
    },
    {
      label: 'L',
      value: 'l'
    },
    {
      label: 'M',
      value: 'm'
    },
    {
      label: 'N',
      value: 'n'
    },
    {
      label: 'O',
      value: 'o'
    },
    {
      label: 'P',
      value: 'p'
    },
    {
      label: 'Q',
      value: 'q'
    },
    {
      label: 'R',
      value: 'r'
    },
    {
      label: 'S',
      value: 's'
    },
    {
      label: 'T',
      value: 't'
    },
    {
      label: 'U',
      value: 'u'
    },
    {
      label: 'V',
      value: 'v'
    },
    {
      label: 'W',
      value: 'w'
    },
    {
      label: 'X',
      value: 'x'
    },
    {
      label: 'Y',
      value: 'y'
    },
    {
      label: 'Z',
      value: 'z'
    }
  ];

  useEffect(() => {
    const initialFilter = {};
    for (let i = 0; i < columns.length; i++) {
      const filterType = columns[i].filterType;
      const field = columns[i].field;
      if (filterType && field) {
        switch (filterType) {
          case IFilterType.String: {
            initialFilter[field] = selectable.reduce((s, c) => s + c.value, '');
            break;
          }

          case IFilterType.Number: {
            initialFilter[field] = {
              $gte: 0,
              $lte: 100
            };
            break;
          }

          case IFilterType.Date: {
            initialFilter[field] = {
              $gte: dayjs().subtract(1, 'month').format(),
              $lte: dayjs().format(),
            };
            break;
          }
        }
      }
    }
    setFilter(initialFilter);
  }, []);

  useEffect(() => {
    if (filter && onFilter) {
      const newFilter = {};
      Object.entries(filter).map(([key, value]) => {
        if (typeof value === 'string') {
          newFilter[key] = { $regex: value };
        } else {
          newFilter[key] = value;
        }
      });
      onFilter(newFilter);
    }
  }, [filter]);

  return (
    <>
      <S.Head>
        <S.Row>
          {columns.map(({ field, align, title, filterType }, index) => {
            return (
              <S.Cell
                key={index}
                align={align || 'left'}
                sx={{
                  px: field === 'check' ? 0 : 12
                }}
              >
                {field == 'check' ? (
                  <Box />
                ) : (
                  <S.SortLabel
                    onClick={(e) => (filterType && field ? handleSort(e, { type: filterType, field }) : null)}
                    IconComponent={ChevronRightIcon}
                  >
                    <S.Label>{title || ''}</S.Label>
                  </S.SortLabel>
                )}
              </S.Cell>
            );
          })}
          {visibleAction && <S.Cell align="right">Actions</S.Cell>}
        </S.Row>
      </S.Head>
      <S.Filter
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: selectedFilter?.type === IFilterType.Date ? 'right' : 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: selectedFilter?.type === IFilterType.Date ? 'right' : 'left'
        }}
      >
        {selectedFilter?.type === IFilterType.String && (
          <Stack py={8}>
            <S.SelectItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter[selectedFilter?.field].length === 26}
                    indeterminate={
                      filter[selectedFilter?.field].length > 0 && filter[selectedFilter?.field].length < 26
                    }
                  />
                }
                onChange={handleSelectAll}
                label="Select all"
              />
            </S.SelectItem>
            <S.Selectable>
              {selectable.map(({ label, value }, index) => (
                <S.SelectItem key={index}>
                  <FormControlLabel
                    value={value}
                    control={<Checkbox checked={filter[selectedFilter?.field].includes(value)} />}
                    label={label}
                    onChange={handleSelect}
                  />
                </S.SelectItem>
              ))}
            </S.Selectable>
          </Stack>
        )}
        {selectedFilter?.type === IFilterType.Number && (
          <Box px={32} pt={54} pb={8} width={360}>
            <Slider
              value={[filter[selectedFilter.field]['$lte'], filter[selectedFilter.field]['$gte']]}
              onChange={handleChangeRange}
              valueLabelDisplay="auto"
            />
          </Box>
        )}
        {selectedFilter?.type === IFilterType.Date && (
          <S.RangePicker
            value={[dayjs(filter[selectedFilter.field]['$lte']), dayjs(filter[selectedFilter.field]['$gte'])]}
            onChange={handleChangeDate}
          />
        )}
      </S.Filter>
    </>
  );
};

export default TableHead;
