import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../constants';
import { AuthApi } from '../../../apis';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { FC, useEffect } from 'react';

export const VerifyEmailPage: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    AuthApi.verify({ token: token })
      .then((res) => {
        enqueueSnackbar(res.msg, { variant: 'success' });
        navigate(ROUTES.AUTH.SIGN_IN);
      })
      .catch((err) => {
        enqueueSnackbar(err.msg, { variant: 'error' });
      });
  }, [token]);

  return <></>;
};
