import { IAccount, IGetParams } from '../interfaces';
import { ENDPOINTS } from '../constants';
import { Http } from '../utils';

const readAll = (params?: IGetParams): Promise<any> => {
  return Http.get(ENDPOINTS.USER.BASE, params);
};

const update = (id: string, user: Partial<IAccount>): Promise<any> => {
  return Http.patch(ENDPOINTS.USER.ID.replace(':id', id), user);
};

const changePassword = (data): Promise<any> => {
  return Http.patch(ENDPOINTS.USER.CHANGE_PASSWORD, data);
};

const removeAll = (ids: string[]): Promise<any> => {
  return Http.delete(ENDPOINTS.USER.BASE, { ids });
};

export const UserApi = {
  readAll,
  update,
  changePassword,
  removeAll
};

