import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ADMIN_ROUTES, AUTH_ROUTES, ROUTES, USER_ROUTES } from '../constants';
import { AdminLayout, AuthLayout, UserLayout } from '../layouts';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { getAccount } from '../store/selectors';
import { setAccount } from '../store/actions';
import { FC, useMemo, useState } from 'react';
import { Role } from '../interfaces';
import { AuthApi } from '../apis';

const AppRouter: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dispatch = useDispatch();
  const account = useSelector(getAccount);

  if (!account) {
    AuthApi.me()
      .then((res) => {
        if (res.user) {
          dispatch(setAccount(res.user));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  const MAIN_ROUTES = useMemo(() => {
    if (account) {
      if (account.role === Role.Admin) {
        return ADMIN_ROUTES;
      } else {
        return USER_ROUTES;
      }
    } else {
      return [];
    }
  }, [account]);

  const Layout = useMemo(() => {
    if (account?.role === Role.Admin) {
      return AdminLayout;
    } else {
      return UserLayout;
    }
  }, [account]);

  return isLoading ? (
    <Box flex={1} height="100vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress color="secondary" />
    </Box>
  ) : (
    <Router>
      {!account ? (
        <Routes>
          {AUTH_ROUTES.map(({ path, element: C }) => (
            <Route
              path={path}
              key={path}
              element={
                <AuthLayout>
                  <C />
                </AuthLayout>
              }
            />
          ))}
          <Route path="*" element={<Navigate to={ROUTES.AUTH.SIGN_IN} />} />
        </Routes>
      ) : (
        <Routes>
          {MAIN_ROUTES.map(({ path, element: C }) => (
            <Route
              path={path}
              key={path}
              element={
                <Layout>
                  <C />
                </Layout>
              }
            />
          ))}
          <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
        </Routes>
      )}
    </Router>
  );
};

export default AppRouter;
