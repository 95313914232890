import { alpha, Box, Table as MuiTable, TableCell, TableRow } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled, { css } from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Table = styled(MuiTable)``;

export const Content = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Cell = styled(TableCell)`
  && {
    height: 56px;
    color: ${(props) => props.theme.palette.text.secondary};
    padding: ${(props) => props.theme.spacing(8, 20)};
    ${(props) => props.theme.typography.body2};
    border-bottom-color: ${(props) => props.theme.palette.primary.main};
    flex-direction: row;
    ${(props) => props.theme.typography['body-m']};
    color: ${(props) => props.theme.palette.primary.dark};

    ${(props) => props.theme.breakpoints.down('xl')} {
      ${(props) => props.theme.typography['body-s']};
      padding: ${(props) => props.theme.spacing(8, 12)};
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      padding: ${(props) => props.theme.spacing(8)};
    }
  }
`;

export const Row = styled(TableRow)<{ onClick?: any }>`
  && {
    color: ${(props) => props.theme.palette.text.secondary};

    &:hover {
      ${Cell} {
        background-color: ${(props) => alpha(props.theme.palette.primary.dark, 0.33)};
      }
    }

    ${(props) =>
      !!props.onClick &&
      css`
        cursor: pointer;
      `}
  }
`;
