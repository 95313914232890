import { Box, Button, Checkbox, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import {
  AddCartIcon,
  CheckIcon,
  DeleteIcon,
  EditIcon,
  MoreIcon,
  PrinterIcon,
  ShareIcon,
  UserIcon
} from '../../../assets/icons';
import { API_SERVER } from '../../../constants';
import { IAccount, IFile } from '../../../interfaces';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from '../../../store/selectors';
import { ShopApi } from '../../../apis';
import { setAccount } from '../../../store/actions';

interface ICardProps {
  title: string;
  owner?: IAccount;
  selected?: boolean;
  thumbnail?: IFile;
  shareWith?: IAccount[];
  printId?: string;
  onPrint?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onShare?: () => void;
  onSelect?: () => void;
  onDetail?: () => void;
}

export const Card: FC<ICardProps> = ({
  title,
  selected,
  thumbnail,
  owner,
  shareWith,
  printId,
  onPrint,
  onEdit,
  onDelete,
  onShare,
  onSelect,
  onDetail
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [visibleMoreActions, setVisibleMoreActions] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const account = useSelector(getAccount);

  const isInCart = useMemo(() => {
    return Boolean(account.cart.find(({ _id }) => _id === printId));
  }, [printId, account]);

  const handleToggleToCart = () => {
    if (printId) {
      setLoading(true);
      ShopApi.toggleToCart([printId])
        .then((res) => {
          dispatch(setAccount(res.user));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleOpenMoreActions = () => {
    setVisibleMoreActions(true);
  };

  const handleCloseMoreActions = () => {
    setVisibleMoreActions(false);
  };

  return (
    <S.Card>
      <S.Thumbnail
        src={
          thumbnail?.fieldname
            ? `${API_SERVER}/${thumbnail?.filename}`
            : thumbnail && URL.createObjectURL(thumbnail as unknown as Blob).toString()
        }
        alt=""
        onClick={onDetail}
        style={{ cursor: onDetail ? 'pointer' : 'auto' }}
      />
      <S.Header>
        {owner ? (
          <S.Owner onClick={onDetail} sx={{ cursor: onDetail ? 'pointer' : 'auto' }}>
            <S.Avatar />
            <Box>
              <Typography variant="title-s">{title}</Typography>
              <Typography variant="body-s">{owner.name}</Typography>
            </Box>
          </S.Owner>
        ) : (
          <Typography title={title} variant="title-s" onClick={onDetail} sx={{ cursor: onDetail ? 'pointer' : 'auto' }}>
            {title}
          </Typography>
        )}
        <Tooltip title={t(selected ? 'common.deselect' : 'common.select')}>
          <Checkbox checked={selected} onChange={onSelect} />
        </Tooltip>
      </S.Header>
      <S.Footer>
        {printId && (
          <S.CartButton
            disabled={loading}
            isInCart={isInCart}
            startIcon={loading ? <CircularProgress size={14} /> : isInCart ? <CheckIcon /> : <AddCartIcon />}
            onClick={handleToggleToCart}
          >
            {isInCart && t('shop.added_to_cart')}
          </S.CartButton>
        )}
        {onPrint && (
          <Button startIcon={<PrinterIcon />} onClick={onPrint}>
            Print
          </Button>
        )}
        {shareWith && shareWith.length > 0 && onShare && (
          <S.Users max={4} isOver={shareWith.length > 4} onClick={onShare}>
            {shareWith.map((_, index) => (
              <S.User key={index}>
                <UserIcon />
              </S.User>
            ))}
          </S.Users>
        )}
        <S.Actions
          direction="left"
          icon={
            <Tooltip title={t('common.more')}>
              <IconButton onClick={handleOpenMoreActions}>
                <MoreIcon />
              </IconButton>
            </Tooltip>
          }
          open={visibleMoreActions}
          onClose={handleCloseMoreActions}
          ariaLabel="more"
        >
          {onEdit && (
            <S.Action
              icon={
                <Tooltip title={t('common.edit')}>
                  <EditIcon />
                </Tooltip>
              }
              onClick={onEdit}
            />
          )}
          {onShare && (
            <S.Action
              icon={
                <Tooltip title={t('common.share')}>
                  <ShareIcon />
                </Tooltip>
              }
              onClick={onShare}
            />
          )}
          {onDelete && (
            <S.Action
              icon={
                <Tooltip title={t('common.delete')}>
                  <DeleteIcon />
                </Tooltip>
              }
              onClick={onDelete}
            />
          )}
        </S.Actions>
      </S.Footer>
    </S.Card>
  );
};
