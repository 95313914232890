import { FC } from 'react';
import * as S from './styles';
import { IconButton, Typography } from '@mui/material';
import { AddIcon, DeleteIcon, RemoveIcon } from '../../../assets/icons';

interface ICounterProps {
  count: number;
  onDecrease: () => void;
  onIncrease: () => void;
}

export const Counter: FC<ICounterProps> = ({ count, onDecrease, onIncrease }) => {
  return (
    <S.Counter>
      {count > 0 && <IconButton onClick={onDecrease}>{count > 1 ? <RemoveIcon /> : <DeleteIcon />}</IconButton>}
      {count > 0 && <Typography variant="label-s">{count > 9 ? count : `0${count}`}</Typography>}
      <IconButton onClick={onIncrease}>
        <AddIcon />
      </IconButton>
    </S.Counter>
  );
};
