import { alpha, Box, Stack } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Image = styled(Box)`
  width: 188px;
  height: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.secondary.main};
  background-color: ${(props) => alpha(props.theme.palette.secondary.main, 0.11)};
  margin-bottom: ${(props) => props.theme.spacing(18)};
  position: relative;

  svg {
    width: auto;
    height: 120px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 140px;
    height: 140px;

    svg {
      height: 80px;
    }
  }
`;

export const Form = styled(Stack)`
  width: 100%;
  max-width: 400px;
`;
