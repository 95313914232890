import {
  DashboardIcon,
  DashboardFilledIcon,
  GalleryFilledIcon,
  GalleryIcon,
  OrderFilledIcon,
  OrderIcon,
  PersonFilledIcon,
  PersonIcon,
  PrintFilledIcon,
  PrintIcon
} from '../../../assets/icons';
import { Logout, Settings, SettingsOutlined } from '@mui/icons-material';
import { ACCESS_TOKEN_KEY, ROUTES } from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAccount } from '../../../store/actions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { storage } from '../../../utils';
import * as S from './styles';
import { FC } from 'react';

const navLinks = [
  {
    label: 'Dashboard',
    to: ROUTES.HOME,
    icon: {
      main: <DashboardIcon />,
      active: <DashboardFilledIcon />
    }
  },
  {
    label: 'Users',
    to: ROUTES.ADMIN.USER,
    icon: {
      main: <PersonIcon />,
      active: <PersonFilledIcon />
    }
  },
  {
    label: 'Galleries',
    to: ROUTES.ADMIN.GALLERY,
    icon: {
      main: <GalleryIcon />,
      active: <GalleryFilledIcon />
    }
  },
  {
    label: 'Orders',
    to: ROUTES.ADMIN.ORDER,
    icon: {
      main: <OrderIcon />,
      active: <OrderFilledIcon />
    }
  },
  {
    label: 'Print Management',
    to: ROUTES.ADMIN.PRINT,
    icon: {
      main: <PrintIcon />,
      active: <PrintFilledIcon />
    }
  }
];

export const Sidebar: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (to) => () => {
    navigate(to);
  };

  const handleLogout = () => {
    storage.removeItem(ACCESS_TOKEN_KEY);
    dispatch(setAccount(null));
  };

  return (
    <S.Sidebar>
      {navLinks.map(({ icon, to, label }, index) => (
        // @ts-ignore
        <S.NavItem key={index} isActive={to === pathname} onClick={handleNavigate(to)}>
          <S.NavIcon>{to === pathname ? icon.active : icon.main}</S.NavIcon>
          <S.NavLabel>{label}</S.NavLabel>
        </S.NavItem>
      ))}
      <S.NavItem
        sx={{ marginTop: 'auto' }}
        isActive={ROUTES.ADMIN.SETTING === pathname}
        onClick={handleNavigate(ROUTES.ADMIN.SETTING)}
      >
        <S.NavIcon>{ROUTES.ADMIN.SETTING === pathname ? <Settings /> : <SettingsOutlined />}</S.NavIcon>
        <S.NavLabel>Settings</S.NavLabel>
      </S.NavItem>
      <S.NavItem isActive={false} onClick={handleLogout}>
        <S.NavIcon>
          <Logout />
        </S.NavIcon>
        <S.NavLabel>{t('header.logout')}</S.NavLabel>
      </S.NavItem>
    </S.Sidebar>
  );
};
